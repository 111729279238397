import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import Footer from "../components/common/Footer";
import Info from "../images/network-error.png";
import "../styles/disable-profile.css";
import Config from "../v2/config";

class NetworkError extends Component {
  state = {
    lng: true, // default en
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  handleTry = () => {
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Network Error | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="disable-div">
            <p className="message" style={this.state.lng ? {}: {letterSpacing: '2px'}}>
              {this.state.lng ? "You’re Offline" : "අන්තර්ජාල සබඳතාවක් නොමැත."}
            </p>
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 100 }} />
            </p>
            <p align="center" className="desc">
              {this.state.lng ? (
                "We are unable to establish a connection to our servers. Please check your Internet and try again."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14" style={{letterSpacing: '2px'}}>
                  වෙබ් අඩවිය සමග සම්බන්ධතාවයක් ස්ථාපිත කිරීමට නොහැකි විය. කරුණාකර ඔබගේ අන්තර්ජාල සබඳතාව පරීක්ෂා කර නැවත උත්සාහ කරන්න.
                </span>
              )}
            </p>

            <Button
              variant="contained"
              onClick={this.handleTry}
              className="login-btn"
              style={this.state.lng ? { width: 160 } : {width: 230}}
            >
              {this.state.lng ? (
                "Reload Page"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12" style={{letterSpacing: '2px'}}>
                  නැවත උත්සාහ කරන්න
                </span>
              )}
            </Button>
          </div>
        </Grid>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(NetworkError);
