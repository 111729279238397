import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import close from "../../images/close.png";
import CreateAccountMethod from "../common/CreateAccountMethod";
import ReactCodeInput from "react-verification-code-input";
import { fetchAllInterest } from "../../actions/fetchAllInterest";
import axios from "axios";
import qs from "qs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { extractUtmCookies, clearCookies } from "../../utils/extractor";
import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";
var timer;

class Main extends Component {
  state = {
    isShowPwd: false,
    phoneError: "",
    isOTPSecondView: false,
    phoneId: "",
    token: "",
    isNewUser: false,
    createdBy: "",
    createdByError: "",

  };


  handleCreators = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });   
  };

  // validate
  validate = () => {
    let phoneError = "";
    if (this.props.phone === "") {
      phoneError = this.props.lng
        ? "Phone number is required"
        : "දුරකථන අංකය අවශ්‍ය වේ";
    }

    if (
      phoneError
    ) {
      this.setState({
        phoneError
      });
      return false;
    }
    return true;
  };



  switchOPTLogin = () => {
    this.props.handleShowFirstView();
    this.setState({ isOTPLogin: true});
  }



  render() {
    return (
      <div
        className="create-login-div login-div-container"
        style={{ margin: 0, width: "300px" }}
      >
        {this.props.showCountryCodes ? (
          <React.Fragment>
            <p className="login-signup-back" onClick={this.props.handleBackBtn}>
              <i className="fas fa-chevron-left"></i>{" "}
              {this.props.lng ? (
                "BACK"
              ) : (
                <span className="sinhala-size-12">ආපසු යන්න</span>
              )}
            </p>
            <p className="modal-heading">
              {this.props.lng ? (
                "Select Phone Dialing Code"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  දුරකථන ඇමතුම් කේතය තෝරන්න
                </span>
              )}
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!this.props.showFirstView ? (
              <p
                className="login-signup-back"
                onClick={this.props.handleShowFirstView}
              >
                <i className="fas fa-chevron-left"></i>{" "}
                {this.props.lng ? (
                  "BACK"
                ) : (
                  <span className="sinhala-size-12">ආපසු යන්න</span>
                )}
              </p>
            ) : (
              <div>
                <picture>
                  <ProductLogo variant={LogoVariant.black} size={LogoSize.small} className={"login-logo"}/>
                </picture>
                <p
                  className="login-signup-back"
                  onClick={() => this.props.history.push("/auth")}
                >
                  <i className="fas fa-chevron-left"></i>{" "}
                  {this.props.lng ? (
                    "BACK"
                  ) : (
                    <span className="sinhala-size-12">ආපසු යන්න</span>
                  )}
                </p>
              </div>
            )}
            <p
              className="modal-heading"
              style={{
                textAlign: this.props.showFirstView ? "center" : "",
                borderBottom:
                  this.props.history.location.pathname === "/login" ? 0 : "",
              }}
            >
              {this.props.lng ? (
                "Login to Your Account"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  ඔබගේ ගිණුමට පිවිසෙන්න
                </span>
              )}
            </p>
          </React.Fragment>
        )}

        {this.props.history.location.pathname === "/login" ? null : (
          <img
            src={close}
            alt="close"
            className="close-icon"
            onClick={this.props.handleClose}
          />
        )}

      
        {(this.props.showFirstView) ? (
          <React.Fragment>
            <p className="register-sec-heading">
              {this.props.lng
                ? "Choose the login method"
                : "පිවිසීමේ ක්‍රමය තෝරන්න"}
            </p>

            <div
              onClick={this.props.handlePhoneMethod}
              className="select-login-btn"
            >
              <i className="fas fa-phone fa-rotate-90"></i>
              {this.props.lng ? (
                "Phone Number"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දුරකතන අංකය
                </span>
              )}
            </div>
            <div
              onClick={this.props.handleEmailMethod}
              className="select-login-btn"
            >
              <i className="fas fa-envelope"></i>
              {this.props.lng ? (
                "Email"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  විද්‍යුත් තැපෑල
                </span>
              )}
            </div>
            <div
              onClick={this.props.handleUserIdMethod}
              className="select-login-btn"
            >
              <i className="fas fa-user-circle"></i>
              {this.props.lng ? (
                "Ad ID"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දැන්වීම් කේතය
                </span>
              )}
            </div>
          </React.Fragment>
        ) : null}

        {this.props.authError ? (
          <p style={{ color: "#f44336", fontSize: "0.75rem", marginTop: 0 }}>
            {this.props.authError}
          </p>
        ) : null}

        {!this.props.showFirstView && this.props.isUsingPhone ? (
          <React.Fragment>
            {!this.props.showCountryCodes && (
              <p className="input-headings">
                {this.props.lng ? (
                  "Phone Number"
                ) : (
                  <span className="sinhala-size-12">දුරකතන අංකය</span>
                )}
              </p>
            )}

            {this.props.showCountryCodes ? (
              <React.Fragment>
                <div style={{ position: "relative" }}>
                  <input
                    autoFocus
                    type="text"
                    name="search"
                    className="search-county-code-input"
                    placeholder="Search"
                    value={this.props.search}
                    onChange={this.props.handleChange}
                    autoComplete="off"
                  />
                  <i className="fas fa-search code-search-icon"></i>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input
                  className="phone-input-country-code"
                  onClick={this.props.handleShowCountryCodes}
                  value={this.props.code}
                />
                <input
                  type="number"
                  name="phone"
                  className={
                    this.props.phoneError
                      ? "phone-input-custom red-border"
                      : "phone-input-custom"
                  }
                  value={this.props.phone}
                  onChange={this.props.handlePhone}
                  autoComplete="off"
                />
                <div className="error-handler">{this.props.phoneError}</div>
              </React.Fragment>
            )}

            {this.props.showCountryCodes && (
              <div className="country-code-div">
                {this.props.filteredCountries.map((code, i) => (
                  <p
                    key={i}
                    onClick={() => this.props.handleCountryCode(code.dialCode)}
                  >
                    <span>{code.name}</span> <span>{code.dialCode}</span>
                  </p>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : !this.props.showFirstView &&
          !this.props.isUsingPhone &&
          this.props.isUserId ? (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Ad ID"
              ) : (
                <span className="sinhala-size-12">දැන්වීම් කේතය</span>
              )}{" "}
              <Tooltip
                title={
                  this.props.lng
                    ? 'Example: 1000123456 (Previously named "Login ID")'
                    : 'උදාහරණය: 1000123456 (කලින් නම් කර ඇත්තේ "පිවිසුම් හැඳුනුම්පත" ලෙසය)'
                }
                arrow
                placement="top"
              >
                <span>
                  <i className="fas fa-info-circle"></i>
                </span>
              </Tooltip>
            </p>

            <input
              type="email"
              name="email"
              className={
                this.props.emailError ? "login-input red-border" : "login-input"
              }
              value={this.props.email}
              onChange={this.props.handleChange}
            />
            <div className="error-handler">{this.props.emailError}</div>
          </React.Fragment>
        ) : !this.props.showFirstView &&
          !this.props.isUsingPhone &&
          !this.props.isUserId ? (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Email"
              ) : (
                <span className="sinhala-size-12">විද්‍යුත් තැපෑල</span>
              )}
            </p>

            <input
              type="email"
              name="email"
              className={
                this.props.emailError ? "login-input red-border" : "login-input"
              }
              value={this.props.email}
              onChange={this.props.handleChange}
            />
            <div className="error-handler">{this.props.emailError}</div>
          </React.Fragment>
        ) : null}

        {this.props.sentSuccess ? (
          <p
            className="text-success text-center"
            style={{
              fontSize: 13,
              color: "green",
              textAlign: "center",
            }}
          >
            <i className="fas fa-check"> </i>{" "}
            {this.props.lng ? "Mail Sent." : "තැපැල් යවන ලදි."}
          </p>
        ) : null}

        {this.props.resendEmail ? (
          <Button
            variant="contained"
            className="resend-btn"
            onClick={this.props.handleResendEmail}
            style={{ minWidth: 90 }}
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : this.props.lng ? (
              "Resend Verification Email"
            ) : (
              "නැවත යවන්න"
            )}
          </Button>
        ) : null}

        {this.props.showFirstView || this.props.showCountryCodes ? null : (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Password"
              ) : (
                <span className="sinhala-size-12">මුරපදය</span>
              )}
            </p>
            <div style={{ position: "relative" }}>
              <input
                type={this.state.isShowPwd ? "text" : "password"}
                name="password"
                className={
                  this.props.passwordError
                    ? "login-input red-border"
                    : "login-input"
                }
                value={this.props.password}
                onChange={this.props.handleChange}
                onKeyUp={this.props.handleLoginKeyUp}
              />
              {this.state.isShowPwd ? (
                <i
                  className="fas fa-eye-slash password-show-hide-btn"
                  onClick={() => this.setState({ isShowPwd: false })}
                ></i>
              ) : (
                <i
                  className="fas fa-eye password-show-hide-btn"
                  onClick={() => this.setState({ isShowPwd: true })}
                ></i>
              )}
            </div>

            <div className="error-handler">{this.props.passwordError}</div>
            <Button
              variant="contained"
              className="login-btn"
              onClick={this.props.handleSaveProfile}
              id="user-login-btn"
            >
              {this.props.loadingLogin ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : this.props.lng ? (
                "Login"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  ඇතුල් වන්න
                </span>
              )}
            </Button>
          </React.Fragment>
        )}

        {this.props.showFirstView || !this.props.showCountryCodes ? (
          <React.Fragment>
            <div>
                  <p
                    className="forget-pwd-link"
                    onClick={this.props.showPwdResetModal}
                  >
                    {this.props.lng ? (
                      "Forgot your password?"
                    ) : (
                      <span className="sinhala-size-10">මුරපදය අමතක වුනාද?</span>
                    )}
                  </p>
                  {this.props.isOTPLogin ?
                    <p
                      style={{width: "fit-content", margin: "auto"}}
                      className="forget-pwd-link"
                      onClick={this.switchOPTLogin}
                    >
                      {this.props.lng ? (
                        "Login using OTP?"
                      ) : (
                        <span className="sinhala-size-10">OTP භාවිතයෙන් පිවිසෙන්න</span>
                      )}
                    </p> :
                    <p
                      className="forget-pwd-link"
                      onClick={() => this.props.history.push("/auth")}
                    >
                      {this.props.lng ? (
                       "Login using OTP?"
                     ) : (
                       <span className="sinhala-size-10">OTP භාවිතයෙන් පිවිසෙන්න</span>
                     )}
                   </p>
                  }
                </div>

            <hr style={{ margin: "30px 0", border: "1px solid #ebebeb" }} />

            <CreateAccountMethod lng={this.props.lng} />

            <p className="terms-and-policy">
              {this.props.lng
                ? "By signing up you are agreeing to our "
                : "ඉදිරියට යෑමෙන් ඔබ අපගේ "}
              <Link to="/privacy">
                {this.props.lng ? "Privacy Policy" : "රහස්‍යතා ප්‍රතිපත්තිය"}
              </Link>{" "}
              {this.props.lng ? "and" : "සහ"}{" "}
              <Link to="/terms">
                {this.props.lng ? "Terms of Use" : "සේවා කොන්දේසි "}
              </Link>
              {this.props.lng ? "" : "වලට එකග වේ"}.
            </p>

            {this.props.history.location.pathname === "/login" && (
              <p
                className="back"
                onClick={() => this.props.history.push("/")}
                style={{ width: "fit-content", margin: "auto", marginTop: 10 }}
              >
                {this.props.lng ? "< BACK TO HOME" : "< ආපසු යන්න"}
              </p>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAllInterest,
    },
    dispatch
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Main));
