import { isEmpty, isEqual } from "lodash";
import UserApi from "../../../../services/api/user";
import Constants from "../../../../config/constant";
import Config from "../../../../config";
import GenericDataAPI from "../../../../services/api/generic";
import {
  getAuthTempProfile,
  getAuthUserAccount,
} from "../../../../features/application";
import { setAppSnackNotification } from "../../../../store/actions/application";
import { buildModelByFormMeta } from "../../../../utils";
import FormMeta from "../schema/meta.json";

const saveAddressFields = async ({ authAccount, address }) => {
  const currentAddress = authAccount.address ?? {};
  const addressEntries = Object.entries(address);
  const newAddress = {};
  for (const [key, value] of addressEntries) {
    if (!isEmpty(value)) {
      newAddress[key] = value;
    }
  }

  if (!isEqual(currentAddress, newAddress)) {
    const response = await UserApi.postUserAddress({
      addressFields: {
        ...newAddress,
        countryCode: Constants.LANKA_COUNTRY_CODE,
      },
    });
    return response.success;
  }
  return true;
};

const saveUserPreferences = async ({ currentProfile, preferences }) => {
  if (
    preferences.showOfflineId !== currentProfile.showOfflineId ||
    preferences.subscribeMagazine !== currentProfile.subscribeMagazine
  ) {
    const response = await UserApi.postUserPreferences({ preferences });
    return response.success;
  }
  return true;
};

export const removeTempUserEmail = () => async (dispatch) => {
  const response = await UserApi.removeUserEmail();
  if (response.success) {
    dispatch(getAuthUserAccount());
  }
  return response;
};

export const updateTempUserEmail =
  ({ email }) =>
  async () => {
    const response = await UserApi.postUserEmail({ email });
    return response;
  };

export const saveTempProfile = async ({
  dispatch,
  formValues,
  currentProfile,
  authAccount,
  history,
  setFieldError,
  t,
}) => {
  try {
    const { CURRENT_PLATFORM, PLATFORMS } = Config;
    if (CURRENT_PLATFORM === PLATFORMS.LK) {
      const addressUpdate = await saveAddressFields({
        authAccount,
        address: {
          name: formValues.nameOfRecipient,
          line1: formValues.addressLineOne,
          line2: formValues.addressLineTwo,
          city: formValues.city,
        },
      });
      if (!addressUpdate) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to update address",
          })
        );
        return;
      }
      // Update user preferences
      const preferenceUpdate = await saveUserPreferences({
        currentProfile,
        preferences: {
          showOfflineId: formValues.showOfflineId,
          subscribeMagazine: formValues.subscribeMagazine,
        },
      });
      if (!preferenceUpdate) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to update preferences",
          })
        );
        return;
      }
    }

    if (isEmpty(formValues.email) && !isEmpty(currentProfile.email)) {
      const response = await dispatch(removeTempUserEmail());
      if (!response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to update email",
          })
        );
        return;
      }
    }

    if (
      !isEmpty(formValues.email) &&
      formValues.email !== currentProfile.email
    ) {
      const response = await dispatch(
        updateTempUserEmail({ email: formValues.email })
      );

      if (!response.success) {
        setFieldError("email", t("createflow.emailUsedByOtherAccountError"));
        return;
      }
    }
    // Upload profile images
    if (Array.isArray(formValues.images)) {
      const profileImages = [...formValues.images].filter(Boolean);
      for (let index = 0; index < profileImages.length; index++) {
        const file = profileImages[index];
        if (file instanceof Blob) {
          const formData = new FormData();
          formData.append("file", file);
          const response = await GenericDataAPI.postFileUpload({
            formData,
          });
          if (!response.success || response.body === undefined) {
            throw new Error("unable to upload profile images");
          }
          const fileName = response.body.name;
          formValues.images[index] = fileName;
        }
      }
    }
    // Upload horoscope images
    if (Array.isArray(formValues.horoscopeImages)) {
      const horoscopeImages = [...formValues.horoscopeImages].filter(Boolean);
      for (let index = 0; index < horoscopeImages.length; index++) {
        const file = horoscopeImages[index];
        if (file instanceof Blob) {
          const formData = new FormData();
          formData.append("file", file);
          const response = await GenericDataAPI.postFileUpload({
            formData,
          });
          if (!response.success || response.body === undefined) {
            throw new Error("unable to upload horoscope images");
          }
          const fileName = response.body.name;
          formValues.horoscopeImages[index] = fileName;
        }
      }
    }

    currentProfile.images = formValues.images.filter(Boolean);
    currentProfile.horoscopeMatching = formValues.horoscopeMatching;
    currentProfile.horoscopeImages = formValues.horoscopeImages.filter(Boolean);
    currentProfile.horoscopeDetail = formValues.horoscopeDetail;
    currentProfile.birthTime = formValues.birthTime || null;
    currentProfile.birthCity = formValues.birthCity;

    currentProfile.personalInfo = currentProfile.personalInfo ?? {};
    currentProfile.personalInfo.originCountryCode =
      formValues.originCountryCode;

    if (CURRENT_PLATFORM === PLATFORMS.IN) {
      currentProfile.hasDosh = formValues.hasDosh;
      currentProfile.personalInfo.starId = formValues.starId;
      currentProfile.personalInfo.starSignId = formValues.starSignId;
      currentProfile.acceptOtherCaste = formValues.acceptOtherCaste;
    }

    // Update Temp Profile
    const response = await UserApi.postUserTempProfile({
      tempProfile: currentProfile,
    });

    if (!response.success) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unable to update profile",
        })
      );
      return;
    }
    dispatch(
      setAppSnackNotification({
        severity: "success",
        message: "Data Saved Successfully",
      })
    );

    await dispatch(getAuthUserAccount());
    await dispatch(getAuthTempProfile());
    history.push("complete-ad-review");
  } catch (error) {
    dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unknown Error Occurred, Try Again",
      })
    );
  }
};

export const bindProfileDataToFormData = ({ authTempProfile, authAccount }) => {
  const model = buildModelByFormMeta(FormMeta);
  try {
    // bind from auth account
    if (!isEmpty(authAccount)) {
      // phoneNumber
      model[FormMeta.phoneNumber.fieldName] = authAccount.phoneNumber ?? "";
      // Email
      model[FormMeta.email.fieldName] = authAccount.email ?? "";
      // LK related fields - auth account
      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
        // showOfflineId
        model[FormMeta.showOfflineId.fieldName] =
          authAccount?.userPreferences[FormMeta.showOfflineId.fieldName] ??
          false;
        // subscribeMagazine
        model[FormMeta.subscribeMagazine.fieldName] =
          authAccount?.userPreferences[FormMeta.subscribeMagazine.fieldName] ??
          false;
        // address fields
        if (!isEmpty(authAccount.address)) {
          const addressObj = authAccount.address;
          model[FormMeta.nameOfRecipient.fieldName] = addressObj.name;
          model[FormMeta.addressLineOne.fieldName] = addressObj.line1;
          model[FormMeta.addressLineTwo.fieldName] = addressObj.line2;
          model[FormMeta.city.fieldName] = addressObj.city;
          model[FormMeta.country.fieldName] = Config.BASE_COUNTRY_CODE;
        }
      }
    }
    // bind from auth profile
    if (!isEmpty(authTempProfile)) {
      const postData = authTempProfile.postData ?? {};
      const personalInfo = postData?.personalInfo ?? {};
      // images
      model[FormMeta.images.fieldName] =
        postData[FormMeta.images.fieldName] ?? [];
      // horoscopeImages
      model[FormMeta.horoscopeImages.fieldName] =
        postData[FormMeta.horoscopeImages.fieldName] ?? [];
      // birthdate
      model[FormMeta.birthdate.fieldName] = personalInfo.dob;
      // birthcity
      model[FormMeta.birthCity.fieldName] =
        postData[FormMeta.birthCity.fieldName] ?? "";
      // birthTime
      model[FormMeta.birthTime.fieldName] =
        postData[FormMeta.birthTime.fieldName] ?? "";
      //  originCountryCode
      model[FormMeta.originCountryCode.fieldName] =
        personalInfo[FormMeta.originCountryCode.fieldName] ?? "";
      // horoscopeMatching
      model[FormMeta.horoscopeMatching.fieldName] =
        postData[FormMeta.horoscopeMatching.fieldName] ??
        FormMeta.horoscopeMatching.defaultValue;
      // horoscopeDetail
      model[FormMeta.horoscopeDetail.fieldName] =
        postData[FormMeta.horoscopeDetail.fieldName] ?? "";

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        // startId
        model[FormMeta.starId.fieldName] =
          personalInfo[FormMeta.starId.fieldName] ?? "";
        // startSignId
        model[FormMeta.starSignId.fieldName] =
          personalInfo[FormMeta.starSignId.fieldName] ?? "";
        // acceptOtherCaste
        model[FormMeta.acceptOtherCaste.fieldName] =
          postData[FormMeta.acceptOtherCaste.fieldName] ?? false;
        model[FormMeta.haveDosh.fieldName] =
          postData[FormMeta.haveDosh.fieldName] ?? "";
      }
    }
    // showAdditionalInfo
    model[FormMeta.showAdditionalInfo.fieldName] = false;
    if (!isEmpty(authTempProfile)) {
      const postData = authTempProfile.postData ?? {};
      const comparator = [
        postData[FormMeta.horoscopeDetail.fieldName],
        postData[FormMeta.horoscopeImages.fieldName],
      ];
      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        comparator.push(
          postData[FormMeta.haveDosh.fieldName],
          postData[FormMeta.acceptOtherCaste.fieldName]
        );
      }
      model[FormMeta.showAdditionalInfo.fieldName] = comparator.some(
        (element) => !isEmpty(element)
      );
    }
  } catch (e) {
    console.log("Error! [bindProfileDataToFormData] ", e);
  }
  return model;
};
