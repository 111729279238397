import React, { Component } from "react";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";

export default class Parent extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "20vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <Typography variant="h6" className="heading">
                  {this.props.lng ? (
                    "Family Info"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-16">
                      පවුලේ තොරතුරු
                    </span>
                  )}
                </Typography>
                <div className="row">
                  <div className="review-col rev-col-left">
                    <p
                      className="review-sub-sec-heading"
                      style={{ paddingLeft: 0 }}
                    >
                      {this.props.lng ? (
                        "Father"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          පියා
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="review-col desktop-mother-col rev-col-right">
                    <p
                      className="review-sub-sec-heading"
                      style={{ paddingLeft: 0 }}
                    >
                      {this.props.lng ? (
                        "Mother"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          මව
                        </span>
                      )}
                    </p>
                  </div>

                  <div
                    className="review-col rev-col-left"
                    style={{ marginBottom: 15 }}
                  >
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Ethnicity" : "ජාතිය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.ethnicityF
                          : this.props.ethnicityF_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Religion" : "ආගම"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.religionF
                          : this.props.religionF_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Caste" : "කුලය"}
                      </div>
                      <div className="con-col">
                        {this.props.casteF ? this.props.casteF : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Profession" : "වෘත්තිය"}
                      </div>
                      <div className="con-col">
                        {this.props.professionF
                          ? this.props.lng
                            ? this.props.professionF
                            : this.props.professionF_si
                          : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Country of Residence" : "පදිංචි රට"}
                      </div>
                      <div className="con-col">
                        {this.props.fCountryOfResidency
                          ? this.props.lng
                            ? this.props.fCountryOfResidency
                            : this.props.fCountryOfResidency_si
                          : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Additional Info" : "අමතර තොරතුරු"}
                      </div>
                      <div className="con-col">
                        {this.props.additionalInfoF
                          ? this.props.additionalInfoF
                          : "-"}
                      </div>
                    </div>
                  </div>

                  <div className="review-col mobile-mother-col rev-col-left">
                    <p
                      className="review-sub-sec-heading"
                      style={{ paddingLeft: 0, marginTop: 10 }}
                    >
                      {this.props.lng ? (
                        "Mother"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          මව
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="review-col rev-col-right">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Ethnicity" : "ජාතිය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.ethnicityM
                          : this.props.ethnicityM_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Religion" : "ආගම"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.religionM
                          : this.props.religionM_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Caste" : "කුලය"}
                      </div>
                      <div className="con-col">
                        {this.props.casteM ? this.props.casteM : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Profession" : "වෘත්තිය"}
                      </div>
                      <div className="con-col">
                        {this.props.professionM
                          ? this.props.lng
                            ? this.props.professionM
                            : this.props.professionM_si
                          : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Country of Residence" : "පදිංචි රට"}
                      </div>
                      <div className="con-col">
                        {this.props.mCountryOfResidency
                          ? this.props.lng
                            ? this.props.mCountryOfResidency
                            : this.props.mCountryOfResidency_si
                          : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Additional Info" : "අමතර තොරතුරු"}
                      </div>
                      <div className="con-col">
                        {this.props.additionalInfoM
                          ? this.props.additionalInfoM
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                { this.props.siblings &&
                  <div className="row">
                    { this.props.siblings.length > 0 &&
                      <div className="review-col rev-col-left">
                        <p
                          className="review-sub-sec-heading"
                          style={{ paddingLeft: 0 }}
                        >
                          {this.props.lng ? (
                            "Siblings"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              සහෝදර සහෝදරියන්
                            </span>
                          )}
                        </p>
                      </div>
                    }
                  </div>
                }
                { this.props.siblings &&
                  <div className="" style={{display:"flex", flexWrap:"wrap"}}>
                    {this.props.siblings.map((sibling, i) => (
                      <div style={{display:"flex", width:"235px", background:"#f2f2f2", height:"50px", borderRadius:"4px", marginRight:"18px", marginBottom:"18px"}}>
                        <div style={{width:"195px", display:"flex", flexDirection:"column", justifyContent:"center", paddingLeft:"14px"}}>
                        {
                            sibling.relationshipType == "YOUNGER" ? this.props.lng === true ? "Younger" : "බාල"
                            : sibling.relationshipType == "OLDER" ? this.props.lng === true ? "Older" : "වැඩිමල්"
                            : this.props.lng === true ? "Twin" : "නිවුන්"
                          }
                          {
                            sibling.relationship == "BROTHER" ? this.props.lng === true ? " Brother" : " සහෝදරයා"
                            : this.props.lng === true ? " Sister" : " සහෝදරිය"
                          }
                          <div style={{fontSize: "12px", color:"#B8B8B8"}}>{sibling.isMarried ? this.props.lng === true ? "Married" : "විවාහක" : this.props.lng === true ? "Unmarried" : "අවිවාහක"} - {sibling.profession && (sibling.profession.name !== "Other" ? this.props.lng ? sibling.profession.name : sibling.profession.name_si : sibling.professionOther)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
