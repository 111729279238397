import React, { useState, useEffect } from "react";
import Navbar from "../components/common/Navbar";
import ReceivedInterest from "../components/my-matches/ReceivedInterestProfile";
import InterestSideView from "../components/my-matches/InterestSideView";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import { getSentInterests } from "../actions/getSentInterests";
import { handleIntBtns } from "../actions/handleIntBtns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Config from "../v2/config";

import "../styles/my-matches.css";

const InterestsSent = (props) => {
  const [lng, setLng] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    setSelectedId(id);
  });

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    setLng(
      localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true
    );

    props.handleIntBtns("sent");
  }, []);

  const handleLng = () => {
    setLng((lng) => !lng);

    localStorage.setItem("lng", !lng);
  };

  return isAccessDenied ? (
    <Unauthorize title={`Sent Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Sent Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div">
          <InterestSideView
            lng={lng}
            intId={selectedId}  
          />
          {selectedId && (
            <ReceivedInterest lng={lng} intId={selectedId} isSent={true} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSentInterests, handleIntBtns: handleIntBtns },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    // post: state.matches.post,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(InterestsSent);
