import { createReduxAction } from "../../../utils";

export const SET_EDIT_AD_PAGE_ADVERTISEMENT = "SET_EDIT_AD_PAGE_ADVERTISEMENT";
export const SET_EDIT_AD_PAGE_PROFILE_IMAGES =
  "SET_EDIT_AD_PAGE_PROFILE_IMAGES";
export const SET_EDIT_AD_PAGE_HOROSCOPE_IMAGES =
  "SET_EDIT_AD_PAGE_HOROSCOPE_IMAGES";
export const SET_EDIT_AD_PAGE_COUNTRY_REGIONS =
  "SET_EDIT_AD_PAGE_COUNTRY_REGIONS";
export const SET_EDIT_AD_PAGE_OLD_SHADOW = "SET_EDIT_AD_PAGE_OLD_SHADOW";
export const SET_EDIT_AD_PAGE_NOTIFICATION = "SET_EDIT_AD_PAGE_NOTIFICATION";
export const SET_EDIT_AD_PAGE_BOOSTRAPPED = "SET_EDIT_AD_PAGE_BOOSTRAPPED";
export const SET_EDIT_AD_COMMUNITIES = "SET_EDIT_AD_COMMUNITIES";
export const SET_EDIT_AD_SHOW_SIBILING_FORM = "SET_EDIT_AD_SHOW_SIBILING_FORM";

export const setEditAdPageAdvertisement = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_ADVERTISEMENT, payload);

export const setEditAdPageProfileImages = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_PROFILE_IMAGES, payload);

export const setEditAdpageHoroscopeImages = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_HOROSCOPE_IMAGES, payload);

export const setEditAdPageCountryRegions = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_COUNTRY_REGIONS, payload);

export const setEditAdPageOldShadow = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_OLD_SHADOW, payload);

export const setEditAdPageNotification = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_NOTIFICATION, payload);

export const setEditAdPageBoostrapped = (payload) =>
  createReduxAction(SET_EDIT_AD_PAGE_BOOSTRAPPED, payload);

export const setEditAdCommunities = (payload) =>
  createReduxAction(SET_EDIT_AD_COMMUNITIES, payload);

export const setEditAdShowSibilingForm = (payload) =>
  createReduxAction(SET_EDIT_AD_SHOW_SIBILING_FORM, payload);
