import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { SectionSubHeading } from "../../custom-typography";
import { useFormikContext } from "formik";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown, {
  Mode,
} from "../../../../../../components/molecules/form-dropdown";
import { fieldVisibility } from "../../../../../../utils";
import FormTextInput from "../../../../../../components/molecules/form-text-input";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";
import Config from "../../../../../../config";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { fetchCommunitiesByReligionId } from "../../../../../../features/generic-data";

export const ParentType = Object.freeze({
  FATHER: "FATHER",
  MOTHER: "MOTHER",
});

const ParentInformation = ({ type }) => {
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    initialValues,
    submitCount,
    setFieldError,
  } = useFormikContext();
  const { t, i18n } = useTranslation();
  const { ethnicities, religions, countries, professions } = useSelector(
    (state) => state.genericData
  );
  const { oldShadow } = useSelector((state) => state.editAdPage);
  const [communities, setCommunities] = useState([]);

  const sectionSubHeading =
    type === ParentType.FATHER ? "common.father" : "common.mother";

  const meta = useMemo(() => {
    const meta = {};
    switch (type) {
      case ParentType.FATHER:
        meta.ethnnicityId = FormMeta.fEthnicityId;
        meta.religionId = FormMeta.fReligionId;
        meta.communityId = FormMeta.fCommunityId;
        meta.subCommunity = FormMeta.fSubCommunity;
        meta.residentCountryCode = FormMeta.fResidentCountryCode;
        meta.caste = FormMeta.fCaste;
        meta.professionId = FormMeta.fProfessionId;
        meta.otherProfession = FormMeta.fOtherProfession;
        break;
      case ParentType.MOTHER:
        meta.ethnnicityId = FormMeta.mEthnicityId;
        meta.religionId = FormMeta.mReligionId;
        meta.communityId = FormMeta.mCommunityId;
        meta.subCommunity = FormMeta.mSubCommunity;
        meta.residentCountryCode = FormMeta.mResidentCountryCode;
        meta.caste = FormMeta.mCaste;
        meta.professionId = FormMeta.mProfessionId;
        meta.otherProfession = FormMeta.mOtherProfession;
        break;
      default:
        break;
    }

    return meta;
  }, [type]);

  const religionId = values[meta.religionId.fieldName];

  useEffect(() => {
    let initialReligionId = oldShadow[meta.religionId.fieldName];
    if (!isEmpty(religionId) && !isEqual(religionId, initialReligionId)) {
      dispatch(fetchCommunitiesByReligionId({ religionId, setCommunities }));
    }
  }, [
    dispatch,
    initialValues,
    meta.religionId.fieldName,
    oldShadow,
    religionId,
    type,
  ]);

  const professionOtherVisibility = useMemo(() => {
    let professionId = values[meta.professionId.fieldName];
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    return (
      fieldVisibility(meta.otherProfession) &&
      professionId === Config.OTHER_PROFESSION_ID
    );
  }, [meta.otherProfession, meta.professionId, values]);

  const otherProfessionFieldMode = useMemo(() => {
    let professionId = values[meta.professionId.fieldName];
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    let initialProfessionId = initialValues[meta.professionId.fieldName];
    if (typeof initialProfessionId === "string") {
      initialProfessionId = parseInt(initialProfessionId);
    }

    if (!isEqual(professionId, initialProfessionId)) {
      return Mode.standard;
    }
    return Mode.interactive;
  }, [initialValues, meta.professionId.fieldName, values]);

  const communityFieldMode = useMemo(() => {
    const religionId = values[meta.religionId.fieldName];
    const initialReligionId = initialValues[meta.religionId.fieldName];
    return isEqual(religionId, initialReligionId)
      ? Mode.interactive
      : Mode.standard;
  }, [initialValues, meta.religionId.fieldName, values]);

  const hasChangedFormField = (fieldMeta) => {
    return hasChangedFormValue({ initialValues, values, meta: fieldMeta });
  };

  const updateFormField = (fieldMeta) => {
    dispatch(updateAdField(fieldMeta, values, t, setFieldError));
  };

  const resetFormField = (fieldMeta) => {
    dispatch(resetChangedField(fieldMeta, initialValues, setFieldValue));
  };

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionSubHeading labelKey={sectionSubHeading} />
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.ethnnicityId)}
            onClickInteractiveSave={() => {
              updateFormField(meta.ethnnicityId);
            }}
            onClickInteractiveClear={() => {
              resetFormField(meta.ethnnicityId);
            }}
            fieldName={meta.ethnnicityId.fieldName}
            value={values[meta.ethnnicityId.fieldName]}
            onChange={handleChange}
            options={ethnicities}
            keyExtractor={meta.ethnnicityId.keyExtractor}
            labelExtractor={meta.ethnnicityId.labelExtractor}
            label={t(meta.ethnnicityId.label)}
            lang={i18n.language}
            errorText={errors[meta.ethnnicityId.fieldName]}
            visibility={fieldVisibility(meta.ethnnicityId)}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormTextInput
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.caste)}
            onClickInteractiveSave={() => {
              updateFormField(meta.caste);
            }}
            onClickInteractiveClear={() => {
              resetFormField(meta.cas);
            }}
            fieldName={meta.caste.fieldName}
            value={values[meta.caste.fieldName]}
            onChange={handleChange}
            label={t(meta.caste.label)}
            lang={i18n.language}
            // labelStyles={labelStyles}
            placeholder={t(meta.caste.placeholder)}
            errorText={submitCount > 0 && errors[meta.caste.fieldName]}
            visibility={fieldVisibility(meta.caste)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.religionId)}
            onClickInteractiveSave={() => updateFormField(meta.religionId)}
            onClickInteractiveClear={() => resetFormField(meta.religionId)}
            fieldName={meta.religionId.fieldName}
            value={values[meta.religionId.fieldName]}
            onChange={handleChange}
            options={religions}
            keyExtractor={meta.religionId.keyExtractor}
            labelExtractor={meta.religionId.labelExtractor}
            label={t(meta.religionId.label)}
            lang={i18n.language}
            errorText={errors[meta.religionId.fieldName]}
            visibility={fieldVisibility(meta.religionId)}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode={communityFieldMode}
            showInteractiveActions={hasChangedFormField(meta.communityId)}
            onClickInteractiveSave={() => updateFormField(meta.communityId)}
            onClickInteractiveClear={() => resetFormField(meta.communityId)}
            fieldName={meta.communityId.fieldName}
            value={values[meta.communityId.fieldName]}
            onChange={handleChange}
            options={communities}
            keyExtractor={meta.communityId.keyExtractor}
            labelExtractor={meta.communityId.labelExtractor}
            label={t(meta.communityId.label)}
            lang={i18n.language}
            errorText={errors[meta.communityId.fieldName]}
            visibility={fieldVisibility(meta.communityId)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormTextInput
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.subCommunity)}
            onClickInteractiveSave={() => updateFormField(meta.subCommunity)}
            onClickInteractiveClear={() => resetFormField(meta.subCommunity)}
            fieldName={meta.subCommunity.fieldName}
            value={values[meta.subCommunity.fieldName]}
            onChange={handleChange}
            label={t(meta.subCommunity.label)}
            lang={i18n.language}
            visibility={fieldVisibility(meta.subCommunity)}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(
              meta.residentCountryCode
            )}
            onClickInteractiveSave={() =>
              updateFormField(meta.residentCountryCode)
            }
            onClickInteractiveClear={() =>
              resetFormField(meta.residentCountryCode)
            }
            fieldName={meta.residentCountryCode.fieldName}
            value={values[meta.residentCountryCode.fieldName]}
            onChange={handleChange}
            options={countries}
            keyExtractor={meta.residentCountryCode.keyExtractor}
            labelExtractor={meta.residentCountryCode.labelExtractor}
            label={t(meta.residentCountryCode.label)}
            lang={i18n.language}
            errorText={errors[meta.residentCountryCode.fieldName]}
            visibility={fieldVisibility(meta.residentCountryCode)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.professionId)}
            onClickInteractiveSave={() => updateFormField(meta.professionId)}
            onClickInteractiveClear={() => resetFormField(meta.professionId)}
            fieldName={meta.professionId.fieldName}
            value={values[meta.professionId.fieldName]}
            onChange={handleChange}
            options={professions}
            keyExtractor={meta.professionId.keyExtractor}
            labelExtractor={meta.professionId.labelExtractor}
            label={t(meta.professionId.label)}
            lang={i18n.language}
            isOptional={true}
            errorText={submitCount > 0 && errors[meta.professionId.fieldName]}
            visibility={fieldVisibility(meta.professionId)}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormTextInput
            mode={otherProfessionFieldMode}
            showInteractiveActions={hasChangedFormField(meta.otherProfession)}
            onClickInteractiveSave={() => updateFormField(meta.otherProfession)}
            onClickInteractiveClear={() => resetFormField(meta.otherProfession)}
            fieldName={meta.otherProfession.fieldName}
            value={values[meta.otherProfession.fieldName]}
            onChange={handleChange}
            label={t(meta.otherProfession.label)}
            lang={i18n.language}
            errorText={errors[meta.otherProfession.fieldName]}
            visibility={
              fieldVisibility(meta.otherProfession) && professionOtherVisibility
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ParentInformation;
