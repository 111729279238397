import { Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useRef } from "react";

import "./style.css";

const FormImageSelector = ({
  image,
  onSelect,
  height = 120,
  onDelete,
  disabled = false,
}) => {
  const inputElementRef = useRef(null);
  let imageView = image;
  if (imageView instanceof File || imageView instanceof Blob) {
    imageView = URL.createObjectURL(image);
  }
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      className="image-selector"
      onClick={() => {
        const inputElement = inputElementRef.current;
        if (inputElement !== null) {
          inputElement.click();
        }
      }}
    >
      {image === undefined ? (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height }}
          className={disabled ? "disabled-image-slot" : ""}
        >
          <AddIcon className="add-icon" />
          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            ref={inputElementRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const files = e.target.files;
              if (files.length !== 0) {
                onSelect(files[0]);
              }
            }}
            disabled={disabled}
          />
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flexWrap: "nowrap", padding: 4 }}
          height={height}
          className={disabled ? "disabled-image-slot" : ""}
        >
          <img
            src={imageView}
            alt="profile-pic"
            style={{
              borderRadius: "4px",
              height: "120px",
              maxWidth: "100%",
              maxHeight: "100%",
              boxSizing: "border-box",
              aspectRatio: "2 / 1",
              objectFit: "contain",
            }}
          />
          {!disabled &&
            onDelete !== undefined &&
            typeof onDelete === "function" && (
              <IconButton size="small" color="secondary" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            )}
        </Grid>
      )}
    </Grid>
  );
};

export default FormImageSelector;
