const STORAGE_LANG_KEY = "lng";

const DeprecationHandler = {
  handleLanguage: () => {
    const currentValue =
      localStorage.getItem(STORAGE_LANG_KEY) === null ||
      JSON.parse(localStorage.getItem(STORAGE_LANG_KEY));
    localStorage.setItem(STORAGE_LANG_KEY, !currentValue);
  },
  getLanguage: () => {
    return (
      localStorage.getItem(STORAGE_LANG_KEY) === null ||
      JSON.parse(localStorage.getItem(STORAGE_LANG_KEY))
    );
  },
  isAuthAuthorized: () => {
    return localStorage.getItem("auth_token") !== null;
  },
};

export default DeprecationHandler;
