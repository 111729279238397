import React from "react";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";

import { connect } from "react-redux";

const Parent = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "20vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <Typography variant="h6" className="heading">
                {props.lng ? (
                  "Parents' Info"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-16">
                    දෙමාපිය තොරතුරු
                  </span>
                )}
              </Typography>
              <div className="row">
                <div className="review-col rev-col-left">
                  <p
                    className="review-sub-sec-heading"
                    style={{ paddingLeft: 0 }}
                  >
                    {props.lng ? (
                      "Father"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        පියා
                      </span>
                    )}
                  </p>
                </div>
                <div className="review-col desktop-mother-col rev-col-right">
                  <p
                    className="review-sub-sec-heading"
                    style={{ paddingLeft: 0 }}
                  >
                    {props.lng ? (
                      "Mother"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">මව</span>
                    )}
                  </p>
                </div>

                <div
                  className="review-col rev-col-left"
                  style={{ marginBottom: 15 }}
                >
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Ethnicity" : "ජාතිය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.father.ethnicity.name
                        : props.father.ethnicity.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Religion" : "ආගම"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.father.religion.name
                        : props.father.religion.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Caste" : "කුලය"}
                    </div>
                    <div className="con-col">
                      {props.father.caste ? props.father.caste : "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Profession" : "වෘත්තිය"}
                    </div>
                    <div className="con-col">
                      {props.father.profession
                        ? props.father.profession.name === "Other"
                          ? props.father.professionOther
                          : props.lng
                          ? props.father.profession.name
                          : props.father.profession.name_si
                        : "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Country of Residence" : "පදිංචි රට"}
                    </div>
                    <div className="con-col">
                      {props.father.residentCountry
                        ? props.lng
                          ? props.father.residentCountry.name
                          : props.father.residentCountry.name_si
                        : "-"}
                    </div>
                  </div>

                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Additional Info" : "අමතර තොරතුරු"}
                    </div>
                    <div className="con-col">
                      {props.father.additionalInfo
                        ? props.father.additionalInfo
                        : "-"}
                    </div>
                  </div>
                </div>

                <div className="review-col mobile-mother-col rev-col-left">
                  <p
                    className="review-sub-sec-heading"
                    style={{ paddingLeft: 0, marginTop: 10 }}
                  >
                    {props.lng ? (
                      "Mother"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">මව</span>
                    )}
                  </p>
                </div>

                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Ethnicity" : "ජාතිය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.mother.ethnicity.name
                        : props.mother.ethnicity.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Religion" : "ආගම"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.mother.religion.name
                        : props.mother.religion.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Caste" : "කුලය"}
                    </div>
                    <div className="con-col">
                      {props.mother.caste ? props.mother.caste : "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Profession" : "වෘත්තිය"}
                    </div>
                    <div className="con-col">
                      {props.mother.profession
                        ? props.mother.profession.name === "Other"
                          ? props.mother.professionOther
                          : props.lng
                          ? props.mother.profession.name
                          : props.mother.profession.name_si
                        : "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Country of Residence" : "පදිංචි රට"}
                    </div>
                    <div className="con-col">
                      {props.mother.residentCountry
                        ? props.lng
                          ? props.mother.residentCountry.name
                          : props.mother.residentCountry.name_si
                        : "-"}
                    </div>
                  </div>

                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Additional Info" : "අමතර තොරතුරු"}
                    </div>
                    <div className="con-col">
                      {props.mother.additionalInfo
                        ? props.mother.additionalInfo
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // father: state.matches.post.post
    //   ? state.matches.post.post.parentInfo.filter(
    //       (el) => el.type === "FATHER"
    //     )[0]
    //   : {},
    // mother: state.matches.post.post
    //   ? state.matches.post.post.parentInfo.filter(
    //       (el) => el.type === "MOTHER"
    //     )[0]
    //   : {},
  };
};

export default connect(mapStateToProps, null)(Parent);
