import React, { useMemo, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { SectionSubHeading } from "../../custom-typography";
import FormDropdown, {
  Mode,
} from "../../../../../../components/molecules/form-dropdown";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { fieldVisibility } from "../../../../../../utils";
import FormDatePicker, {
  DATE_PATTERN,
} from "../../../../../../components/molecules/form-date-picker";
import {
  hasChangedFormValue,
  transformNameFormatsByName,
} from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";
import FormTextInput from "../../../../../../components/molecules/form-text-input";
import Config from "../../../../../../config";

const CaptionText = ({ color, label }) => {
  const { t, i18n } = useTranslation();

  const className = useMemo(() => {
    const { language } = i18n;
    return `caption-text caption-text-${language} caption-text-${color}`;
  }, [color, i18n]);

  return <Typography className={className}>{`* ${t(label)}`}</Typography>;
};

const BasicInformationForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down("md"));
  const {
    values,
    errors,
    initialValues,
    handleChange,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const {
    nameFormatOptions,
    religions,
    genderOptions,
    civilStatusOptions,
    heightOptions,
    hasChildrenOptions,
    gothraOptions,
    motherTongues,
  } = useSelector((state) => state.genericData);
  const { advertisement, communities } = useSelector(
    (state) => state.editAdPage
  );
  // const { authProfile } = useSelector((state) => state.application);
  const dobStartDate = moment().subtract(60, "years").format(DATE_PATTERN);
  const dobEndDate = moment().subtract(18, "years").format(DATE_PATTERN);

  const [dateOfBirth, setDateOfBirth] = useState("");

  const modifiedNameFormats = useMemo(() => {
    if (isEmpty(advertisement)) {
      return nameFormatOptions;
    }
    const personalInfo = advertisement.personalInfo ?? {};
    const output = transformNameFormatsByName({
      nameFormats: nameFormatOptions,
      firstname: personalInfo.fname,
      lastname: personalInfo.lname,
    });
    return output;
  }, [advertisement, nameFormatOptions]);

  const communityFieldMode = useMemo(() => {
    if (!fieldVisibility(FormMeta.communityId)) {
      return Mode.standard;
    }

    return initialValues[FormMeta.religion.fieldName] !==
      values[FormMeta.religion.fieldName]
      ? Mode.standard
      : Mode.interactive;
  }, [initialValues, values]);

  const childrenFieldMode = useMemo(() => {
    if (!fieldVisibility(FormMeta.children)) {
      return Mode.standard;
    }
    const initialCivilStatus = initialValues[FormMeta.civilStatus.fieldName];
    const newCivilStatus = values[FormMeta.civilStatus.fieldName];

    const mode =
      initialCivilStatus !== newCivilStatus &&
      newCivilStatus !== "never_married"
        ? Mode.standard
        : Mode.interactive;

    return mode;
  }, [initialValues, values]);

  const canUpdateRestrictedFields = useMemo(() => {
    if (isEmpty(advertisement)) {
      return false;
    }
    if (isEmpty(advertisement.firstPublishedAt)) {
      return true;
    }
    const firstPublishedAt = moment(advertisement.firstPublishedAt);
    const unrestrictedTime = firstPublishedAt.add(
      Config.SELF_AD_RESTRICTED_FIELD_UPDATABLE_THRSHOLD,
      "day"
    );
    const today = moment();
    return today.isBefore(unrestrictedTime);
  }, [advertisement]);

  const showGothraField = useMemo(() => {
    return (
      fieldVisibility(FormMeta.gothraId) &&
      values[FormMeta.religion.fieldName] === "hindu"
    );
  }, [values]);

  const nameFieldLabelStyle = {};
  if (!isMobileViewport) {
    nameFieldLabelStyle.width = "21% !important";
  }

  const labelStyles = isMobileViewport ? {} : { width: "22%" };

  return (
    <Grid container direction="column" className="row-gap-16">
      <SectionSubHeading labelKey="common.basic" />
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} lg={8}>
          <FormTextInput
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.firstName,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.firstName, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.firstName,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.firstName.fieldName}
            value={values[FormMeta.firstName.fieldName]}
            onChange={handleChange}
            label={t(FormMeta.firstName.label)}
            lang={i18n.language}
            labelStyles={labelStyles}
            errorText={errors[FormMeta.firstName.fieldName]}
            visibility={fieldVisibility(FormMeta.firstName)}
            disabled={advertisement.idVerified || !canUpdateRestrictedFields}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <FormTextInput
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.lastName,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.lastName, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.lastName,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.lastName.fieldName}
            value={values[FormMeta.lastName.fieldName]}
            onChange={handleChange}
            label={t(FormMeta.lastName.label)}
            lang={i18n.language}
            labelStyles={labelStyles}
            errorText={errors[FormMeta.lastName.fieldName]}
            visibility={fieldVisibility(FormMeta.lastName)}
            disabled={advertisement.idVerified || !canUpdateRestrictedFields}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.displayNameFormat,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.displayNameFormat, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.displayNameFormat,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.displayNameFormat.fieldName}
            value={values[FormMeta.displayNameFormat.fieldName]}
            onChange={handleChange}
            options={modifiedNameFormats}
            label={t(FormMeta.displayNameFormat.label)}
            labelStyles={labelStyles}
            lang={i18n.language}
            errorText={errors[FormMeta.displayNameFormat.fieldName]}
            visibility={fieldVisibility(FormMeta.displayNameFormat)}
            igonoreLocaleLabels={true}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.gender,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.gender, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(FormMeta.gender, initialValues, setFieldValue)
              );
            }}
            fieldName={FormMeta.gender.fieldName}
            value={values[FormMeta.gender.fieldName]}
            onChange={handleChange}
            options={genderOptions}
            label={t(FormMeta.gender.label)}
            lang={i18n.language}
            errorText={errors[FormMeta.gender.fieldName]}
            visibility={fieldVisibility(FormMeta.gender.fieldName)}
            disabled={true}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDatePicker
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.dob,
              values: {
                ...values,
                [FormMeta.dob.fieldName]: dateOfBirth,
              },
              initialValues,
            })}
            onClickInteractiveSave={() => {
              const tempValues = { ...values };
              tempValues[FormMeta.dob.fieldName] = dateOfBirth;
              dispatch(updateAdField(FormMeta.dob, tempValues, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(FormMeta.dob, initialValues, setFieldValue)
              );
            }}
            value={values[FormMeta.dob.fieldName]}
            onChange={setDateOfBirth}
            startDate={dobStartDate}
            endDate={dobEndDate}
            label={t(FormMeta.dob.label)}
            errorText={errors[FormMeta.dob.fieldName]}
            visibility={fieldVisibility(FormMeta.dob)}
            disabled={advertisement.idVerified || !canUpdateRestrictedFields}
            lang={i18n.language}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.religion,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(FormMeta.religion, values, t, setFieldError)
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.religion,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.religion.fieldName}
            value={values[FormMeta.religion.fieldName]}
            onChange={handleChange}
            options={religions}
            label={t(FormMeta.religion.label)}
            keyExtractor={FormMeta.religion.keyExtractor}
            labelExtractor={FormMeta.religion.labelExtractor}
            errorText={errors[FormMeta.religion.fieldName]}
            visibility={fieldVisibility(FormMeta.religion)}
            disabled={!canUpdateRestrictedFields}
            lang={i18n.language}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.motherTongueId,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.motherTongueId, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.motherTongueId,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.motherTongueId.fieldName}
            value={values[FormMeta.motherTongueId.fieldName]}
            onChange={handleChange}
            options={motherTongues}
            keyExtractor={FormMeta.motherTongueId.keyExtractor}
            labelExtractor={FormMeta.motherTongueId.labelExtractor}
            label={t(FormMeta.motherTongueId.label)}
            lang={i18n.language}
            visibility={fieldVisibility(FormMeta.motherTongueId)}
            errorText={errors[FormMeta.motherTongueId.fieldName]}
            // disabled={isEmpty(communities)}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode={communityFieldMode}
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.communityId,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.communityId, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.communityId,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.communityId.fieldName}
            value={values[FormMeta.communityId.fieldName]}
            onChange={handleChange}
            options={communities}
            keyExtractor={FormMeta.communityId.keyExtractor}
            labelExtractor={FormMeta.communityId.labelExtractor}
            label={t(FormMeta.communityId.label)}
            lang={i18n.language}
            visibility={fieldVisibility(FormMeta.communityId)}
            errorText={errors[FormMeta.communityId.fieldName]}
            disabled={isEmpty(communities)}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormTextInput
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.subCommunity,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.subCommunity, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.subCommunity,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.subCommunity.fieldName}
            value={values[FormMeta.subCommunity.fieldName]}
            onChange={handleChange}
            label={t(FormMeta.subCommunity.label)}
            lang={i18n.language}
            labelStyles={nameFieldLabelStyle}
            errorText={errors[FormMeta.subCommunity.fieldName]}
            visibility={fieldVisibility(FormMeta.subCommunity)}
            // disabled={advertisement.idVerified || !canUpdateRestrictedFields}
          />
        </Grid>
        {showGothraField && (
          <Grid item className="form-field-container">
            <FormDropdown
              mode="interactive"
              showInteractiveActions={hasChangedFormValue({
                meta: FormMeta.gothraId,
                values,
                initialValues,
              })}
              onClickInteractiveSave={() => {
                dispatch(updateAdField(FormMeta.gothraId, values, t));
              }}
              onClickInteractiveClear={() => {
                dispatch(
                  resetChangedField(
                    FormMeta.gothraId,
                    initialValues,
                    setFieldValue
                  )
                );
              }}
              fieldName={FormMeta.gothraId.fieldName}
              value={values[FormMeta.gothraId.fieldName]}
              onChange={handleChange}
              options={gothraOptions}
              keyExtractor={FormMeta.gothraId.keyExtractor}
              labelExtractor={FormMeta.gothraId.labelExtractor}
              label={t(FormMeta.gothraId.label)}
              lang={i18n.language}
              visibility={showGothraField}
              errorText={errors[FormMeta.gothraId.fieldName]}
              // disabled={isEmpty(communities)}
            />
          </Grid>
        )}
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.height,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.height, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(FormMeta.height, initialValues, setFieldValue)
              );
            }}
            fieldName={FormMeta.height.fieldName}
            value={values[FormMeta.height.fieldName]}
            onChange={handleChange}
            options={heightOptions}
            keyExtractor={FormMeta.height.keyExtractor}
            labelExtractor={FormMeta.height.labelExtractor}
            label={t(FormMeta.height.label)}
            lang={i18n.language}
            igonoreLocaleLabels
            errorText={errors[FormMeta.height.fieldName]}
            visibility={fieldVisibility(FormMeta.height)}
            disabled={!canUpdateRestrictedFields}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.civilStatus,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.civilStatus, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.civilStatus,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.civilStatus.fieldName}
            value={values[FormMeta.civilStatus.fieldName]}
            onChange={handleChange}
            options={civilStatusOptions}
            keyExtractor={FormMeta.civilStatus.keyExtractor}
            labelExtractor={FormMeta.civilStatus.labelExtractor}
            label={t(FormMeta.civilStatus.label)}
            lang={i18n.language}
            errorText={errors[FormMeta.civilStatus.fieldName]}
            visibility={fieldVisibility(FormMeta.civilStatus)}
            disabled={!canUpdateRestrictedFields}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode={childrenFieldMode}
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.children,
              values,
              initialValues,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.children, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.children,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.children.fieldName}
            value={values[FormMeta.children.fieldName]}
            onChange={handleChange}
            options={hasChildrenOptions}
            label={t(FormMeta.children.label)}
            lang={i18n.language}
            visibility={
              fieldVisibility(FormMeta.children) &&
              !isEmpty(values[FormMeta.civilStatus.fieldName]) &&
              values[FormMeta.civilStatus.fieldName] !== "never_married"
            }
            errorText={errors[FormMeta.children.fieldName]}
            disabled={!canUpdateRestrictedFields}
          />
        </Grid>
        <Grid item className="form-field-container"></Grid>
      </Grid>
      {!canUpdateRestrictedFields && (
        <Grid container direction="column" className="caption-text-container">
          {!canUpdateRestrictedFields && (
            <CaptionText
              label={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
                  ? "common.editAdPersonalInfoText1"
                  : "common.editAdPersonalInfoText1"
              }
              color="blue"
            />
          )}
          {advertisement.idVerified && (
            <CaptionText label="common.editAdPersonalInfoText2" color="red" />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default BasicInformationForm;
