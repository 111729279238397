import React, { useContext, useEffect, useState } from "react";
import PageHead from "../../components/atoms/page-head";
import Navbar from "../../../components/common/Navbar";
import AppContext from "../../context";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import { Box, Grid, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import FormDropdown from "../../components/molecules/form-dropdown";
import { isEmpty } from "lodash";
import { getPublicProfile } from "../../features/application";
import ProfileView from "../../templates/profile-view"
import "./style.css";
import Unauthorize from "../../../pages/Unauthorize";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import Config from "../../../v2/config";

const VIEW_TYPE_OPTIONS = [
  { id: "PUBLIC", name: "Public Ad", name_si: "පොදු දැන්වීම" },
  {
    id: "MATCHES",
    name: "View for matches",
    name_si: "ගැලපීම් වලට පෙනෙන අයුරු",
  },
];

const ViewMyAd = () => {
  const [selectedType, setSelectedType] = useState("MATCHES");
  const [profileData, setProfileData] = useState({});

  const history = useHistory();
  const { t } = useTranslation();
  const { authProfile } = useSelector((state) => state.application);
  const { appLanguage, lng, handleLng } = useContext(AppContext);

  const authenticated =
    localStorage.getItem("auth_token") && localStorage.getItem("postId");

  const fetchPost = async (id) => {
    try {
      const publicProfile = await getPublicProfile({
        id: id,
        context: id,
      });
      setProfileData(publicProfile.body);
    } catch (error) {
      console.log("[Error]", error);
    }
  };

  useEffect(() => {
    setProfileData({});
    if (selectedType === "MATCHES" && !isEmpty(authProfile)) {
      setProfileData(authProfile);
    }
    if (selectedType === "PUBLIC" && !isEmpty(authProfile)) {
      fetchPost(authProfile.id);
    }
    
    const body = document.querySelector("#root");
    body.scrollIntoView();
    
  }, [selectedType, authProfile]);

  const state = useSelector((state) => state);

  if (!authenticated) {
    return <Unauthorize title={`My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />;
  }

  return (
    <>
      <PageHead title={`My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <Box className="view-ad-wrapper">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="cus-inner-page cus-container-post"
        >
          <Grid item className="select-type-wrapper">
            <FormDropdown
              options={VIEW_TYPE_OPTIONS}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              keyExtractor="id"
              labelExtractor="name"
              lang={appLanguage}
            />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className="nav-btn-section"
            alignItems="center"
          >
            <Grid
              item
              className="nav-prev-btn"
              onClick={() => history.push("/")}
            >
              <Grid item container alignItems="center">
                <i className="fas fa-angle-left left-angle-icon" />

                <Link className="prev-btn-text">
                  {t("common.backBtnText")}
                </Link>
              </Grid>
            </Grid>
            <Link
              onClick={() => history.push("/edit-my-ad")}
              className="nav-edit-ad"
            >
              {t("common.editMyAd")}
            </Link>
          </Grid>

          <ProfileView
            variant="SELF"
            direction={selectedType}
            data={profileData}
          />
        </Grid>
      </Box>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default ViewMyAd;
