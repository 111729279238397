import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";

import PwdResetModal from "../password-reset/PwdResetModal";
import Main from "./Main";

class LoginCom extends Component {
  state = {
    showFirstView: true,
    email: "",
    phone: "",
    password: "",
    showPassword: false,

    isUsingPhone: false,
    isUserId: false,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: sessionStorage.getItem("countryDialCode")
      ? sessionStorage.getItem("countryDialCode")
      : "+94",

    // error
    emailError: "",
    phoneError: "",
    passwordError: "",
    authError: "",
    failAlert: false,

    discardModal: false,

    // drawer
    rightDrawer: false,
    menuDrawer: false,

    gender: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.gender
      : "",

    // resend email
    resendEmail: false,
    sentSuccess: false,

    // reset password
    resetEmail: "",
    // resetPhone: "",
    showPwdResetModal: false,

    // error
    resetEmailError: "",

    // alert
    showResendSuccess: false,
    showResendFail: false,
    error: "",

    // loading
    loading: false,
    loadingLogin: false,
    resetLoading: false,

    // redirect
    redirectToPayment: false,
    redirectToReview: false,
    redirectToPricing: false,
    redirectToTerms: false,

    // alert
    errorAlert: false,
    errorCode: "",
  };

  componentDidMount = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/ipinfo`)
      .then((res) => {
        if (res.data.country) {
          if (res.data.country.callingCode) {
            // set user dial code
            sessionStorage.setItem(
              "countryDialCode",
              `+${res.data.country.callingCode}`
            );

            this.setState({
              code: `+${res.data.country.callingCode}`,
            });
          }

          if (res.data.country.code) {
            // set user country code
            sessionStorage.setItem("countryCode", res.data.country.code);
          }
        }
      })
      .catch((err) => console.log(err));

    // country
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
      .then((res) => {
        const sortedCountries = res.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.setState({
          countries: sortedCountries,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleCountryCode = (code) => {
    this.setState({ code, showCountryCodes: false });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ phone: parseInt(str, 10) });
  };

  handleEmailMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: false,
      isUserId: false,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
    });
  };

  handlePhoneMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: true,
      isUserId: false,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
      sentSuccess: "",
    });
  };

  handleUserIdMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: false,
      isUserId: true,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
      sentSuccess: "",
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickOpen = () => {
    this.setState({ discardModal: true });
  };

  handleClose = () => {
    this.setState({
      discardModal: false,
      showFirstView: true,
      showPwdResetModal: false,
      email: "",
      password: "",
      showPassword: false,
      emailError: "",
      passwordError: "",
      authError: "",
      resendEmail: false,
      sentSuccess: false,
      resetEmail: "",
      resetEmailError: "",
      showResendSuccess: false,
      showResendFail: false,
      showCountryCodes: false,
      isUsingPhone: false,
    });
  };

  // validate
  validate = () => {
    let emailError = "";
    let phoneError = "";
    let passwordError = "";

    if (this.state.isUsingPhone) {
      if (this.state.phone === "") {
        phoneError = this.props.lng
          ? "Phone number is required"
          : "දුරකථන අංකය අවශ්‍ය වේ";
      }
    } else {
      if (this.state.email === "") {
        emailError = this.props.lng
          ? "Email / Ad ID is required"
          : "විද්‍යුත් තැපැල් / දැන්වීම් කේතය අවශ්‍ය වේ";
      }
    }

    if (this.state.password === "") {
      passwordError = this.props.lng
        ? "Password is required"
        : "මුරපදය අවශ්‍ය වේ";
    }

    if (emailError || passwordError || phoneError) {
      this.setState({
        emailError,
        phoneError,
        passwordError,
      });
      return false;
    }

    return true;
  };

  handleSaveProfile = () => {
    if (this.validate()) {
      this.setState({ loadingLogin: true });
      const data = qs.stringify({
        userId: this.state.isUsingPhone
          ? `${this.state.code}${this.state.phone}`
          : this.state.email,
        password: this.state.password,
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/auth/create_session`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;",
          },
        })
        .then((res) => {
          localStorage.setItem("user_role", "USER");
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("userId", res.data.memberId);          

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem("user_role", res.data.role);
              localStorage.setItem(
                "user_email",
                res.data.email ? res.data.email : ""
              );
              if (res.data.role === 'OPERATOR' || res.data.role === 'OPERATOR_L2') {
                this.setState({ loadingLogin: false });
                this.setState({ email: "", password: "", discardModal: false });
                this.props.history.push("/manage-posts");
              } else {
              }
            })
            .catch((err) => {
              console.log(err);
            });

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account/posts`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (res.data.length > 0) {
                localStorage.setItem(
                  "post_details",
                  JSON.stringify(res.data[0])
                );
                localStorage.setItem("postId", res.data[0].id);
                this.setState({ loadingLogin: false });

                if (this.props.history.location.pathname === "/login") {
                  const redirection = localStorage.getItem("redirect-after-login");
                  if (redirection !== null) {
                    const redirectionObj = JSON.parse(redirection);
                    const { pathname, search} = redirectionObj;
                    localStorage.removeItem("redirect-after-login");
                    this.props.history.push(`${pathname}${search}`);
                  } else {
                    this.props.history.push("/");
                  }
                } else if (
                  this.props.history.location.pathname ===
                    "/unauthorized-user" ||
                  this.props.history.location.pathname === "/access-denied"
                ) {
                  this.props.history.goBack();
                }
              } else {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "auth_token"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("personalInfo");
                    localStorage.removeItem("parentInfo");
                    localStorage.removeItem("privateInfo");

                    if (res.data !== "") {
                      if (res.data.postData.personalInfo) {
                        localStorage.setItem(
                          "personalInfo",
                          JSON.stringify(res.data.postData.personalInfo)
                        );
                      }

                      if (res.data.postData.parentInfo) {
                        localStorage.setItem(
                          "parentInfo",
                          JSON.stringify(res.data.postData.parentInfo)
                        );
                      }

                      if (
                        res.data.postData.horoscopeMatching ||
                        res.data.postData.makePhonePublic
                      ) {
                        localStorage.setItem(
                          "privateInfo",
                          JSON.stringify({
                            createdBy: res.data.postData.createdBy
                              ? res.data.postData.createdBy
                              : "SELF",
                            phoneId: res.data.postData.phoneId,
                            makePhonePublic: res.data.postData.makePhonePublic,
                            horoscopeMatching:
                              res.data.postData.horoscopeMatching,
                            birthTime: res.data.postData.birthTime,
                            birthCity: res.data.postData.birthCity,
                            images: res.data.postData.images,
                            phone: res.data.postData.phone,
                            horoscopeDetail: res.data.postData.horoscopeDetail
                              ? res.data.postData.horoscopeDetail
                              : "",
                            horoscopeImages: res.data.postData.horoscopeImages
                              ? res.data.postData.horoscopeImages
                              : [],
                          })
                        );
                      }
                    }

                    this.props.history.push("/complete-ad-review");
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              console.log(err);

              this.setState({ loadingLogin: false });
            });

          this.setState({ email: "", password: "", discardModal: false });
        })
        .catch((err) => {
          if (err.response.data.code === 1103) {
            this.setState({
              emailError: this.props.lng
                ? "Your email has not been verified. Please check your inbox for the verification email or click Resend to send it again."
                : "ඔබගේ විද්‍යුත් ලිපිනය සත්‍යාපනය කර නොමැත. කරුණාකර සත්‍යාපන විද්‍යුත් තැපෑල සඳහා ඔබගේ එන ලිපි පරීක්ෂා කරන්න හෝ නැවත යැවීමට නැවත යවන්න ක්ලික් කරන්න.",
              resendEmail: true,
            });
          } else if (err.response.data.code === 1102) {
            this.setState({
              authError: this.props.lng
                ? "Username and password does not match"
                : "පරිශීලකයා සහ මුරපදය නොගැලපේ",
            });
          } else {
            this.setState({
              errorAlert: true,
              errorCode: err.response.data.code,
            });
          }
          this.setState({ loadingLogin: false });
        });
    } else {
      this.setState({ loadingLogin: false });
    }
  };

  showPwdResetModal = () => {
    this.setState({
      discardModal: false,
      showPwdResetModal: true,
      resetEmail: "",
      resetEmailError: "",
      showResendSuccess: false,
      showResendFail: false,
    });
  };

  // resend verification email
  handleResendEmail = () => {
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/auth/resend_activation`,
        `email=${this.state.email}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          resendEmail: false,
          authError: "",
          sentSuccess: true,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleBackBtn = () => {
    this.setState({ showCountryCodes: false });
  };

  handleShowCountryCodes = () => {
    this.setState({ showCountryCodes: true });
  };

  hideCountryCodes = () => {
    this.setState({ showCountryCodes: false });
  };

  handleCloseResetPwdModal = () => {
    this.setState({ showPwdResetModal: false, showCountryCodes: false });
  };

  handleShowFirstView = () => {
    this.setState({ showFirstView: true });
  };

  // login when press the enter key
  handleLoginKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-login-btn").click();
    }
  };

  render() {
    const filteredCountries = this.state.countries.filter((country) => {
      return (
        country.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 || country.dialCode.includes(this.state.search)
      );
    });

    return (
      <React.Fragment>
        <Main
          lng={this.props.lng}
          filteredCountries={filteredCountries}
          showCountryCodes={this.state.showCountryCodes}
          showFirstView={this.state.showFirstView}
          sentSuccess={this.state.sentSuccess}
          resendEmail={this.state.resendEmail}
          code={this.state.code}
          email={this.state.email}
          phone={this.state.phone}
          password={this.state.password}
          isUserId={this.state.isUserId}
          isUsingPhone={this.state.isUsingPhone}
          search={this.state.search}
          loadingLogin={this.state.loadingLogin}
          handlePhone={this.handlePhone}
          handleChange={this.handleChange}
          handleResendEmail={this.handleResendEmail}
          handleCountryCode={this.handleCountryCode}
          handleEmailMethod={this.handleEmailMethod}
          handlePhoneMethod={this.handlePhoneMethod}
          handleUserIdMethod={this.handleUserIdMethod}
          authError={this.state.authError}
          emailError={this.state.emailError}
          passwordError={this.state.passwordError}
          phoneError={this.state.phoneError}
          showPwdResetModal={this.showPwdResetModal}
          handleSaveProfile={this.handleSaveProfile}
          handleLoginKeyUp={this.handleLoginKeyUp}
          handleBackBtn={this.handleBackBtn}
          handleShowCountryCodes={this.handleShowCountryCodes}
          handleShowFirstView={this.handleShowFirstView}
          loading={this.state.loading}
          handleClose={this.handleClose}
          isOTPLogin={false}
        />

        <PwdResetModal
          showPwdResetModal={this.state.showPwdResetModal}
          handleCountryCodes={this.handleShowCountryCodes}
          handleCloseResetPwdModal={this.handleCloseResetPwdModal}
          showCountryCodes={this.state.showCountryCodes}
          handleChange={this.handleChange}
          handleCountryCode={this.handleCountryCode}
          hideCountryCodes={this.hideCountryCodes}
          search={this.state.search}
          code={this.state.code}
          filteredCountries={filteredCountries}
          lng={this.props.lng}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(LoginCom);
