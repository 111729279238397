import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

import "../styles/privacy-terms.css";

export default class Privacy extends Component {
  state = {
    lng: true, // default en
  };

  componentDidMount = () => {
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };
  render() {
    return (
      <React.Fragment>
        <PageHead title={`Privacy | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
          >
            <Typography
              variant="h4"
              align="center"
              className="privacy-page-heading"
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ margin: "10px 0 50px " }}
            >
              Last updated date: 03 May 2021
            </Typography>
            <div className="terms-section">
              <div>
                <p>
                  The website, Poruwa.lk <b>[the “Site”/ “Poruwa.lk]</b> is
                  owned by Serendib Systems Pty Ltd of Australia which, together
                  with its affiliate partner <b>[“We”, “Us”, or “Our”]</b> and
                  third party licensors provide an online platform where you as
                  a user <b>[“You”]</b> may place advertisements containing your
                  own personal information and data (or that of consenting third
                  parties) for purposes of seeking a suitable matrimonial
                  partner either on your own behalf or on behalf of such
                  consenting third party <b>[“Matrimonial Advertisement”]</b>.
                </p>
                <p>Our Privacy Policy statement explains the following:</p>

                <ol className="alpha" style={{ paddingLeft: 35 }}>
                  <li style={{ marginBottom: 0 }}>
                    what personal information and data may be provided by You as
                    a user of the Site,
                  </li>
                  <li style={{ marginTop: 0, marginBottom: 0 }}>
                    what information and data do we gather or collect from
                    visitors to, and users (including You) of, the Site by the
                    use of cookies and other technologies, and
                  </li>
                  <li style={{ marginTop: 0, marginBottom: 0 }}>
                    how we store, use, share, disclose, and protect such
                    information and data.
                  </li>
                </ol>

                <p>
                  Our Privacy Policy applies to Poruwa.lk, and to the apps
                  related to the Site. It also applies to the Magazine that we
                  publish and referred to in our Terms and Conditions.
                </p>
                <ol>
                  <li>
                    <h2>We Respect Your Privacy</h2>
                    <ol className="alpha">
                      <li>
                        When You use Poruwa.lk You trust us with your
                        information. We are committed to keeping that trust.
                        That starts with helping You understand Our privacy
                        practices.
                      </li>
                      <li>
                        We adhere to the Australian National Privacy Principles
                        established by the Privacy Act 1988. This policy sets
                        out how we collect and treat your personal information.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>What Information Do We Collect?</h2>
                    <ol className="alpha">
                      <li>
                        Information is automatically collected from You when you
                        access and use the site and its related services and
                        when you enter and/or upload information on the site.
                        This information pertains to your location, your usage
                        of our services, your transaction history on our Site,
                        Information about your device/s and your communication
                        data.
                      </li>
                      <li>
                        Personal information and data provided by You including
                        but not limited to your name, date of birth, civil
                        status, height etc.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Your Consent and Authorisation</h2>
                    <ol className="alpha">
                      <li>
                        By accessing and using Poruwa.lk you consent and
                        authorise us to collect, use, share, disclose, and
                        publish your personal information and data in accordance
                        with Our Privacy Policy.
                      </li>
                      <li>
                        Information so collected will be retained by us for such
                        a period of time as we are permitted under law and or
                        relevant regulations (45 days), notwithstanding that You
                        have ceased to be a user of Poruwa.lk and its services.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>How We Use Your Information</h2>
                    <p>
                      We may use the information that We collect in the
                      following manner:
                    </p>
                    <ol className="alpha">
                      <li>
                        to serve You better in various ways including (i)
                        Providing information and updates about Our services and
                        of new opportunities (ii) improving Our response to
                        Users’ service requests and Our services to be more
                        aligned with the needs of Our users.
                      </li>
                      <li>
                        for purposes of research, marketing, product development
                        and planning in order to improve the services provided
                        by Poruwa.lk in a manner that meets your expectations of
                        a customized and productive service.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Cookies and Third Party Technologies</h2>
                    <p>
                      Cookies are small text files that are stored on your
                      browser or device by websites, apps, online media and
                      advertisements. Poruwa.lk uses cookies and similar
                      technologies for purposes such as:
                    </p>
                    <ol className="alpha">
                      <li>Authenticating users</li>
                      <li>Remembering user preferences and settings</li>
                      <li>Determining the popularity of content</li>
                      <li>
                        Delivering and measuring the effectiveness of
                        advertising campaigns
                      </li>
                      <li>Analysing site traffic and trends</li>
                    </ol>
                  </li>

                  <li>
                    <h2>Information Sharing and Disclosure</h2>
                    <ol className="alpha">
                      <li>
                        We do not sell, trade, or otherwise transfer your
                        personal information to outside parties. This does not
                        include website hosting partners and other parties who
                        assist Us in operating Poruwa.lk, carrying out Our
                        business, or serving Our users. Our user information is
                        shared with such parties under an agreement to keep such
                        information confidential.
                      </li>
                      <li>
                        We may disclose and provide your information including
                        your personal information in order to comply with the
                        law or any regulations, to enforce Our Site policies,
                        and or to safeguard and protect Our rights or the rights
                        of others including property rights and public safety.
                      </li>
                      <li>
                        We may provide information on visitors to Our Site,
                        which does not however disclose their identity, to third
                        parties for marketing, advertising or other uses.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Security of your Personal Information</h2>
                    <ol className="alpha">
                      <li>
                        Your personal information is protected behind secured
                        networks and is only accessible by authorized persons
                        who have special access rights to such systems, and who
                        are required to keep such information confidential.
                      </li>
                      <li>
                        All transactions are processed through a gateway
                        provider and are not stored or processed on our servers.
                      </li>
                      <li>
                        Necessary security measures are in place and get
                        activated whenever a user submits or accesses their
                        information on the Site. This is to safeguard the
                        personal information of users of Poruwa.lk. against
                        unauthorized access.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Magazine</h2>
                    <p>
                      The Magazine publishes information derived from the
                      Matrimonial Advertisements placed by users of Our Site and
                      is restricted to basic details, insufficient to disclose
                      identity. This information is shared by Us with our
                      affiliate partner Serendib Systems Lanka (Pvt) Ltd of Sri
                      Lanka, the owner, publisher and distributor of the
                      Magazine The Magazine too is subject to this Privacy
                      Policy.
                    </p>
                  </li>

                  <li>
                    <h2>Third Party Products & Services</h2>
                    <p>
                      Poruwa.lk may include the products and services of third
                      parties. The websites of these third parties will have
                      their own separate and independent privacy policies. We
                      have no responsibility or liability for the content and
                      activities of these linked sites.
                    </p>
                  </li>

                  <li>
                    <h2>Age Group of Users</h2>
                    <p>
                      You are permitted to use Poruwa.lk and its services only
                      if You satisfy the eligibility criteria set out in Our
                      Terms and Conditions. If You are a Minor and or a person
                      under 18 years of age you are prohibited from using
                      Poruwa.lk and its services.
                    </p>
                  </li>
                  <li>
                    <h2>Amendments to the Privacy Policy</h2>
                    <p>
                      This Privacy Policy may be modified and updated from time
                      to time at our sole discretion and without notice to you.
                      The amended Privacy Policy will be effective immediately
                      upon posting on Poruwa.lk. Your continued access and use
                      of the Site after the date of posting shall be deemed to
                      constitute your acceptance of the amended Privacy Policy.
                    </p>
                  </li>

                  <li>
                    <h2>How to Contact Us</h2>
                    <p>
                      If you have any questions about this Privacy Policy or the
                      services provided by using Poruwa.lk, please contact us
                      directly via{" "}
                      <a href={`mailto:${Config.SUPPORT_EMAIL}`}>{Config.SUPPORT_EMAIL}</a>
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </Grid>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}
