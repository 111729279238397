import React from "react";
import { Grid, Typography, OutlinedInput } from "@material-ui/core";
import "./style.css";
import FormInteractiveButton from "../form-interactive-button";
import FormItemLabel from "../../atoms/form-item-label";

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

const FormTextInput = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  onClickInteractiveClear,
  fieldName,
  value,
  onChange,
  label,
  labelPosition = LABEL_POSITION.left,
  lang,
  multiline = false,
  maxCharactorCount = "",
  placeholder = undefined,
  labelStyles = {},
  visibility = true,
  disabled = false,
  errorText = "",
  onBlur = undefined,
}) => {
  return (
    visibility && (
      <Grid
        container
        direction="row"
        className="form-text-input"
        alignItems="center"
      >
        <FormItemLabel
          text={label}
          lang={lang}
          labelPosition={labelPosition}
          styleOverrides={labelStyles}
        />
        <Grid item xs container direction="column">
          <OutlinedInput
            value={value}
            name={fieldName}
            onChange={onChange}
            notched={false}
            multiline={multiline ? true : false}
            placeholder={placeholder}
            fullWidth
            className={`input-text input-text-${lang}`}
            disabled={disabled}
            error={Boolean(errorText)}
            inputProps={{
              maxLength: maxCharactorCount,
            }}
            onBlur={onBlur}
          />
          {Boolean(errorText) && (
            <Typography
              variant="caption"
              className={`form-error-text form-error-text-${lang}`}
            >
              {errorText}
            </Typography>
          )}
        </Grid>
        {mode === Mode.interactive && showInteractiveActions && (
          <FormInteractiveButton
            onClickInteractiveClear={onClickInteractiveClear}
            onClickInteractiveSave={onClickInteractiveSave}
          />
        )}
      </Grid>
    )
  );
};

export default FormTextInput;
