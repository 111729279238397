import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Grid } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { Formik, isFunction, isString, useFormikContext } from "formik";
import AppContext from "../../../context";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import CreateFlowPage from "../templates/create-flow-page";
import ParentInformation, { ParentType } from "./templates/parent-information";
import FormValidationSchema from "./schema";
import { bindTemporaryProfile, saveParentCreateData } from "./extensions";
import formMeta from "./schema/meta.json";
import { buildModelByFormMeta, scrollOnError } from "../../../utils";

// Form Proxy Component
const Form = ({ children, parentType }) => {
  const { authTempProfile } = useSelector((state) => state.application);
  const { resetForm } = useFormikContext();

  const formData = useMemo(() => {
    const metaModel = buildModelByFormMeta(formMeta);
    metaModel.type = parentType;
    if (isEmpty(authTempProfile?.postData?.parentInfo)) {
      return metaModel;
    }
    const parentInfo = authTempProfile.postData.parentInfo ?? [];
    const parentElement = parentInfo.find(
      (element) => element.type === parentType
    );
    bindTemporaryProfile({ input: metaModel, parent: parentElement });
    return metaModel;
  }, [authTempProfile, parentType]);

  useEffect(() => {
    resetForm({ values: formData });
  }, [formData, resetForm]);

  return <>{children}</>;
};

const initialValues = {
  [ParentType.FATHER]: {
    ...buildModelByFormMeta(formMeta),
    type: ParentType.FATHER,
  },
  [ParentType.MOTHER]: {
    ...buildModelByFormMeta(formMeta),
    type: ParentType.MOTHER,
  },
};

// Main Componenet
const ParentsCreateAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { FATHER, MOTHER } = ParentType;

  //  TODO: refactor two form entity to one entity flow
  const [fatherInformation, setFaterInformation] = useState({});
  const [motherInformation, setMotherInformation] = useState({});
  const [isValidFatherInfo, setIsValidFatherInfo] = useState(false);
  const [isValidMotherInfo, setIsValidMotherInfo] = useState(false);
  const [formSubmitCount, setFormSubmitCount] = useState(0);

  const [fatherAdditionalInfoError, setFatherAdditonalInfoError] = useState("");
  const [motherAdditionalInfoError, setMotherAdditionalInfoError] =
    useState("");

  const onClickBack = () => {
    history.push("complete-ad-personal");
  };

  const handleSubmit = useCallback(async () => {
    try {
      if (!(isValidFatherInfo && isValidMotherInfo)) {
        return;
      }
      saveParentCreateData({
        dispatch,
        history,
        parentInfomation: [fatherInformation, motherInformation],
        fatherSetError: setFatherAdditonalInfoError,
        motherSetError: setMotherAdditionalInfoError,
        t,
      });
    } catch (error) {
      console.log("Unable to save parents data");
    } finally {
      setFormSubmitCount(formSubmitCount + 1);
    }
  }, [
    formSubmitCount,
    isValidFatherInfo,
    isValidMotherInfo,
    fatherInformation,
    motherInformation,
    dispatch,
    history,
    t,
  ]);

  const formValidationSchema = useMemo(() => {
    if (!(isFunction(t) && isString(appLanguage))) {
      return {};
    }
    return FormValidationSchema(t);
  }, [appLanguage, t]);

  return (
    <CreateFlowPage>
      <Formik
        initialValues={initialValues.FATHER}
        validationSchema={formValidationSchema}
        validateOnMount={false}
        validateOnBlur={false}
        onSubmit={() => {}}
      >
        <Form parentType={FATHER}>
          <ParentInformation
            parentType={FATHER}
            setFormValues={setFaterInformation}
            setFomValidity={setIsValidFatherInfo}
            submitCount={formSubmitCount}
            additionalInfoError={fatherAdditionalInfoError}
            setAdditionalInfoError={setFatherAdditonalInfoError}
          />
        </Form>
      </Formik>
      <Formik
        initialValues={initialValues.MOTHER}
        validationSchema={formValidationSchema}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {}}
      >
        <Form parentType={MOTHER}>
          <ParentInformation
            parentType={MOTHER}
            setFormValues={setMotherInformation}
            setFomValidity={setIsValidMotherInfo}
            submitCount={formSubmitCount}
            additionalInfoError={motherAdditionalInfoError}
            setAdditionalInfoError={setMotherAdditionalInfoError}
          />
        </Form>
      </Formik>
      <Grid container direction="row" justifyContent="space-between">
        <Button
          variant="text"
          startIcon={<ArrowBackIos />}
          onClick={onClickBack}
        >
          {t("common.backBtnText")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`button-${appLanguage}`}
          onClick={() => {
            handleSubmit();
            setTimeout(() => {
              scrollOnError();
            }, 500);
          }}
        >
          {t("common.saveAndContinueBtn")}
        </Button>
      </Grid>
    </CreateFlowPage>
  );
};

export default ParentsCreateAccount;
