import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { SectionSubHeading } from "../../custom-typography";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown from "../../../../../../components/molecules/form-dropdown";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import FormTextInput from "../../../../../../components/molecules/form-text-input";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";
import { fieldVisibility } from "../../../../../../utils";
import Config from "../../../../../../config";

const { OTHER_PROFESSION_ID, HOUSE_WIFE_PROFESSION_ID } = Config;
const excludedProfessionIds = [HOUSE_WIFE_PROFESSION_ID];

const EducationAndProfession = () => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    initialValues,
    handleChange,
    submitCount,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const showFieldErrors = submitCount > 0;
  const { t, i18n } = useTranslation();
  const { educationLevels, professions } = useSelector(
    (state) => state.genericData
  );

  const showOtherProfessionField = useMemo(() => {
    let profession = values[FormMeta.profession.fieldName];
    if (typeof profession === "string") {
      profession = parseInt(profession);
    }
    return profession === OTHER_PROFESSION_ID;
  }, [values]);

  const filteredProfessions = useMemo(() => {
    return professions.filter(
      (element) => !excludedProfessionIds.includes(element.id)
    );
  }, [professions]);

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionSubHeading labelKey="common.educationAndProfession" />
      <Grid container direction="row" className="row-gap-8">
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.educationLevel,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.educationLevel, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.educationLevel,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.educationLevel.fieldName}
            value={values.educationLevelId}
            onChange={handleChange}
            options={educationLevels}
            keyExtractor={FormMeta.educationLevel.keyExtractor}
            labelExtractor={FormMeta.educationLevel.labelExtractor}
            lang={i18n.language}
            label={t(FormMeta.educationLevel.label)}
            visibility={fieldVisibility(FormMeta.educationLevel)}
            errorText={showFieldErrors && errors.educationLevelId}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.profession,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(FormMeta.profession, values, t, setFieldError)
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.profession,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.profession.fieldName}
            value={values.professionId}
            onChange={handleChange}
            options={filteredProfessions}
            keyExtractor={FormMeta.profession.keyExtractor}
            labelExtractor={FormMeta.profession.labelExtractor}
            lang={i18n.language}
            label={t(FormMeta.profession.label)}
            visibility={fieldVisibility(FormMeta.profession)}
            errorText={showFieldErrors && errors.professionId}
          />
        </Grid>
        {fieldVisibility && showOtherProfessionField && (
          <Grid item className="form-field-container">
            <FormTextInput
              fieldName={FormMeta.otherProfession.fieldName}
              value={values[FormMeta.otherProfession.fieldName]}
              onChange={handleChange}
              label={t(FormMeta.otherProfession.label)}
              lang={i18n.language}
              visibility={
                fieldVisibility(FormMeta.otherProfession) &&
                showOtherProfessionField
              }
              errorText={errors[FormMeta.otherProfession.fieldName]}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EducationAndProfession;
