import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  buildModelByFormMeta,
  fieldVisibility,
} from "../../../../../../../utils";
import FormMeta from "../../../../../../../meta/sibling-form.json";
import { useFormik, useFormikContext } from "formik";
import FormDropdown from "../../../../../../../components/molecules/form-dropdown";
import { useTranslation } from "react-i18next";
import {
  SIBLING_CIVIL_STATUS_OPTIONS,
  SIBLING_OPTIONS,
} from "../../../../../../../static-data";
import { useDispatch, useSelector } from "react-redux";
import FormTextInput from "../../../../../../../components/molecules/form-text-input";
import SaveIcon from "@material-ui/icons/CheckOutlined";
import CloseIcon from "@material-ui/icons/CloseRounded";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import Config from "../../../../../../../config";
import { setEditAdShowSibilingForm } from "../../../../../../../store/actions/edit-ad-page";

const SiblingForm = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleChange,
    submitForm,
  } = useFormikContext();
  const { professions } = useSelector((state) => state.genericData);

  const showOtherProfessionField = useMemo(() => {
    if (!isEmpty(values) && isObject(values)) {
      let professionId = values.professionId;
      console.log("");
      if (professionId !== undefined) {
        professionId =
          typeof professionId === "string"
            ? parseInt(professionId)
            : professionId;
        return professionId === Config.OTHER_PROFESSION_ID;
      }
    }
    return false;
  }, [values]);

  const onClickCancel = () => {
    dispatch(setEditAdShowSibilingForm(false));
  };

  const showErrors = submitCount > 0;

  return (
    <Grid
      container
      direction="column"
      className="sibling-form-content-container row-gap-16"
    >
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            fieldName={FormMeta.relationship.fieldName}
            value={values[FormMeta.relationship.fieldName]}
            onChange={handleChange}
            options={SIBLING_OPTIONS}
            keyExtractor={FormMeta.relationship.keyExtractor}
            labelExtractor={FormMeta.relationship.labelExtractor}
            label={t(FormMeta.relationship.label)}
            lang={i18n.language}
            visibility={fieldVisibility(FormMeta.relationship)}
            errorText={showErrors && errors[FormMeta.relationship.fieldName]}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            fieldName={FormMeta.isMarried.fieldName}
            value={values[FormMeta.isMarried.fieldName]}
            onChange={handleChange}
            options={SIBLING_CIVIL_STATUS_OPTIONS}
            keyExtractor={FormMeta.isMarried.keyExtractor}
            labelExtractor={FormMeta.isMarried.labelExtractor}
            label={t(FormMeta.isMarried.label)}
            lang={i18n.language}
            visibility={fieldVisibility(FormMeta.isMarried)}
            errorText={showErrors && errors[FormMeta.isMarried.fieldName]}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            fieldName={FormMeta.profession.fieldName}
            value={values[FormMeta.profession.fieldName]}
            onChange={handleChange}
            options={professions}
            keyExtractor={FormMeta.profession.keyExtractor}
            labelExtractor={FormMeta.profession.labelExtractor}
            label={t(FormMeta.profession.label)}
            lang={i18n.language}
            visibility={fieldVisibility(FormMeta.profession)}
            errorText={showErrors && errors[FormMeta.profession.fieldName]}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormTextInput
            fieldName={FormMeta.otherProfession.fieldName}
            value={values[FormMeta.otherProfession.fieldName]}
            onChange={handleChange}
            label={t(FormMeta.otherProfession.label)}
            lang={i18n.language}
            visibility={
              fieldVisibility(FormMeta.otherProfession) &&
              showOtherProfessionField
            }
            errorText={showErrors && errors[FormMeta.otherProfession.fieldName]}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="form-button-container">
        <IconButton
          className="form-button outline-success"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          className="form-button outline-error"
          onClick={onClickCancel}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SiblingForm;
