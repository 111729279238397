import React, { useState, useEffect, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import qs from "qs";
import { CircularProgress, Button, Grid, Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import Male from "../../images/groom.png";
import Female from "../../images/bride.png";
import Empty from "../../images/empty-profile.png";
import UnmatchModal from "../modals/UnmatchModal";
import AlertModal from "./detail-page/AlertModal";
import { setPreLoading } from "../../actions/setPreLoading";
import { getMatchedPosts } from "../../actions/getMatchedPosts";
import { fetchNotificationCount } from "../../actions/fetchNotificationCount";
import {updateMatch} from "../../actions/updateMatch";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getInnerHeight } from "./methods/getInnerHeight";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import SendMessageTextBox from "./SendMessageTextBox";
import { uniqBy, isEmpty } from "lodash";
import Config from "../../v2/config";

function MessageView(props) {
  const [rawMessages, setRawMessages] = useState([]);
  const [groupMessages, setGroupedMessages] = useState({});
  const [avatar, setAvatar] = useState(Empty);
  const [preLoading, setPreLoading] = useState(false);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);
  const [loadingIgnoreYes, setLoadingIgnoreYes] = useState(false);
  const [loadingIgnoreMsg, setLoadingIgnoreMsg] = useState(false);
  const [nudge, setNudge] = useState(false);
  const [delMsgId, setDelMsgId] = useState('');
  const [isInitializedMsgs, setIsInitializedMsgs] = useState(false);

  const { authProfile } = useSelector((state) => state.application);

  const chatOptions = useMemo(() => {
    let chatStatus = { isDisableChat: true, chatNotice: "" };
    if (!isInitializedMsgs) {
      chatStatus = { isDisableChat: true, chatNotice: "" };
      return chatStatus;
    }
    if (
      Config.CURRENT_PLATFORM === Config.PLATFORMS.IN &&
      isEmpty(groupMessages)
    ) {
      const gender = authProfile?.personalInfo?.gender;
      switch (gender) {
        case "MALE":
          chatStatus = {
            isDisableChat: true,
            chatNotice:
              "They will initiate the conversation. Kindly wait for their first message to begin the chat",
          };
          break;
        case "FEMALE":
          chatStatus = {
            isDisableChat: false,
            chatNotice:
              "You have the first move! Send the first message to initiate the conversation.",
          };
          break;
        default:
          chatStatus = {
            isDisableChat: false,
            chatNotice: "",
          };
      }
      return chatStatus;
    }
    chatStatus = { isDisableChat: false, chatNotice: "" };
    return chatStatus;
  }, [authProfile, groupMessages, isInitializedMsgs]);

  useEffect(() => {
    // avatar
    if (props.post && props.post.avatar) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
            props.post.id
          }/images/${props.post.avatar}?thumbnail=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);

          setAvatar(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getInnerHeight("message-content", 294);
  }, []);

  useEffect(() => {
    // clear existing messages
    // setAllMessage([]);
    setRawMessages([]);
    setGroupedMessages({});
    // props.getMatchedPosts();
    setTimeout(() => {
      getAllMessages();
    }, 500);
  }, [props.intId]);

  useEffect(() => {
    getPostData();
  }, [props.intId, props.messagePostData]);

  useEffect(() => {
    if ((!props.inactivePost && !props.post.isDisabledProfile && !props.post.ignored) &&
      (((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && !JSON.parse(localStorage.getItem("post_details")).isManaged) || (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2"))) {
      window.ws.onmessage = (event) => {
        if (event === undefined) {
          return;
        }
        let msgData = event.data
          ? event.data === "pong"
            ? event.data
            : JSON.parse(event.data)
          : {};
        if (msgData !== "pong") {
          props.updateMatch(msgData);
          if (props.intId === msgData.data.interestId) {
            const rawData = [...rawMessages, msgData.data];
            setRawMessages(rawData);
            const map = groupMessagesByDate(rawData);
            setGroupedMessages(map);
            // getMessages(rawData);
            scrollToBottom();
            if (msgData.data.sender === "OTHER_PARTY" && document.getElementById("message-content")) {
              seenMessages();
            }
          }
          // props.getMatchedPosts();
          props.fetchNotificationCount();
        }
      };
    }
  });

  const groupMessagesByDate = (messages) => {
    const map = {};
    for (const msg of messages) {
      const messageDate = moment.utc(msg.createdAt).local().format("YYYY-MM-DD");
      if (map[messageDate] === undefined) {
        map[messageDate] = [];
      }
      map[messageDate].push(msg);
    }
    return map;
  };

  const getPostData = () => {
    setNudge(props.messagePostData.shouldShowNudge ? props.messagePostData.shouldShowNudge : false)
  }

  const getAllMessages = async () => {
    setIsInitializedMsgs(false);
    try {
      setPreLoading(true);
      const { chatToken, fetchChatToken, post: interest } = props;
      if (moment().isAfter(moment(chatToken.expiresAt))) {
        await fetchChatToken();
      }
      const searchParams = new URLSearchParams();
      searchParams.append("token", chatToken.token);
      searchParams.append("interestId", interest.id);
      let URL = `${process.env.REACT_APP_API_URL}/v1`;
      URL += `/chat_messages`;
      URL += `?${searchParams.toString()}`;
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      if (response !== undefined && response.status === 200) {
        setRawMessages(response.data);
        const map = groupMessagesByDate(response.data);
        setGroupedMessages(map);
        if (
          response.data.length > 0 &&
          response.data[response.data.length - 1].seen === false
        ) {
          seenMessages();
        }
      }
      setPreLoading(false);
      props.setPreLoading(false);
      scrollToBottom(); 
    } catch (error) {
      console.log("Error! getAllMessages ", error);
      setPreLoading(false);
      props.setPreLoading(false);
    }
    setIsInitializedMsgs(true);
    // if (
    //   moment().format() <
    //   moment
    //     .utc(sessionStorage.getItem("chat_token_expires_in"))
    //     .local()
    //     .format()
    // ) {
    //   getAllMessagesFun();
    // } else {
    //   // chat_token
    //   axios
    //     .get(
    //       `${
    //         process.env.REACT_APP_API_URL
    //       }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       sessionStorage.setItem("chat_token", res.data.token);
    //       sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);
    //       getAllMessagesFun();
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  // const getAllMessagesFun = () => {
  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_API_URL
  //       }/v1/chat_messages?token=${sessionStorage.getItem(
  //         "chat_token"
  //       )}&interestId=${props.intId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setRawMessages(res.data);
  //       getMessages(res.data);

  //       if (
  //         res.data.length > 0 &&
  //         res.data[res.data.length - 1].seen === false
  //       ) {
  //         seenMessages();
  //       }
  //       setPreLoading(false);
  //       props.setPreLoading(false);
  //       scrollToBottom();
  //     })
  //     .catch((err) => {
  //       setPreLoading(false);
  //       props.setPreLoading(false);
  //       console.log(err);
  //     });
  // };

  // const getMessages = (data) => {
  //   const groups = data.reduce((groups, msg) => {
  //     const date = moment.utc(msg.createdAt).local().format("YYYY-MM-DD");
  //     if (!groups[date]) {
  //       groups[date] = [];
  //     }
  //     groups[date].push(msg);
  //     return groups;
  //   }, {});

  //   // Edit: to add it in the array format instead
  //   const groupByDate = Object.keys(groups).map((date) => {
  //     return {
  //       date,
  //       message: groups[date],
  //     };
  //   });

  //   setAllMessage(groupByDate);
  // };

  const seenMessages = () => {
    const data = `token=${sessionStorage.getItem("chat_token")}&interestId=${
      props.intId
    }`;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/chat_messages/mark_seen`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            ContentType: "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // props.getMatchedPosts();
        props.fetchNotificationCount();
      })
      .catch((err) => console.log(err));
  };

  const handleSentMessage = async (msg) => {
    const { chatToken, fetchChatToken, post: interest } = props;
    try {
      if (moment().isAfter(moment(chatToken.expiresAt))) {
        await fetchChatToken();
      }
      const payload = qs.stringify({
        token: chatToken.token,
        interestId: interest.id,
        message: msg,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/chat_messages`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          ContentType: "application/x-www-form-urlencoded",
          }
        }
      );
      if (response !== undefined && response.status === 200) {
        const msgs = uniqBy([...rawMessages, response.data], "id");
        setRawMessages(msgs);
        const meesageMap = groupMessagesByDate(msgs);
        setGroupedMessages(meesageMap);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error! handleSentMessage ", error);
    }

    // const getMessages = (data) => {
    //   const groups = data.reduce((groups, msg) => {
    //     const date = moment.utc(msg.createdAt).local().format("YYYY-MM-DD");
    //     if (!groups[date]) {
    //       groups[date] = [];
    //     }
    //     groups[date].push(msg);
    //     return groups;
    //   }, {});
  
    //   // Edit: to add it in the array format instead
    //   const groupByDate = Object.keys(groups).map((date) => {
    //     return {
    //       date,
    //       message: groups[date],
    //     };
    //   });
  
    //   // setAllMessage(groupByDate);
    // };

    // if (
    //   moment().format() <
    //   moment
    //     .utc(sessionStorage.getItem("chat_token_expires_in"))
    //     .local()
    //     .format()
    // ) {
    //   sendMessageFun(msg);
    // } else {
    //   // chat_token
    //   axios
    //     .get(
    //       `${
    //         process.env.REACT_APP_API_URL
    //       }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       sessionStorage.setItem("chat_token", res.data.token);
    //       sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

    //       sendMessageFun(msg);
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  // const sendMessageFun = (msg) => {
  //   if (msg) {
  //     const data = qs.stringify({
  //       token: sessionStorage.getItem("chat_token"),
  //       interestId: props.intId,
  //       message: msg,
  //     });

  //     axios
  //       .post(`${process.env.REACT_APP_API_URL}/v1/chat_messages`, data, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //           ContentType: "application/x-www-form-urlencoded",
  //         },
  //       })
  //       .then((res) => {
  //         scrollToBottom();
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };

  const scrollToBottom = () => {
    var div = document.getElementById("message-content");
    if (div !== null) {
      div.scrollTop = div.scrollHeight - div.clientHeight;
    }
  };

  const handleViewProfile = (id, post) => {
    if (props.post.isDisabledProfile) {
      sessionStorage.setItem("unavailable_profile", JSON.stringify(post));
      props.history.push("/unavailable-profile");
    } else {
      const interest = post;
      const searchParams = new URLSearchParams();
      searchParams.append("id", interest.post.id);
      props.history.push({
        pathname: "/post",
        search: `?${searchParams.toString()}`
      });
    }
  };

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingIgnore(false);
        setUnmatchModal(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  const handleDeleteInterest = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem("postId")}/interests/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNudgedismiss = (intId) => {
    setLoadingIgnoreYes(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem("postId")}/interests/${intId}/nudge_dismiss`, 
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          ContentType: "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        setLoadingIgnoreYes(false);
        setNudge(false);
      })
      .catch((err) => {
        setLoadingIgnoreYes(false);
        console.log(err)
      }
      );
  }

  const handleDeleteMsg = (id) => {
    setDelMsgId(id);
    setLoadingIgnoreMsg(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/chat_messages/${id}?token=${sessionStorage.getItem("chat_token")}
        &interestId=${props.intId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        // getAllMessages();
        const messages = rawMessages.filter((msg) => msg.id !== id);
        setRawMessages(messages);
        const map = groupMessagesByDate(messages);
        setGroupedMessages(map);
        setTimeout(() => {
          setLoadingIgnoreMsg(false);
        }, 1000);

      }).catch((err) => {
        setLoadingIgnoreMsg(false);
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      <div
        className={
          props.isEmptyPost ? "message-view-div" : "message-view-div show-view"
        }
      >
        <span
          className="dis-back-btn back-btn-msg"
          onClick={() => {
            props.history.push({ pathname: "/messages"})
          }}
          style={{ margin: 15 }}
        >
          <i className="fas fa-angle-left"></i>{" "}
          {props.lng ? "Back" : "ආපසු යන්න"}
        </span>

        {(props.post && props.post.post) ? (
          <div className="profile-summery-div">
            <img
              src={
                props.post.avatar
                  ? avatar
                  : props.post.post.personalInfo.gender === "MALE"
                  ? Male
                  : Female
              }
              className="profile-pic"
              alt="profile pic"
            />
            <p className="name">
                {props.post.post.personalInfo.displayName}
            </p>
            <p className="message">
              {props.post.post.personalInfo.age}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
              {props.post.post.personalInfo.residentCity}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
              {props.post.post.personalInfo.profession.name === "Other"
                ? props.post.post.personalInfo.professionOther
                : props.lng
                ? props.post.post.personalInfo.profession.name
                : props.post.post.personalInfo.profession.name_si}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>
              {Math.floor(
                Math.round(props.post.post.personalInfo.height / 2.54) / 12
              )}
              ' {Math.round(props.post.post.personalInfo.height / 2.54) % 12}"
            </p>
            <p>
              {(Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP) &&
                <button onClick={() => handleDeleteInterest(props.intId)}>Remove Interest</button>
              }
            </p>
            {(!props.post.isDisabledProfile && !props.post.restricted) &&
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewProfile(props.post.post.id, props.post)
                }}
              >
                View Profile <i className="fas fa-angle-right"></i>
              </Link>
            }
          </div>
        ) : null}
        <div className="message-content" id="message-content">
          {preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            Object.entries(groupMessages).map(([messageDate, msgs], i) => (
              <div className="message-div" key={i}>
                <p className="message-date">
                  {/* {moment.utc(el.date).local().format("ddd, DD MMM")} */}
                  {messageDate}
                </p>
                {msgs.map((el, i) => (
                  <div
                    className={
                      el.sender === "SELF" ? "outgoing-msg" : "incomming-msg"
                    }
                    key={i}
                  >
                    {
                     (moment(new Date()).diff(moment.utc(el.createdAt),'hours') <= 24 && el.sender === "SELF" && el.message != "") ? (
                      <>
                        <OverlayTrigger
                          trigger="click"
                          placement={'top'}
                          overlay={
                            <Popover 
                              id={`popover-positioned-top`} 
                              onClick={() => handleDeleteMsg(el.id)} 
                              style={{backgroundColor: "white", padding: "8px", borderRadius: "3px" }}
                            >
                              <Popover.Body style={{fontSize: "12px", cursor: 'pointer'}} >
                                 Delete Message
                              </Popover.Body>
                            </Popover>
                          }
                          rootClose={true}
                          onClick = {document.body.click()}
                        >
                          <MoreVertIcon 
                            style={{fontSize: "14px", color: "white", position: "absolute", right: "1px", cursor: "pointer"}}
                          />
                        </OverlayTrigger>
                      </>
                     ) : null
                    }
                    <p className={(moment(new Date()).diff(moment.utc(el.createdAt),'hours') <= 24 && el.sender === "SELF") === true ? "last-message" : "message"}>
                      {
                        (loadingIgnoreMsg && delMsgId === el.id) ? <CircularProgress size={15} style={{ color: "white" }} /> : 
                        <>
                          {el.message === "" ? <p className="message-del">message deleted</p>: el.message}
                        </>
                      }
                    </p>
                    <p className = {el.message === "" ? "message-time-del" : "message-time"}>
                      {moment.utc(el.createdAt).local().format("hh:mm a")}
                      {el.message != "" && el.sender === 'SELF' && 
                        <CheckIcon className={el.seen ? "message-seen" : "message-nt-seen"} />
                      }
                    </p>
                  </div>
                ))}
              </div>
            ))
          )
          }
          {(!preLoading && nudge) ? <>
            <div className="outgoing-msg" style={{backgroundColor: "#f1f1f1", width: "100%", maxWidth: "100%", padding: "0", marginBottom: "2px"}}>
              <p className="message-date">
                  {moment.utc().local().format("ddd, DD MMM")}
              </p>
            </div>
            <div className="outgoing-msg" style={{backgroundColor: "#E3F2FD", width: "100%", maxWidth: "100%", padding: "0", marginBottom: "12px", marginTop: "0px", border: "1px solid #32ACF1", borderRadius: "5px"}}>
              <div className="nudge-container">
                <div className="nudge-message">
                  {props.lng ? (
                    "Hmm... looks like you haven’t responded in a few days. Are you still interested in this profile?"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ඔබ දින කිහිපයකින් ප්‍රතිචාර දක්වා නැති බව පෙනේ. ඔබ තවමත් මෙම දැන්වීම ගැන උනන්දු ද?
                    </span>
                  )}
                </div>
                <div className="nudge-buttons">
                  <Button
                    variant="contained"
                    className="common-save-btn"
                    style={{marginRight: "12px", marginTop: "12px", marginBottom: "10px"}}
                    onClick={()=>handleNudgedismiss(props.intId)}
                  >
                    {loadingIgnoreYes ? (
                      <div className="loading-div" style={{top: "85%"}}>
                        <CircularProgress size={15} style={{ color: "#fff" }} />
                      </div>
                    ) : (
                      <span>
                        {props.lng === true ? (
                          "Yes"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-10">
                            ඔව්
                          </span>
                        )}
                      </span>
                    ) }
                  </Button>
                  <Button
                    variant="contained"
                    className="common-unmatch-btn"
                    onClick={handleUnmatchModal}
                    style={{marginTop: "12px", marginBottom: "10px"}}
                  >
                    {loadingIgnore ? (
                      <div className="loading-div">
                        <CircularProgress size={20} style={{ color: "#000" }} />
                      </div>
                    ) : (
                      <span>
                        {props.lng === true ? (
                          "Unmatch"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-10">
                            නොගලපන්න
                          </span>
                        )}
                    </span>
                    )}
                  </Button>
                </div>
              </div> 
            </div> 
          </> : null
          }
          {isInitializedMsgs &&
            !isEmpty(chatOptions.chatNotice) && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                className="chat-restriction-container"
              >
                <Typography className="chat-restriction-message">
                  {chatOptions.chatNotice}
                </Typography>
              </Grid>
            )}
        </div>
        <div className="message-input-container" style={{ height: 115 }}>
          <div className="input-container">
            {(props.post && props.post.post.isExpired) && (
              <span className="is-expired">
                {props.lng ? (
                  "This ad has expired. You may not receive a response."
                ) : (
                  <span className="sinhala-size-10">
                    මෙම දැන්වීම කල් ඉකුත් වී ඇත. ඇතැම්විට ඔබට ප්‍රතිචාරයක්
                    නොලැබෙනු ඇත.
                  </span>
                )}
              </span>
            )}
            {(props.post && !props.post.ignored && props.post.isDisabledProfile) && (
              <span
                onClick={handleUnmatchModal}
                className="unmatch-profile-link"
              >
                Unmatch this profile
              </span>
            )}
            <SendMessageTextBox
              handleSentMessage={handleSentMessage}
              inactivePost={props?.inactivePost}
              lng={props?.lng}
              isDisabledProfile={props?.post?.isDisabledProfile}
              ignored={props?.post?.ignored}
              isDisableChat={chatOptions.isDisableChat}
            />
          </div>
        </div>
      </div>

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        loading={props.loading}
      />

      <UnmatchModal
        lng={props.lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
      />
    </React.Fragment>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPreLoading,
      getMatchedPosts,
      updateMatch,
      fetchNotificationCount
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    messagePostData: state.matches.messagePostData
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(MessageView));
