import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useHistory } from "react-router-dom";
import CommentSection, { FIELDS } from "../components/review/CommentSection";
import UserApi from "../services/api/user";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useFormik } from "formik";

import "../styles/review-comments.css";

const GOOGLE_REVIEW_HREF =
  "https://search.google.com/local/writereview?placeid=ChIJI_F7HtFb4joRkTYQHNGS-6Q";

const ReviewComments = () => {
  const history = useHistory();

  const [disabled, setDisabled] = useState(true);
  const [lng, setLng] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const form = useFormik({
    initialValues: {
      matchRating: 0,
      matchReview: "",
      overallRating: 0,
      overallReview: "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const result = await UserApi.postUserReviews({ payload: values });
        setSubmitStatus(result.success);

        if (!result.success) {
          return;
        }
        if (values.overallRating > 3 && values.matchRating > 3) {
          setTimeout(() => {
            window.open(GOOGLE_REVIEW_HREF, "_blank");
            history.push("/");
          }, 1000);
        } else {
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
        localStorage.setItem("googleReviewCount", 2);

        setLoading(false);
      } catch (e) {
        // TODO: crash reporting integration
      }
    },
  });

  useEffect(() => {
    setDisabled(form.values.matchRating <= 0 || form.values.overallRating <= 0);
  }, [form.values.matchRating, form.values.overallRating]);

  useEffect(() => {
    setLng(localStorage.getItem("lng") === "true");
  }, []);

  const handleLng = () => {
    setLng((lng) => !lng);
    localStorage.setItem("lng", !lng);
  };

  return (
    <React.Fragment>
      <Navbar handleLng={handleLng} lng={lng} />
      <Grid container direction="row" justifyContent="center">
        <Grid
          container
          direction="column"
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          style={{
            paddingBlock: 24,
            paddingInline: 16,
          }}
        >
          <Grid item className="back-btn-container">
            <Button
              variant="text"
              className="back-btn-in-detail-pages"
              onClick={() => history.push("/")}
            >
              <i className="fas fa-angle-left"></i> &nbsp;
              {lng ? "Back" : <span className="sinhala-font-styles">ආපසු</span>}
            </Button>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid
              item
              xs={12}
              md={8}
              container
              direction="column"
              style={{ rowGap: 16 }}
            >
              <Typography align="center">
                {lng ? (
                  `Your valuable feedback helps us improve our service and\n help you find your perfect match.`
                ) : (
                  <span className="sinhala-font-styles">
                    ඔබගේ වටිනා ප්‍රතිචාරය අපගේ සේවාවන් වැඩිදියුණු කිරීමට සහ ඔබට
                    වඩාත්ම ගැලපෙන පාර්ශවයන් සම්බන්ද කරගැනීමට ඉවහල් වේ.
                  </span>
                )}
              </Typography>
              <CommentSection
                headerText={
                  lng ? (
                    `How was your experience with the users you matched with on Poruwa.lk?`
                  ) : (
                    <span className="sinhala-font-styles">
                      පෝරුව.lk තුලින් ඔබ සමග ගැලපුණු පාර්ශවයන් සම්බන්දව ඔබගේ
                      අත්දැකීම්/අදහස් මොනවාද?
                    </span>
                  )
                }
                values={{
                  [FIELDS.startRating]: form.values.matchRating,
                  [FIELDS.description]: form.values.matchReview,
                }}
                onChange={(field, value) => {
                  switch (field) {
                    case FIELDS.startRating: {
                      form.setFieldValue("matchRating", value);
                      break;
                    }
                    case FIELDS.description: {
                      form.setFieldValue("matchReview", value);
                      break;
                    }
                  }
                }}
                language={lng}
              />
              <CommentSection
                headerText={
                  lng ? (
                    `What is your feedback about Poruwa.lk service and the website? `
                  ) : (
                    <span className="sinhala-font-styles">
                      පෝරුව.lk සේවාව සහ වෙබ් අඩවිය ගැන ඔබගේ අදහස කුමක්ද?
                    </span>
                  )
                }
                values={{
                  [FIELDS.startRating]: form.values.overallRating,
                  [FIELDS.description]: form.values.overallReview,
                }}
                onChange={(field, value) => {
                  switch (field) {
                    case FIELDS.startRating: {
                      form.setFieldValue("overallRating", value);
                      break;
                    }
                    case FIELDS.description: {
                      form.setFieldValue("overallReview", value);
                      break;
                    }
                  }
                }}
                language={lng}
              />
              <Grid item container direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={form.submitForm}
                  style={{ width: "50%" }}
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#000" }} />
                  ) : (
                    <span>
                      {" "}
                      {lng ? (
                        "Submit"
                      ) : (
                        <span className="sinhala-font-styles">
                          ඉදිරිපත් කරන්න
                        </span>
                      )}
                    </span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer lng={lng} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={submitStatus}
      >
        <Alert severity="success">
          {lng ? (
            "Thank you for your valued feedback !!!"
          ) : (
            <span className="sinhala-font-styles">
              ඔබගේ වටිනා ප්‍රතිචාරයට ස්තූතියි !!!
            </span>
          )}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ReviewComments;
