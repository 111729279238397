import React, { useEffect, useState } from "react";
import DataColumn from "../../../../components/molecules/data-column";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../features/profile-view";
import { Grid, Typography } from "@material-ui/core";
import DataSection from "../../../../components/organisms/data-section";
import { fieldVisibility } from "../../../../utils";
import formMeta from "../../form-meta/personal-form-meta";

const PersonalInformation = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({});

  useEffect(() => {
    if (isEmpty(data)) return;
    generateDisplayValues({
      section: DataDisplaySection.personalInformation,
      data: data,
      lang: i18n.language,
    }).then((values) => setValues(values));
  }, [data, i18n.language]);

  return (
    !isEmpty(data) && (
      <DataSection heading="post.personalInfo">
        <Typography
          className={`post-data-section-title typo-h5-${i18n.language} font-weight-500 capitalized-text`}
        >
          {t("post.basic")}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className="data-column-container"
        >
          <DataColumn
            label={t(formMeta.ethnicity.label)}
            value={values.ethnicity}
            visibility={fieldVisibility(formMeta.ethnicity)}
          />
          <DataColumn
            label={t(formMeta.caste.label)}
            value={values.caste}
            visibility={fieldVisibility(formMeta.caste)}
          />
          <DataColumn
            label={t(formMeta.religion.label)}
            value={values.religion}
            visibility={fieldVisibility(formMeta.religion)}
          />
          <DataColumn
            label={t(formMeta.motherTongueId.label)}
            value={values.motherTongue}
            visibility={fieldVisibility(formMeta.motherTongueId)}
          />
          <DataColumn
            label={t(formMeta.communityId.label)}
            value={values.community}
            visibility={fieldVisibility(formMeta.communityId)}
          />
          <DataColumn
            label={t(formMeta.subCommunity.label)}
            value={values.subCommunity}
            visibility={fieldVisibility(formMeta.subCommunity)}
          />
          {!isEmpty(values.gothra) && (
            <DataColumn
              label={t(formMeta.gothraId.label)}
              value={values.gothra}
              visibility={fieldVisibility(formMeta.gothraId)}
            />
          )}
          <DataColumn
            label={t(formMeta.height.label)}
            value={values.height}
            visibility={fieldVisibility(formMeta.height)}
          />
          <DataColumn
            label={t(formMeta.age.label)}
            value={t("createflow.ageDisplayFormat", {
              age: values.age,
            })}
            visibility={fieldVisibility(formMeta.age)}
          />
          <DataColumn
            label={t(formMeta.civilStatus.label)}
            value={values.civilStatus}
            visibility={fieldVisibility(formMeta.civilStatus)}
          />
          {values.hasChildren && (
            <DataColumn
              label={t(formMeta.children.label)}
              value={values.hasChildren}
              visibility={fieldVisibility(formMeta.children)}
            />
          )}
        </Grid>

        <Typography
          className={`post-data-section-title typo-h5-${i18n.language} font-weight-500 capitalized-text`}
        >
          {t("post.residency")}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className="data-column-container"
        >
          <DataColumn
            label={t(formMeta.country.label)}
            value={values.residentCountry}
            visibility={fieldVisibility(formMeta.country)}
          />
          {!isEmpty(values.residentRegion) && (
            <DataColumn
              label={t(formMeta.residentRegion.label)}
              value={values.residentRegion}
              visibility={fieldVisibility(formMeta.residentRegion)}
            />
          )}
          <DataColumn
            label={t(formMeta.residentCity.label)}
            value={values.residentCity}
            visibility={fieldVisibility(formMeta.residentCity)}
          />
          {!isEmpty(values.visaType) && (
            <DataColumn
              label={t(formMeta.visaStatus.label)}
              value={values.visaType}
              visibility={fieldVisibility(formMeta.visaStatus)}
            />
          )}
        </Grid>

        <Typography
          className={`post-data-section-title typo-h5-${i18n.language} font-weight-500 capitalized-text`}
        >
          {t("post.educationAndProfession")}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className="data-column-container"
        >
          <DataColumn
            label={t(formMeta.education.label)}
            value={values.educationLevel}
            visibility={fieldVisibility(formMeta.education)}
          />
          <DataColumn
            label={t(formMeta.profession.label)}
            value={values.profession}
            visibility={fieldVisibility(formMeta.profession)}
          />
        </Grid>

        <Typography
          className={`post-data-section-title typo-h5-${i18n.language} font-weight-500 capitalized-text`}
        >
          {t("post.habits")}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className="data-column-container"
        >
          <DataColumn
            label={t(formMeta.drinking.label)}
            value={values.drinking}
            visibility={fieldVisibility(formMeta.drinking)}
          />
          <DataColumn
            label={t(formMeta.smoking.label)}
            value={values.smoking}
            visibility={fieldVisibility(formMeta.smoking)}
          />
          <DataColumn
            label={t(formMeta.foodPreference.label)}
            value={values.foodPreference}
            visibility={fieldVisibility(formMeta.foodPreference)}
          />
        </Grid>
      </DataSection>
    )
  );
};

export default PersonalInformation;
