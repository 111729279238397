import React, { useMemo, useState } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import WarningRounded from "@material-ui/icons/WarningRounded";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import { isEqual } from "lodash";

import { SectionHeading } from "../custom-typography";
import Notice from "../../../../../components/molecules/notice";
import FormMeta from "../../../../../meta/edit-self-profile.json";
import {
  fieldVisibility,
  isEmptyOrNullOrUndefined,
} from "../../../../../utils";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import FormControl from "../../../../../components/molecules/form-control";
import FormImageSelector from "../../../../../components/molecules/form-image-selector";
import {
  createEditRequest,
  handleFieldOnBlur,
  REVIEW_FORM_FIELDS,
} from "../../../../../features/edit-ad";
import ImageRestrictionNotice from "../../../../create-flow/private-create-account/templates/image-restriction-notice";
import AlertDialogBox from "../../../../../components/molecules/dialog-box";

const InformationWithReview = ({ contentLoading }) => {
  const [showImagesRestrictionModal, setShowImagesRestrictionModal] =
    useState(false);
  const dispatch = useDispatch();
  const { profileImages, horoscopeImages, advertisement } = useSelector(
    (state) => state.editAdPage
  );
  const { t, i18n } = useTranslation();
  const {
    values,
    errors,
    getFieldMeta,
    handleChange,
    setFieldError,
    setFieldValue,
  } = useFormikContext();

  const canUpdateNameFields = useMemo(() => {
    if (isEmptyOrNullOrUndefined(advertisement.firstPublishedAt)) {
      return false;
    }
    const idVerified = advertisement.idVerified ?? false;
    const firstPublishedAt = moment(advertisement.firstPublishedAt);
    const latestUpdatableDate = firstPublishedAt.add(3, "days");
    const todayDate = moment();

    return todayDate.isBefore(latestUpdatableDate) && !idVerified;
  }, [advertisement]);

  const hasPendingEditRequsts = useMemo(() => {
    return !isEmptyOrNullOrUndefined(advertisement.pendingEditRequests);
  }, [advertisement]);

  const hasChangedFormFields = useMemo(() => {
    for (const current of REVIEW_FORM_FIELDS) {
      const fieldMeta = getFieldMeta(current.fieldName);
      if (!isEmptyOrNullOrUndefined(fieldMeta)) {
        const { initialValue, value } = fieldMeta;
        if (!isEqual(value, initialValue)) {
          return true;
        }
      }
    }

    return false;
  }, [getFieldMeta]);

  const onSubmitReview = () => {
    dispatch(createEditRequest({ getFieldMeta, t }));
  };

  const onClickCancel = () => {
    for (const current of REVIEW_FORM_FIELDS) {
      const fieldMeta = getFieldMeta(current.fieldName);
      if (!isEmptyOrNullOrUndefined(fieldMeta)) {
        const { initialValue } = fieldMeta;
        setFieldValue(current.fieldName, initialValue);
      }
    }
  };

  if (contentLoading) {
    return (
      <Paper variant="outlined" className="form-section-paper">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="full-height-container"
        >
          <CircularProgress className="branded-circullar-progress" />
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" className="form-section-paper">
      <SectionHeading labelKey="common.reviewAndUpdate" />
      {!hasPendingEditRequsts && (
        <Notice
          label={t("common.reviewAndUpdateSectionNotice")}
          lang={i18n.language}
        />
      )}
      <Grid container direction="column" className="row-gap-16 margin-top-8">
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.firstNameReview.fieldName}
          value={values[FormMeta.firstNameReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.firstNameReview.label)}
          lang={i18n.language}
          errorText={errors[FormMeta.firstNameReview.fieldName]}
          visibility={
            fieldVisibility(FormMeta.firstNameReview) && canUpdateNameFields
          }
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.lastNameReview.fieldName}
          value={values[FormMeta.lastNameReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.lastNameReview.label)}
          lang={i18n.language}
          errorText={errors[FormMeta.lastNameReview.fieldName]}
          visibility={
            fieldVisibility(FormMeta.lastNameReview) && canUpdateNameFields
          }
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.subCommunityReview.fieldName}
          value={values[FormMeta.subCommunityReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.subCommunityReview.label)}
          lang={i18n.language}
          errorText={errors[FormMeta.subCommunityReview.fieldName]}
          visibility={fieldVisibility(FormMeta.subCommunityReview)}
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.casteReview.fieldName}
          value={values[FormMeta.casteReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.casteReview.label)}
          lang={i18n.language}
          visibility={fieldVisibility(FormMeta.casteReview)}
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          fieldName={FormMeta.adDescriptionReview.fieldName}
          value={values[FormMeta.adDescriptionReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => dispatch(handleFieldOnBlur(e, setFieldError, t))}
          label={t(FormMeta.adDescriptionReview.label)}
          placeholder={t(FormMeta.adDescriptionReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.adDescriptionReview.fieldName]}
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          fieldName={FormMeta.fAdditionalInfoReview.fieldName}
          value={values[FormMeta.fAdditionalInfoReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => dispatch(handleFieldOnBlur(e, setFieldError, t))}
          label={t(FormMeta.fAdditionalInfoReview.label, {
            parent: t("common.father"),
          })}
          placeholder={t(FormMeta.fAdditionalInfoReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.fAdditionalInfoReview.fieldName]}
          disabled={hasPendingEditRequsts}
        />
        <FormTextInput
          fieldName={FormMeta.mAdditionalInfoReview.fieldName}
          value={values[FormMeta.mAdditionalInfoReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => dispatch(handleFieldOnBlur(e, setFieldError, t))}
          label={t(FormMeta.mAdditionalInfoReview.label, {
            parent: t("common.mother"),
          })}
          placeholder={t(FormMeta.mAdditionalInfoReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.mAdditionalInfoReview.fieldName]}
          disabled={hasPendingEditRequsts}
        />
      </Grid>
      <FormControl
        label={t("common.yourPhotoes")}
        classes={{ rootContainer: "photo-form-control" }}
      >
        <Grid
          container
          row
          style={{ justifyContent: "flex-start", columnGap: 8 }}
          className="row-gap-8"
        >
          {profileImages.map((src, idx) => (
            <Grid item style={{ width: "30%" }} key={`profile-img-slot-${idx}`}>
              <FormImageSelector image={src} disabled={hasPendingEditRequsts} />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <Grid
        container
        direction="row"
        className="clickable-instruction-container"
        onClick={() => setShowImagesRestrictionModal(true)}
      >
        <WarningRounded className="clickable-instruction" />
        <Typography
          className={`clickable-instruction clickable-instruction-${i18n.language}`}
        >
          {t("createflow.pictureRestrictionNotice")}
        </Typography>
      </Grid>
      <FormControl
        label={t("common.horoscopeDetails")}
        classes={{ rootContainer: "photo-form-control" }}
      >
        <Grid
          container
          row
          style={{ justifyContent: "flex-start", columnGap: 8 }}
        >
          {horoscopeImages.map((src, idx) => (
            <Grid item style={{ width: "30%" }} key={`profile-img-slot-${idx}`}>
              <FormImageSelector image={src} disabled={hasPendingEditRequsts} />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormTextInput
        fieldName={FormMeta.horoscopeDetailReview.fieldName}
        value={values[FormMeta.horoscopeDetailReview.fieldName]}
        onChange={handleChange}
        onBlur={(e) => dispatch(handleFieldOnBlur(e, setFieldError, t))}
        label={t(FormMeta.horoscopeDetailReview.label)}
        placeholder={t(FormMeta.horoscopeDetailReview.placeholder)}
        lang={i18n.language}
        multiline
        errorText={errors[FormMeta.horoscopeDetailReview.fieldName]}
        disabled={hasPendingEditRequsts}
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        className="submit-action-container"
      >
        {hasPendingEditRequsts ? (
          <Typography variant="body2" className="review-in-progress-txt">
            {t("message.reviewInProgress")}
          </Typography>
        ) : (
          <>
            <Button
              variant="text"
              className="cancel-btn"
              disabled={!hasChangedFormFields}
              onClick={() => onClickCancel()}
            >
              {t("createflow.cancel")}
            </Button>
            <Button
              variant="contained"
              className="review-btn"
              disabled={!hasChangedFormFields}
              onClick={onSubmitReview}
            >
              {t("createflow.submitForReview")}
            </Button>
          </>
        )}
      </Grid>
      <AlertDialogBox
        showModal={showImagesRestrictionModal}
        onClose={() => setShowImagesRestrictionModal(false)}
        title={t("createflow.pictureModalNotice")}
      >
        <ImageRestrictionNotice />
      </AlertDialogBox>
      {/* <Grid></Grid> */}
    </Paper>
  );
};

export default InformationWithReview;
