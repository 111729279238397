import React, { useContext, useEffect } from "react";
import PageHead from "../../components/atoms/page-head";
import Navbar from "../../../components/common/Navbar";
import AppContext from "../../context";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Schema from "./schema";
import TermsSegment from "./terms-segment";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import Config from "../../config";

const TermsAndConditionsPageIn = () => {
  const { lng, handleLng } = useContext(AppContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView();
  }, []);
  return (
    <>
      <PageHead title={`Terms | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        className="cus-container privacy-policy-content"
        style={{ rowGap: 10, padding: 20 }}
      >
        <Grid>
          <Typography variant="h4" align="center" style={{ marginTop: 30 }}>
            {Schema.topic}
          </Typography>
          <Typography variant="body2" align="center">
            {`Last updated date: ${Schema.lastUpdatedOn}`}
          </Typography>
        </Grid>

        <Grid style={{ padding: isMobileScreen ? "0 20px 0 20px" : 0 }}>
          <ol className="list-title">
            {Schema.content.bullets.map((content, idx) => (
              <li className="list-item">
                <TermsSegment
                  key={`content-bullet-${idx}`}
                  title={content.heading}
                  alphaList={content.alphaList}
                  paragraphs={content.paragraphs}
                  bulletPoints={content.bulletPoints}
                  bottomParagraphs={content.bottomParagraphs}
                />
              </li>
            ))}
          </ol>
        </Grid>
      </Grid>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default TermsAndConditionsPageIn;
