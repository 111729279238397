import React, { Component } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewComponent from "../components/common/ReviewComponent";
import "../styles/reviews.css";
import Header from "../components/magazine/Header";
import MonthlyMagazines from "../components/magazine/MonthlyMagazines";
import "../styles/magazine.css";
import Config from "../v2/config";

class Magazine extends Component {
  state = {
    lng: true, // default en
    reviews: []
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });
    this.getReviews(0, 6);
    // upto top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  getReviews = (pageNum, pageSize) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/reviews?pageNum=${pageNum}&pageSize=${pageSize}`)
      .then((res) => {
        if (res.data) {
          this.setState({ reviews: res.data.results });
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      arrows: false,
      speed: 1000,
      infinite: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <React.Fragment>
        <PageHead title={`Magazines | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <Header lng={this.state.lng} />
        <div style={{ background: "#f7f7f7", padding: "0 10px" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
          >
            <MonthlyMagazines lng={this.state.lng} />
          </Grid>
          <div style={{paddingBottom: '30px'}}>
          {Config.REVIEWS_AND_RATINGS_VISIBILITY &&
              this.state.reviews.length > 0 && (
              <div className="magazine-reviews-container">
                <Grid container xs={12} sm={10} md={8} className="all-ads-container reviews-slider-grid">
                  <Grid item className="reviews-slider-sub-grid">
                    <Slider {...settings}>
                      {(this.state.reviews).map((review, i) => (
                        <ReviewComponent review={review} lang={this.state.lng} isRedirect={true}/>
                      ))}
                    </Slider>
                    <p
                      className="ignore-int-text-btn"
                      style={{textAlign: 'end'}}
                      onClick={() => this.props.history.push("/reviews")}
                    >
                      <span>
                        {this.state.lng ? (
                          "Load all reviews"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-13">
                            සියලු නිර්දේශ කිරීම් බලන්න
                          </span>
                        )}
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}

export default Magazine;
