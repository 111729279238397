import React, { Component } from "react";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";

export default class Personal extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "20vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <Typography variant="h6" className="heading">
                  {this.props.lng ? (
                    "Personal Info"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-16">
                      පෞද්ගලික තොරතුරු
                    </span>
                  )}
                </Typography>
                <div className="row">
                  <p className="review-sub-sec-heading">
                    {this.props.lng ? (
                      "Basic"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        මූලික
                      </span>
                    )}
                  </p>

                  <div className="review-col rev-col-left">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Ethnicity" : "ජාතිය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.ethnicity
                          : this.props.ethnicity_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Religion" : "ආගම"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.religion
                          : this.props.religion_si}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Age" : "වයස"}
                      </div>
                      <div className="con-col">
                        {this.props.lng ? null : "අවුරුදු"} {this.props.age}{" "}
                        {this.props.lng ? "years" : null}
                      </div>
                    </div>
                  </div>
                  <div className="review-col rev-col-right abnormal-col">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Caste" : "කුලය"}
                      </div>
                      <div className="con-col">
                        {this.props.caste ? this.props.caste : "-"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Civil Status" : "සිවිල් තත්වය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.status
                          : this.props.status_si}
                      </div>
                    </div>
                    {(this.props.status !== "Never Married" && this.props.children) &&
                      <div className="con-row">
                        <div className="con-col">
                          {this.props.lng ? "Has Children" : "දරුවන් ඇත"}
                        </div>
                        <div className="con-col">
                          {this.props.children === "YES" && (this.props.lng ? "Yes" : "ඔව්")}
                          {this.props.children === "NO" && (this.props.lng ? "No" : "නැත")}
                          {this.props.children === "UNDISCLOSED" && (this.props.lng ? "Prefer not to mention" : "සඳහන් කිරීමට අකමැතියි")}
                        </div>
                      </div>
                    }

                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Height" : "උස"}
                      </div>
                      <div className="con-col">
                        {Math.floor(Math.round(this.props.height / 2.54) / 12)}'{" "}
                        {Math.round(this.props.height / 2.54) % 12}"
                      </div>
                    </div>
                  </div>

                  <p className="review-sub-sec-heading">
                    {this.props.lng ? (
                      "Residency"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        පදිංචිය
                      </span>
                    )}
                  </p>

                  <div className="review-col rev-col-left">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Country" : "පදිංචි රට"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.country
                          : this.props.country_si}
                      </div>
                    </div>
                    {this.props.residentRegion ? (
                      <div className="con-row">
                        <div className="con-col">
                          {this.props.lng
                            ? "State / District"
                            : "රාජ්‍යය / දිස්ත්‍රික්කය"}
                        </div>
                        <div className="con-col">
                          {this.props.lng
                            ? this.props.residentRegion
                            : this.props.residentRegion_si}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="review-col rev-col-right">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "City" : "පදිංචි නගරය"}
                      </div>
                      <div className="con-col">{this.props.residentCity}</div>
                    </div>
                    {this.props.country === "Sri Lanka" ? null : (
                      <div className="con-row">
                        <div className="con-col">
                          {this.props.lng ? "Visa Status" : "වීසා තත්වය"}
                        </div>
                        <div className="con-col">
                          {this.props.lng
                            ? this.props.visaType
                            : this.props.visaType_si}
                        </div>
                      </div>
                    )}
                  </div>

                  <p className="review-sub-sec-heading">
                    {this.props.lng ? (
                      "Education & Profession"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        වෘත්තිය
                      </span>
                    )}
                  </p>

                  <div className="review-col rev-col-left">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Education" : "අධ්යාපනය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.eduLevel
                          : this.props.eduLevel_si}
                      </div>
                    </div>
                  </div>
                  <div className="review-col rev-col-right">
                    <div className="con-row mob-occu-row">
                      <div className="con-col">
                        {this.props.lng ? "Profession" : "වෘත්තිය"}
                      </div>
                      <div className="con-col">
                        {this.props.typedOccupation
                          ? this.props.typedOccupation
                          : this.props.lng
                          ? this.props.profession
                          : this.props.profession_si}
                      </div>
                    </div>
                  </div>

                  <p className="review-sub-sec-heading">
                    {this.props.lng ? (
                      "Habits"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        පුරුදු
                      </span>
                    )}
                  </p>

                  <div className="review-col rev-col-left">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Drinking" : "මත් පැන් භාවිතය"}
                      </div>
                      <div className="con-col">
                        {this.props.drinking === "YES"
                          ? this.props.lng
                            ? "Yes"
                            : "ඔව්"
                          : this.props.drinking === "NO"
                          ? this.props.lng
                            ? "No"
                            : "නැත"
                          : this.props.lng
                          ? "Occasionally"
                          : "ඇතැම් විට"}
                      </div>
                    </div>
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Smoking" : "දුම් පානය"}
                      </div>
                      <div className="con-col">
                        {this.props.smoking === "YES"
                          ? this.props.lng
                            ? "Yes"
                            : "ඔව්"
                          : this.props.lng
                          ? "No"
                          : "නැත"}
                      </div>
                    </div>
                  </div>
                  <div className="review-col rev-col-right">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Food Preference" : "ආහාර මනාපය"}
                      </div>
                      <div className="con-col">
                        {this.props.lng
                          ? this.props.foodPref
                          : this.props.foodPref_si}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
