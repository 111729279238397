import React, { useContext, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import FormSection from "../../../../../components/molecules/form-section";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import AppContext from "../../../../../context";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import { isEmptyOrNullOrUndefined } from "../../../../../utils";
import Config from "../../../../../config";

const excludedProfessionIds = [Config.HOUSE_WIFE_PROFESSION_ID];

const EducationProfession = ({ formMeta }) => {
  const { values, errors, handleChange, submitCount } = useFormikContext();
  const showFieldErrors = submitCount > 0;
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { educationLevels, professions } = useSelector(
    (state) => state.genericData
  );

  const professionOtherVisibility = useMemo(() => {
    let professionId = values.professionId;
    if (isEmptyOrNullOrUndefined(professionId)) {
      return false;
    }
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    return professionId === Config.OTHER_PROFESSION_ID;
  }, [values]);

  const filteredProfessions = useMemo(() => {
    return professions.filter(
      (element) => !excludedProfessionIds.includes(element.id)
    );
  }, [professions]);

  return (
    <FormSection heading={t("common.educationAndProfession")}>
      <Grid container direction="row" className="row-gap-16">
        <Grid item xs={12} md={6}>
          <FormDropdown
            fieldName={formMeta.educationLevel.fieldName}
            value={values.educationLevelId}
            onChange={handleChange}
            options={educationLevels}
            keyExtractor={formMeta.educationLevel.keyExtractor}
            labelExtractor={formMeta.educationLevel.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.educationLevel.label)}
            errorText={showFieldErrors && errors.educationLevelId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          className="row-gap-16"
        >
          <FormDropdown
            fieldName={formMeta.profession.fieldName}
            value={values.professionId}
            onChange={handleChange}
            options={filteredProfessions}
            keyExtractor={formMeta.profession.keyExtractor}
            labelExtractor={formMeta.profession.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.profession.label)}
            errorText={showFieldErrors && errors.professionId}
          />
          <FormTextInput
            fieldName={formMeta.otherProfession.fieldName}
            value={values.otherProfession}
            onChange={handleChange}
            label={t(formMeta.otherProfession.label)}
            lang={appLanguage}
            visibility={professionOtherVisibility}
            errorText={showFieldErrors && errors.otherProfession}
            maxCharactorCount={30}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default EducationProfession;
