import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { Grid, Button } from "@material-ui/core";

import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

class EmailActivation extends Component {
  state = {
    lng: true, // default en

    success: false,
    fail: false,
    refresh: false,
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    if (queryString.parse(this.props.location.search).success === "true") {
      this.setState({ success: true });

      // post details
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}`;
      } else {
        url = "account/posts";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            Authorization: `Bearer ${
              queryString.parse(this.props.location.search).t
            }`,
          },
        })
        .then((res) => {
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            res.data[0] = res.data;
          }
          if (res.data.length > 0) {
            localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            localStorage.setItem("postId", res.data[0].id);
          }
          localStorage.setItem(
            "auth_token",
            queryString.parse(this.props.location.search).t
          );

          // user account
          let url = "";
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            url = `operator/managed_posts/${localStorage.getItem("postId")}/owner`;
          } else {
            url = "account";
          }
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
              headers: {
                Authorization: `Bearer ${
                  queryString.parse(this.props.location.search).t
                }`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name ?? "");
              localStorage.setItem("user_email", res.data.email ?? "");

              this.setState({ refresh: true });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ fail: true });
    }
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });
    localStorage.setItem("lng", !this.state.lng);
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Email Activation | ${Config.DOMAIN_DISPLAY_NAME}`} />

        {this.state.refresh ? (
          <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        ) : (
          <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        )}

        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          {this.state.success ? (
            <div className="success-error-div">
              <p align="center">
                <i className="far fa-check-circle fa-4x" align="center"></i>
              </p>
              <p className="message">
                {this.state.lng ? "Successful!" : "සාර්ථකයී!"}
              </p>
              <p align="center">
                {this.state.lng
                  ? "Your email has been successfully activated."
                  : "ඔබගේ විද්‍යුත් ලිපිනය සාර්ථකව සක්‍රිය කර ඇත."}{" "}
              </p>
              <Button
                variant="contained"
                className="all-ads-btn"
                onClick={() => this.props.history.push("/")}
              >
                {this.state.lng ? "Go To ALL Ads" : "සියලුම දැන්වීම් වෙත යන්න"}
              </Button>
            </div>
          ) : null}
          {this.state.fail ? (
            <div className="success-error-div">
              <p align="center">
                <i className="far fa-times-circle fa-4x" align="center"></i>
              </p>
              <p className="message">
                {" "}
                {this.state.lng ? "Error!" : "දෝෂයකි!"}{" "}
              </p>
              <p align="center">
                {this.state.lng
                  ? "Email could not be activated due to an invalid token."
                  : "අවලංගු කෙතයක් හේතුවෙන් විද්‍යුත් තැපෑල සක්‍රිය කළ නොහැක."}
              </p>
            </div>
          ) : null}
        </Grid>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(EmailActivation);
