import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import InterestPref from "../components/interest-preferences/InterestPref";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import Config from "../v2/config";

export default class PartnerPref extends Component {
  state = {
    lng: true, // default en
    isAccessDenied: false,
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
      isAccessDenied: localStorage.getItem("auth_token") ? false : true,
    });

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`Interest Preferences | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <PageHead title={`Interest Preferences | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div style={{ background: "#dfdfdf", paddingTop: 5 }}>
          <Typography
            variant="h3"
            align="center"
            className="create-new-ad"
            style={{ paddingBottom: 30, fontWeight: 500 }}
          >
            {this.state.lng === true ? (
              "Interest Preferences"
            ) : (
              <span>කැමැත්තන් පිළිගැනීමේ නිර්ණායක</span>
            )}
          </Typography>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className="cus-container complete-ad-container"
          >
            <InterestPref lng={this.state.lng} />
          </Grid>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}
