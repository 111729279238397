import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { isEmpty } from "lodash";
import schema from "./schema";
import FaqItem from "./faq-item";
import PageHead from "../../components/atoms/page-head";
import AppContext from "../../context";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import Config from "../../config";
import "./style.css";

const FaqPageIn = () => {
  const [selectedHelpOption, setSelectedHelpOption] = useState({});

  const { state } = useLocation();
  const { lng, handleLng } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!isEmpty(state)) {
      const value = state.optionType;
      switch (value) {
        case "account_creation":
          setSelectedHelpOption(schema.account_creation);
          break;
        case "matching_with_other":
          setSelectedHelpOption(schema.matching_with_other);
          break;
        case "settings":
          setSelectedHelpOption(schema.settings);
          break;
        default:
          setSelectedHelpOption(schema.account_creation);
      }
    } else {
      setSelectedHelpOption(schema.account_creation);
    }
  }, []);

  return (
    <>
      <PageHead title={`Help | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        className="cus-container privacy-policy-content"
        style={{ padding: 20 }}
      >
        <Grid>
          <Button
            className="back-btn-in-detail-pages"
            onClick={() => history.goBack()}
            style={{ justifyContent: "flex-start" }}
          >
            <i className="fas fa-angle-left"></i> &nbsp; Back
          </Button>
        </Grid>
        <Typography className="faq-page-heading">Help Center</Typography>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
          style={{ alignItems: "center" }}
        >
          <Typography
            onClick={() => history.goBack()}
            className="faq-page-breadcrumb-txt"
          >
            Help
          </Typography>
          <Typography className="faq-page-breadcrumb-txt cursor-default">
            {selectedHelpOption.heading || ""}
          </Typography>
        </Breadcrumbs>

        <Grid container direction="column">
          {!isEmpty(selectedHelpOption.questionList) &&
            selectedHelpOption.questionList.map((item, index) => (
              <Fragment key={index}>
                <Typography className="faq-page-question-list-title">
                  {item.title || ""}
                </Typography>
                {item.questions.map((question, index) => (
                  <FaqItem
                    key={index}
                    question={question.question}
                    answers={question.answers}
                  />
                ))}
              </Fragment>
            ))}
        </Grid>
      </Grid>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default FaqPageIn;
