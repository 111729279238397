import { isEmpty, sortBy, isEqual } from "lodash";
import FormMeta from "../../../../meta/edit-self-profile.json";
import { buildModelByFormMeta } from "../../../../utils";
import GenericDataAPI from "../../../../services/api/generic";
import Config from "../../../../config";
import {
  setEditAdCommunities,
  setEditAdPageCountryRegions,
  setEditAdPageOldShadow,
} from "../../../../store/actions/edit-ad-page";
import store from "../../../../../store";
import { getLocaleLabelExtractorByMeta } from "../../../../utils/data-generator";
import { ADDITIONAL_COMMUNITY_OPTIONS } from "../../../../static-data";

const bindEditFormByAuth = ({ advertisement }) => {
  const personalInfo = advertisement?.personalInfo ?? {};
  const parentInfo = advertisement?.parentInfo ?? {};
  const horoscopeInfo = advertisement?.horoscopeInfo ?? {};

  const model = buildModelByFormMeta(FormMeta);

  mapPersonalInfoToMetaModel({ personalInfo, model });
  mapParentInfoToMetaModel({ parentInfo, model });
  mapHoroscopeInfoToMetaModel({ horoscopeInfo, model });
  mapRootLevelAdFieldsToMetaModel({ advertisement, model });
  mapReviewSectionToMetaModel({ advertisement, model });

  return model;
};

const handleOnChangeForm =
  ({ values, initialValues, setFieldValue }) =>
  (dispatch) => {
    const { oldShadow } = store.getState().editAdPage;
    if (isEqual(values, oldShadow)) {
      return;
    }
    if (
      isChangedFieldValue({
        meta: FormMeta.residentCountry,
        currentValues: values,
        oldValues: oldShadow,
      }) ||
      isEmpty(oldShadow)
    ) {
      const residentCountry = values[FormMeta.residentCountry.fieldName];
      GenericDataAPI.getRegionsByCountryCode({
        countryCode: residentCountry,
      }).then((response) => {
        if (!response.success) {
          return; // TODO: Error Handling
        }
        dispatch(setEditAdPageCountryRegions(response.body));
      });
    }

    if (
      isChangedFieldValue({
        meta: FormMeta.religion,
        currentValues: values,
        oldValues: oldShadow,
      })
    ) {
      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        const religionId = values[FormMeta.religion.fieldName];
        if (
          !isEmpty(initialValues) &&
          religionId !== initialValues[FormMeta.religion.fieldName]
        ) {
          setFieldValue(FormMeta.communityId.fieldName, "");
        }

        if (!isEmpty(religionId)) {
          GenericDataAPI.getCommunitiesByReligionId({ religionId }).then(
            (response) => {
              if (!response.success) {
                return; //TODO: Error Handling
              }
              const communities = response.body;
              if (Array.isArray(communities)) {
                communities.push(ADDITIONAL_COMMUNITY_OPTIONS);
              }

              dispatch(setEditAdCommunities(response.body));
            }
          );
        }
      }
    }

    if (
      !isEmpty(oldShadow) &&
      isChangedFieldValue({
        meta: FormMeta.fReligionId,
        currentValues: values,
        oldValues: oldShadow,
      })
    ) {
      setFieldValue(FormMeta.fCommunityId.fieldName, "");
    }

    if (
      !isEmpty(oldShadow) &&
      isChangedFieldValue({
        meta: FormMeta.mReligionId,
        currentValues: values,
        oldValues: oldShadow,
      })
    ) {
      setFieldValue(FormMeta.mCommunityId.fieldName, "");
    }

    dispatch(setEditAdPageOldShadow(values));
  };

export const isChangedFieldValue = ({ meta, currentValues, oldValues }) => {
  if (isEmpty(oldValues)) {
    return false;
  }
  const fieldName = meta.fieldName;
  const currentValue = currentValues[fieldName];
  const oldValue = oldValues[fieldName];
  return !isEqual(currentValue, oldValue);
};

const mapPersonalInfoToMetaModel = ({ model, personalInfo }) => {
  model[FormMeta.firstName.fieldName] =
    personalInfo[FormMeta.firstName.fieldName] ?? "";
  model[FormMeta.lastName.fieldName] =
    personalInfo[FormMeta.lastName.fieldName] ?? "";
  model[FormMeta.displayNameFormat.fieldName] =
    personalInfo.displayNameFormat ?? "";
  model[FormMeta.adDescription.fieldName] = personalInfo.additionalInfo ?? "";
  model[FormMeta.dob.fieldName] = personalInfo.dob;
  model[FormMeta.religion.fieldName] = personalInfo.religion?.id ?? "";
  model[FormMeta.gender.fieldName] = personalInfo.gender ?? "";
  model[FormMeta.civilStatus.fieldName] = personalInfo.civilStatus?.id ?? "";
  model[FormMeta.height.fieldName] = personalInfo.height;
  model[FormMeta.children.fieldName] = personalInfo.children ?? "";
  model[FormMeta.residentCountry.fieldName] =
    personalInfo.residentCountry?.code ?? "";
  model[FormMeta.residentCountryOther.fieldName] =
    personalInfo.residentCountryOther?.code ?? "";
  model[FormMeta.residentRegion.fieldName] =
    personalInfo.residentRegion?.id ?? "";
  model[FormMeta.residentCity.fieldName] = personalInfo.residentCity ?? "";
  model[FormMeta.visaType.fieldName] = personalInfo.visaType?.id ?? "";
  model[FormMeta.educationLevel.fieldName] =
    personalInfo.educationLevel?.id ?? "";
  model[FormMeta.profession.fieldName] = personalInfo.profession?.id ?? "";
  model[FormMeta.otherProfession.fieldName] =
    personalInfo.professionOther ?? "";
  model[FormMeta.drinking.fieldName] = personalInfo.drinking ?? "";
  model[FormMeta.smoking.fieldName] = personalInfo.smoking ?? "";
  model[FormMeta.foodPreference.fieldName] =
    personalInfo.foodPreference?.id ?? "";
  model[FormMeta.originCountryCode.fieldName] =
    personalInfo.originCountry?.code ?? "";

  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
    model[FormMeta.caste.fieldName] = personalInfo.caste ?? "";
  }

  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    model[FormMeta.communityId.fieldName] = personalInfo.community?.id ?? "";
    model[FormMeta.subCommunity.fieldName] =
      personalInfo[FormMeta.subCommunity.fieldName];
    model[FormMeta.gothraId.fieldName] = personalInfo.gothra?.id ?? "";
    model[FormMeta.motherTongueId.fieldName] =
      personalInfo.motherTongue?.id ?? "";
    model[FormMeta.starId.fieldName] = personalInfo.star?.id ?? "";
    model[FormMeta.starSignId.fieldName] = personalInfo.starSign?.id ?? "";
  }
};

const mapParentInfoToMetaModel = ({ parentInfo, model }) => {
  if (!Array.isArray(parentInfo)) {
    return; // throw an error
  }
  const ParentTypes = Config.PARENT_TYPE;
  for (const parentType of Object.values(ParentTypes)) {
    const parentObj = parentInfo.find((obj) => obj.type === parentType);

    if (isEmpty(parentInfo)) continue;
    switch (parentType) {
      case ParentTypes.FATHER:
        model[FormMeta.fReligionId.fieldName] = parentObj.religion?.id ?? "";
        model[FormMeta.fResidentCountryCode.fieldName] =
          parentObj.residentCountry?.code ?? "";
        model[FormMeta.fProfessionId.fieldName] =
          parentObj.profession?.id ?? "";
        model[FormMeta.fOtherProfession.fieldName] =
          parentObj.professionOther ?? "";
        model[FormMeta.fAdditionalInfo.fieldName] =
          parentObj.additionalInfo ?? "";

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
          model[FormMeta.fEthnicityId.fieldName] =
            parentObj.ethnicity?.id ?? "";
          model[FormMeta.fCaste.fieldName] = parentObj.caste ?? "";
        }

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
          model[FormMeta.fCommunityId.fieldName] =
            parentObj.community?.id ?? "";
          model[FormMeta.fSubCommunity.fieldName] =
            parentObj.subCommunity ?? "";
        }
        break;
      case ParentTypes.MOTHER:
        model[FormMeta.mReligionId.fieldName] = parentObj.religion?.id ?? "";
        model[FormMeta.mResidentCountryCode.fieldName] =
          parentObj.residentCountry?.code ?? "";
        model[FormMeta.mProfessionId.fieldName] =
          parentObj.profession?.id ?? "";
        model[FormMeta.mOtherProfession.fieldName] =
          parentObj.professionOther ?? "";
        model[FormMeta.mAdditionalInfo.fieldName] =
          parentObj.additionalInfo ?? "";

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
          model[FormMeta.mEthnicityId.fieldName] =
            parentObj.ethnicity?.id ?? "";
          model[FormMeta.mCaste.fieldName] = parentObj.caste ?? "";
        }

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
          model[FormMeta.mCommunityId.fieldName] =
            parentObj.community?.id ?? "";
          model[FormMeta.mSubCommunity.fieldName] =
            parentObj.subCommunity ?? "";
        }
        break;
      default:
        break; // throw an error if parentType invalid
    }
  }
};

const mapHoroscopeInfoToMetaModel = ({ horoscopeInfo, model }) => {
  model[FormMeta.birthCity.fieldName] = horoscopeInfo.birthCity ?? "";
  model[FormMeta.birthTime.fieldName] = horoscopeInfo.birthTime ?? "";
  model[FormMeta.horoscopeDetail.fieldName] =
    horoscopeInfo.additionalData ?? "";
};

const mapRootLevelAdFieldsToMetaModel = ({ advertisement, model }) => {
  // Field: Diffterently Abled
  model[FormMeta.differentlyAbled.fieldName] =
    advertisement[FormMeta.differentlyAbled.fieldName] ??
    FormMeta.differentlyAbled.defaultValue;
  model[FormMeta.horoscopeMatching.fieldName] =
    advertisement.horoscopeMatching ?? FormMeta.horoscopeMatching.defaultValue;

  // Myvivah.app Fields
  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    // Field: Diffterently Abled
    model[FormMeta.acceptOtherCaste.fieldName] =
      advertisement[FormMeta.acceptOtherCaste.fieldName] ??
      FormMeta.acceptOtherCaste.defaultValue;
    // Field: Accept Other Caste
    model[FormMeta.haveDosh.fieldName] =
      advertisement[FormMeta.haveDosh.fieldName] ??
      FormMeta.haveDosh.defaultValue;
  }
};

const mapReviewSectionToMetaModel = ({ advertisement, model }) => {
  // pending Edit Reqeust
  const pendingEditRequest =
    !isEmpty(advertisement.pendingEditRequests) &&
    Array.isArray(advertisement.pendingEditRequests)
      ? advertisement.pendingEditRequests[0]
      : {};

  // Review: First Name
  model[FormMeta.firstNameReview.fieldName] =
    pendingEditRequest.firstName ?? model[FormMeta.firstName.fieldName];

  // Review: Last Name
  model[FormMeta.lastNameReview.fieldName] =
    pendingEditRequest.lastName ?? model[FormMeta.lastName.fieldName];

  // Review: Self Ad Description
  model[FormMeta.adDescriptionReview.fieldName] =
    pendingEditRequest[FormMeta.adDescriptionReview.jsonKey] ??
    model[FormMeta.adDescription.fieldName];

  // Review: Horoscope Additional Info
  model[FormMeta.horoscopeDetailReview.fieldName] =
    pendingEditRequest[FormMeta.horoscopeDetailReview.jsonKey] ??
    model[FormMeta.horoscopeDetail.fieldName];

  // Poruwa.lk Fields
  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
    // Review: Caste
    model[FormMeta.casteReview.fieldName] =
      pendingEditRequest[FormMeta.casteReview.jsonKey] ??
      model[FormMeta.caste.fieldName];
  }

  // Myvivah.app Fields
  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    // Review: Sub Community
    model[FormMeta.subCommunityReview.fieldName] =
      pendingEditRequest[FormMeta.subCommunityReview.jsonKey] ??
      model[FormMeta.subCommunity.fieldName];
  }

  // Review: Father Additional Info
  model[FormMeta.fAdditionalInfoReview.fieldName] =
    model[FormMeta.fAdditionalInfo.fieldName];
  // Review: Mother Additional Info
  model[FormMeta.mAdditionalInfoReview.fieldName] =
    model[FormMeta.mAdditionalInfo.fieldName];

  const parentEditRequests = pendingEditRequest.parentDescriptions ?? [];
  const tempMapper = {};
  const parents = advertisement.parentInfo ?? [];
  for (const current of parents) {
    tempMapper[current.id] = current;
  }

  for (const current of parentEditRequests) {
    const parentId = current.id;
    const parent = tempMapper[parentId];
    switch (parent.type) {
      case Config.PARENT_TYPE.FATHER:
        model[FormMeta.fAdditionalInfoReview.fieldName] = current.description;
        break;
      case Config.PARENT_TYPE.MOTHER:
        model[FormMeta.mAdditionalInfoReview.fieldName] = current.description;
        break;
      default:
        break;
    }
  }
};

export const onChangeLangauge =
  ({ language }) =>
  (dispatch) => {
    const { countryRegions } = store.getState().editAdPage;
    // sort country regions
    const localeLabelKey = getLocaleLabelExtractorByMeta({
      fieldMeta: FormMeta.residentRegion,
      language,
    });
    const sortedRegions = sortBy(countryRegions, [localeLabelKey]);
    dispatch(setEditAdPageCountryRegions(sortedRegions));
  };

export { bindEditFormByAuth, handleOnChangeForm };
