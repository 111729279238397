import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import Config from "../../v2/config";

import fb from "../../images/fb.png";
import instagram from "../../images/instagram.png";
import messenger from "../../images/fb-messenger.png";
import whatsapp from "../../images/whatsapp.png";

import "../../styles/footer.css";
import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";

export default class Navbar extends Component {
  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  render() {
    return (
      <div className="footer-sec">
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          className="cus-container"
          style={{ margin: "auto" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <picture>
              <source srcSet={logo} type="image/webp" />
              <source srcSet={logoPng} type="image/png" />
              <img src={logo} className="footer-logo" alt="poruwa logo" />
            </picture> */}
            <ProductLogo variant={LogoVariant.white} size={LogoSize.large} />
            <Typography className="connect-with-us">
              {this.props.lng === true ? (
                "Connect with us"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  අප හා සම්බන්ධ වන්න
                </span>
              )}
            </Typography>
            <div className="social-div">
              <a
                href={Config.FACEBOOK_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={fb}
                  width="30"
                  className="facebook-icon"
                  alt="fb link"
                />
              </a>
              <a
                href={Config.INSTAGRAM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  width="30"
                  className="instagram-icon"
                  alt="fb link"
                />
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Link to="/privacy" className="link">
              {this.props.lng === true ? (
                "Privacy Policy"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  රහස්‍යතා ප්‍රතිපත්තිය
                </span>
              )}
            </Link>
            <Link to="/terms" className="link">
              {this.props.lng === true ? (
                "Terms of Service"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  සේවා කොන්දේසි
                </span>
              )}
            </Link>
            <Link to="/pricing" className="link" onClick={this.scrollToTop}>
              {this.props.lng === true ? (
                "Pricing"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">මිල ගනන්</span>
              )}
            </Link>
            {Config.MAGAZINE_VISIBILITY && (
              <Link to="/magazine" className="link" onClick={this.scrollToTop}>
                {this.props.lng === true ? (
                  "Monthly Magazine"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    මාසික සඟරාව
                  </span>
                )}
              </Link>
            )}

            {Config.BLOG_PAGE_VISIBILITY && (
              <a className="link" href="/blog">
                {this.props.lng === true ? (
                  "Blog"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    බ්ලොග් පිටුව
                  </span>
                )}
              </a>
            )}

            <a href="/help" className="link">
              {this.props.lng === true ? (
                "Help"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  උපදෙස් සහ සහාය
                </span>
              )}
            </a>
          </Grid>
          <Grid item xs={12} sm={12} lg={5}>
            <Typography className="about-us">
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN ? (
                `${Config.DOMAIN_DISPLAY_NAME} is the best privacy focused matrimony service for Indians living in India and overseas. This is an SSL secured website built in accordance with global standards and with utmost priority for privacy and data security.`
              ) : this.props.lng === true ? (
                `${Config.DOMAIN_DISPLAY_NAME} is the most trusted matrimony website made by Sri Lankans for Sri Lankans living in Sri Lanka and overseas. This is an SSL secured website built in accordance with global standards and with utmost priority for privacy and data security.`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  {Config.DOMAIN_DISPLAY_NAME} යනු ශ්‍රී ලංකාවේ සහ විදේශයන්හි
                  වෙසෙන ශ්‍රී ලාංකිකයන් සඳහා ශ්‍රී ලාංකිකයන් විසින් නිර්මාණය කරන
                  ලද වඩාත්ම විශ්වාසදායක මංගල යෝජනා වෙබ් අඩවියයි. මෙම වෙබ් අඩවිය
                  ජාත්‍යාන්තර ප්‍රමිතීන්ට අනුකූලව SSL ආරක්ෂිත ආරක්ෂිතව ගොඩ නගා
                  අති අතර පුද්ගලිකත්වය සහ දත්ත සුරක්‍ෂිතතාවයට අතිශයින්ම
                  ප්‍රමුඛතාවය ලබා දී ඇත.
                </span>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={2} className="border-top">
            <Typography className="heading">
              <i className="far fa-comments"></i> &nbsp;
              {this.props.lng === true ? (
                "Live Chat"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  වෙනත් සම්බන්ධිත නාලිකා
                </span>
              )}
            </Typography>

            <p className="non-link">
              <a
                href={`${Config.MESSENGER_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={messenger}
                  alt="messsenger"
                  width="18"
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                &nbsp; Messenger
              </a>
            </p>
            <p className="non-link">
              <a
                href={`${Config.WHATSAPP_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapp}
                  alt="whatsapp"
                  width="18"
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                &nbsp;{" "}
                {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
                  ? "Whatsapp"
                  : Config.PHONE_NUMBER_MOBILE}
              </a>
            </p>
          </Grid>

          <Grid item xs={12}>
            <p
              align="center"
              style={{ fontSize: 13, color: "#fff", marginTop: 50 }}
            >
              &copy; {this.getYear()} {Config.DOMAIN_DISPLAY_NAME} Powered by{" "}
              <a
                style={{ fontSize: 13, color: "#fff", textDecoration: "none" }}
                target="_blank"
                href="https://www.serendib.io/"
              >
                Serendib Systems
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}
