import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import AccountCreation from "../components/faq/AccountCreation";
import Matching from "../components/faq/Matching";
import SettingsFAQ from "../components/faq/SettingsFAQ";
import "../styles/new-faq.css";
import Config from "../v2/config";

export default class FAQ extends Component {
  state = {
    lng: true, // default en
  };

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });
    localStorage.setItem("lng", !this.state.lng);
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Help | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div className="new-faq-wrapper">
          <div className="new-faq-div">
            <div className="new-faq-col-main">
              <Typography
                align="center"
                className="new-faq-page-heading"
              >
                {this.state.lng === true ? (
                  "Help Center"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-17">
                    උපදෙස් සහ සහාය
                  </span>
                )}
              </Typography>
              <div className="new-faq-mobile">
                <div className="new-faq-heading">
                  <div style={{marginBottom:"5px"}}>
                    {this.state.lng === true ? (
                      <>
                        Help &gt;{" "}
                        <strong>
                          {this.props.location.state.Account && (
                            <>Account Creation</>
                          )}
                          {this.props.location.state.Matching && (
                            <>Matching with other ads</>
                          )}
                          {this.props.location.state.Settings && <>Settings</>}
                        </strong>
                      </>
                    ) : (
                      <span className="sinhala-w-200 sinhala-size-13">
                        උපදෙස් සහ සහාය &gt;{" "}
                        <strong className="sinhala-w-400">
                          {this.props.location.state.Account && (
                            <>ගිණුමක් සෑදීම</>
                          )}
                          {this.props.location.state.Matching && (
                            <>අනෙකුත් දැන්වීම් සමග සම්බන්ද වීම</>
                          )}
                          {this.props.location.state.Settings && <>සැකසුම්</>}
                        </strong>
                      </span>
                    )}
                  </div>
                  <Button
                    className="back-btn-in-detail-pages"
                    onClick={() => this.props.history.goBack()}
                    style={{margin:"10px 0"}}
                    >
                    <i className="fas fa-angle-left"></i> &nbsp;
                    {this.state.lng ? "Back" : "ආපසු යන්න"}
                  </Button>
                  <div className="new-faq-heading-title">
                    {this.state.lng === true ? (
                      <>
                        {this.props.location.state.Account && (
                          <>Account Creation</>
                        )}
                        {this.props.location.state.Matching && (
                          <>Matching with other ads</>
                        )}
                        {this.props.location.state.Settings && <>Settings</>}
                      </>
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-17">
                        {this.props.location.state.Account && (
                          <>ගිණුමක් සෑදීම</>
                        )}
                        {this.props.location.state.Matching && (
                          <>අනෙකුත් දැන්වීම් සමග සම්බන්ද වීම</>
                        )}
                        {this.props.location.state.Settings && <>සැකසුම්</>}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.location.state.Account && (
            <AccountCreation lng={this.state.lng} />
          )}
          {this.props.location.state.Matching && (
            <Matching lng={this.state.lng} />
          )}
          {this.props.location.state.Settings && (
            <SettingsFAQ lng={this.state.lng} />
          )}
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.state.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.state.lng} />
        </div>
      </React.Fragment>
    );
  }
}
