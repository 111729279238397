import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

export default class Terms extends Component {
  state = {
    lng: true, // default en
  };

  componentDidMount = () => {
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };
  render() {
    return (
      <React.Fragment>
        <PageHead title={`Terms | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
          >
            <Typography
              variant="h4"
              align="center"
              className="privacy-page-heading"
            >
              Terms & Conditions
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ margin: "10px 0 50px " }}
            >
              Last updated date: 03 May 2021
            </Typography>
            <div className="terms-section">
              <div>
                <ol>
                  <li>
                    <h2>Agreement</h2>
                    <p>
                      These Terms and Conditions include Our Privacy Policy.
                      They may be amended from time to time. They will govern
                      your <b>[“You”]</b> access and use of the website
                      Poruwa.lk
                      <b>[“Site”/“Poruwa.lk]</b> and its related services. By
                      accessing and using Poruwa.lk and its related services You
                      agree to be legally bound by these Terms and Conditions.
                    </p>
                  </li>

                  <li>
                    <h2>Services</h2>
                    <p>
                      <b>Online:</b> Poruwa.lk provides online advertising space
                      and related services which You receive for a nominal fee
                      , to advertise
                      personal information and data either of your own or of a
                      consenting third party <b>[“Third Party”]</b> for the
                      purpose of seeking a matching prospective matrimonial
                      partner
                      <b>[“Matrimonial Advertisement”]</b>, and also use the
                      related services for such purpose
                    </p>
                    <p>
                      <b>Offline:</b> Publication and distribution across Sri
                      Lanka in a monthly /bi-weekly Magazine of basic
                      information derived from your Matrimonial Advertisement
                      (if selected) but unrevealing of personal identity. This
                      is to reach a wider group of potential matrimonial matches
                      for advertisements on the Site.
                    </p>
                  </li>

                  <li>
                    <h2>About Us</h2>
                    <p>
                      The website Poruwa.lk is owned by Serendib Systems Pty Ltd
                      which together with its affiliate partner, Serendib
                      Systems Lanka (Pvt) Ltd, <b>[“We”, “Us”, or “Our”]</b> and
                      third party licensors provide the Services.
                    </p>
                    <p>
                      Serendib Systems Pty Ltd is a company duly incorporated in
                      Australia (ACN 637232038) and having its registered office
                      at 2/15, McEwan Road, Heidelberg Heights, VIC3081,
                      Australia.
                    </p>
                    <p>
                      The Magazine is owned, published and distributed by
                      Serendib Systems Lanka (Pvt) Ltd, a Company duly
                      incorporated in Sri Lanka (Company Registration No
                      PV129221 and having its registered office at Poruwa.lk, No.356,
                      Denzil Kobbekaduwa Road, Battaramulla, Sri Lanka.
                    </p>
                  </li>

                  <li>
                    <h2>Eligibility Criteria</h2>
                    <p>
                      To access and use Poruwa.lk, You and any third party on
                      whose behalf You may be accessing and using the Site:
                    </p>
                    <ol className="alpha">
                      <li>Must not be less than 18 years of age;</li>
                      <li>
                        Must not be a minor under the laws and regulations
                        applicable to You (and to any Third Party); and
                      </li>
                      <li>
                        Must be legally competent to marry under the laws and
                        regulations applicable to You (and to any Third Party).
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Advertizing Content</h2>
                    <p>
                      You will provide the content of the Matrimonial
                      Advertisement which will comprise personal information and
                      data in text and images. <b>[“Content”]</b> and which You
                      will upload for inclusion on Poruwa.lk. We reserve the
                      right to edit or if necessary delete at any time any part
                      of the Content, at our sole discretion and without warning
                      or notice to You, in order to safeguard and preserve the
                      integrity and ethical profile of Poruwa.lk.
                    </p>
                    <p>
                      All images uploaded and made available on Poruwa.lk, bear
                      a watermark. This is to provide for easy identification
                      and dissuade the images being used for purposes other than
                      those to which You, as an advertiser, have consented
                    </p>
                  </li>

                  <li>
                    <h2>Conditions Governing use of the Site</h2>
                    <ol className="alpha">
                      <li>
                        You are permitted to access and use Poruwa.lk only for
                        the authorized purposes set out in Clause 2.
                      </li>
                      <li>
                        You are solely responsible for ensuring that the Content
                        provided and uploaded by You for inclusion on the Site
                        complies with all applicable laws and regulations and
                        that they do not violate any contractual rights with a
                        third party or any privacy or data protection rights, as
                        applicable.
                      </li>
                      <li>
                        You will promptly update and correct any inaccuracies in
                        the Content uploaded or provided by You and such changes
                        will be verified and changed by Poruwa.lk.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Representations & Warranties</h2>
                    <p>
                      By accessing and using Poruwa.lk, You represent and
                      warrant that:
                    </p>
                    <ol className="alpha">
                      <li>
                        You, and any third party on whose behalf You are using
                        the Site, satisfy the eligibility criteria in item 2
                        above;
                      </li>
                      <li>
                        Any third party on whose behalf You have uploaded
                        Content has consented to your doing so.
                      </li>
                      <li>
                        All personal information and data placed on the
                        Matrimonial Advertisement by You are true, accurate,
                        current, complete and legally valid; and
                      </li>
                      <li>
                        You and a Third Party (if any) on whose behalf You are
                        using the Site are not prohibited by the laws and
                        regulations applicable to each of you or by any
                        contractual third party rights to disclose, advertise,
                        license, and or publish your personal information and
                        data on Poruwa.lk and or to permit such information to
                        be disclosed or published in the Magazine.
                      </li>
                      <li>
                        You and a third party (if any) on whose behalf You are
                        using the Site are not prohibited by applicable laws and
                        regulations and or by any contractual third party rights
                        to consent and authorize Us to collect, process, use,
                        share and or disclose personal information and data .
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Confidentiality</h2>
                    <p>
                      Without your prior consent, Your personal information,
                      data, and identity (and that of any third party on whose
                      behalf You access or use Poruwa.lk) will be kept
                      confidential except to the extent provided for in these
                      Terms and Conditions and or if an obligation arises to
                      make disclosure:
                    </p>
                    <ol className="alpha">
                      <li>to comply with the law or any regulations; and or</li>
                      <li>
                        to protect and enforce our rights or the rights of
                        others who use Poruwa.lk
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Privacy Policy</h2>
                    <p>
                      The Privacy Policy explains how personal information and
                      data are collected, used, shared, disclosed or otherwise
                      processed. The Site uses cookies and similar technologies.
                    </p>
                    <p>
                      By using Poruwa.lk You consent to the collection,
                      processing, using, sharing, and or disclosing of personal
                      information and data provided by You in line with the
                      Privacy Policy. Please read the Privacy Policy for
                      details.
                    </p>
                  </li>

                  <li>
                    <h2>
                      Withdrawal & Cancellation of Matrimonial Advertisement
                    </h2>
                    <p>
                      Advertisers can unpublish their ads at any time by
                      themselves. If they wish to have all the content removed
                      from our databases, they can request so via email.
                    </p>
                    <p>
                      The advertising fee is non-refundable unless the
                      advertiser requests the refund before the ad is being
                      published on the website. (Within the review period)
                    </p>
                  </li>

                  <li>
                    <h2>Intellectual Property</h2>
                    <p>
                      Poruwa.lk, and its databases, text, photographs, logos,
                      trademarks and all other intellectual property created and
                      or used in Poruwa.lk, including without limitation all
                      software, design work, layout, appearance, graphics etc,
                      are owned by Us or are licensed to Us and are protected by
                      the Intellectual Property laws of Australia.No material on
                      Poruwa.lk may be copied, downloaded, reproduced,
                      republished, stored, photographed, transmitted, installed,
                      posted, or distributed without Our written permission.
                    </p>
                  </li>

                  <li>
                    <h2>Disclaimer</h2>
                    <p>
                      We assume no responsibility for your use of Poruwa.lk. You
                      agree that We shall not in any way be held liable to You
                      for any loss, damage, or injury resulting from or arising
                      out of or which is in any way related to:
                    </p>
                    <ol className="alpha">
                      <li>
                        Errors on Poruwa.lk or the Content including, but not
                        limited to technical and typographical errors;
                      </li>
                      <li>
                        Inability to access, or use, or the unavailability of
                        Poruwa.lk;
                      </li>
                      <li>Your use of Poruwa.lk or the content; or</li>
                      <li>
                        Your use of any software in connection with Poruwa.lk
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Indemnity</h2>
                    <p>
                      By accessing and using Poruwa.lk You agree to indemnify
                      and hold harmless Serendib Systems Pty Ltd, its officers,
                      directors, employees and agents from and against all
                      claims or actions by third parties and any losses,
                      expenses, damages and costs including attorneys fees
                      resulting from any violation by You of these Terms and
                      Conditions including negligent or wrongful conduct by You
                      in accessing and using Poruwa.lk.
                    </p>
                  </li>
                  <li>
                    <h2>Termination / Its Consequences</h2>
                    <p>
                      This Agreement will terminate forthwith in the following
                      situations:
                    </p>
                    <ol className="alpha">
                      <li>
                        On the expiry of the advertising term; or
                      </li>
                      <li>
                        On your withdrawing your Matrimonial Advertisement
                        during the advertising term; or
                      </li>
                      <li>
                        By a breach of any of these Terms and Conditions, by You
                        and any Third Party on whose behalf You are using Our
                        Services including of any representation or warranty
                        therein, or of any applicable law or regulation; or
                      </li>
                    </ol>
                    <p>
                      Consequences of termination include Our right to forthwith
                      and without notice to you or liability terminate your
                      access to and use of Poruwa.lk. and delete your Content
                      from the Site
                    </p>
                  </li>
                  <li>
                    <h2>Amendments to the Terms and Conditions</h2>
                    <p>
                      These Terms and Conditions may be amended from time to
                      time at our sole discretion. The amended Terms and
                      Conditions will be effective immediately upon posting on
                      Poruwa.lk and your continued access and use of the Site
                      after the date of posting shall be deemed to constitute
                      your acceptance of the amended Terms and Conditions.
                    </p>
                  </li>
                  <li>
                    <h2>Governing Law & Jurisdiction</h2>
                    <p>
                      These Terms and Conditions shall be construed and
                      interpreted in accordance with the laws of Australia. The
                      Courts of Australia, with the District Court of Victoria
                      as the Court of first instance, shall have exclusive
                      jurisdiction in relation to all disputes arising under or
                      in relation to this Agreement.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </Grid>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}
