import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";

import UnavailableProfile from "../components/my-matches/interest-com/UnavailableProfile";
import Header from "../components/my-matches/detail-page/Header";
import TimeLine from "../components/my-matches/detail-page/TimeLine";
import AlertModal from "../components/my-matches/detail-page/AlertModal";
import UnmatchModal from "../components/modals/UnmatchModal";
import Config from "../v2/config";

import { removePost } from "../actions/removePost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../styles/disable-profile.css";

function DisabledAcc(props) {
  const [lng, setLng] = useState(true);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);

  useEffect(() => {
    // lng
    setLng(
      localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true
    );
  }, []);

  const handleLng = () => {
    this.setState({ lng: !lng });

    localStorage.setItem("lng", !lng);
  };

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          JSON.parse(sessionStorage.getItem("unavailable_profile")).id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.removeItem("unavailable_profile");
        setLoadingIgnore(false);
        setUnmatchModal(false);

        props.history.push("/my-matches");
        props.removePost();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  return (
    <React.Fragment>
      <PageHead title={`Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`}/>
      <Navbar handleLng={handleLng} lng={lng} />
      <div style={{ background: "#dfdfdf", height: "90vh" }}>
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container received-profile-detail-div"
          style={{ width: "auto" }}
        >
          <div>
            <Button
              variant="contained"
              className="back-btn-in-detail-pages "
              onClick={() => props.history.push("/my-matches")}
            >
              <i className="fas fa-angle-left"></i> &nbsp;
              {lng ? "Back" : "ආපසු යන්න"}
            </Button>

            {sessionStorage.getItem("unavailable_profile") && (
              <>
                <Header
                  lng={lng}
                  post={JSON.parse(
                    sessionStorage.getItem("unavailable_profile")
                  )}
                />
                <TimeLine
                  lng={lng}
                  post={JSON.parse(
                    sessionStorage.getItem("unavailable_profile")
                  )}
                  handleUnmatchModal={handleUnmatchModal}
                />
              </>
            )}

            <UnavailableProfile lng={lng} />
          </div>
        </Grid>
      </div>

      <AlertModal
        lng={lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
      />

      <UnmatchModal
        lng={lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
      />
    </React.Fragment>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ removePost }, dispatch);
};

export default connect(null, matchDispatchToProps)(DisabledAcc);
