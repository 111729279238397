import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/common/Navbar";
import InterestSideView from "../components/my-matches/InterestSideView";
import MessageView from "../components/my-matches/InterestMessageView";
import InterestProfile from "../components/my-matches/InterestProfile";
import InactiveProfile from "../components/my-matches/InactiveProfile";
import ReceivedInterest from "../components/my-matches/ReceivedInterestProfile";
import UnautorizedProfile from "../components/my-matches/interest-com/UnautorizedProfile";
import IncorrectToken from "../components/my-matches/interest-com/IncorrectToken";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import { handleIntBtns } from "../actions/handleIntBtns";
import { getInterestPosts } from "../actions/getInterestPosts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/my-matches.css";
import Config from "../v2/config";

const Interests = (props) => {
  const [lng, setLng] = useState(true);
  const [preLoading, setPreLoading] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [post, setPost] = useState([]);
  // unwanted state definition
  // const [inactivePost, setInactivePost] = useState(false);

  useEffect(() => {
    // props.getInterestPosts();
  }, []);

  useEffect(() => {
    // props.getInterestPosts();
    // const query = new URLSearchParams(props.location.search);
    // const id = query.get("id");
    // setSelectedId(id);
    const { search } = props.location;
    if (!search) {
      setSelectedId(undefined);
      return;
    }
    const searchParams = new URLSearchParams(search);
    setSelectedId(searchParams.get("id")); 
  }, [props.location]);

  // useEffect(() => {
  //   props.getInterestPosts();
  // }, [selectedId]);

  useEffect(() => {
    setIsAccessDenied(
      !(localStorage.getItem("auth_token") && localStorage.getItem("postId"))
    );

    /**
     * what is the purpose of closing chat token here
     */
    if (window.ws) {
      window.ws.close();
    }

    // chat_token
    /**
     * Is it always mandatory to retrieve & persit chat token
     * when everytime routing to this page?
     */
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("chat_token", res.data.token);
        sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

        // openWS(res.data.token);
      })
      .catch((err) => console.log(err));

    // setLng(!!JSON.parse(localStorage.getItem("lng")));
    setLng(
      localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true
    );
  }, []);

  const handleLng = () => {
    setLng((lng) => !lng);
    localStorage.setItem("lng", !lng);
  };

  return isAccessDenied ? (
    <Unauthorize title={`Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div kk">
          <InterestSideView
            lng={lng}
            intId={selectedId}
          />
          {/* {!inactivePost && selectedId && (
            <ReceivedInterest lng={lng} intId={selectedId} />
          )} */}
          {selectedId && <ReceivedInterest lng={lng} intId={selectedId} />}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ getInterestPosts, handleIntBtns }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    sideMenuInterests: state.matches.sideMenuInterests.interests,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Interests);
