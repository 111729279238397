import { Grid, Select, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import moment from "moment";
import FormInteractiveButton from "../form-interactive-button";

export const TIME_PATTERN = "HH:mm";
const PICKER_TIME_PATTERN = "HH:mm A";

const TimePickerFields = Object.freeze({
  hour: "hours",
  minute: "minute",
  meridiem: "meridiem",
});

const Placholder = {
  [TimePickerFields.hour]: "hh",
  [TimePickerFields.minute]: "mm",
  [TimePickerFields.meridiem]: "AM/PM",
};

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

const FormTimePicker = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  onClickInteractiveClear,
  value,
  onChange,
  lang,
  label,
  labelStyles = {},
  disabled = false,
  visibility = true,
}) => {
  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");
  const [meridiem, setMeridiem] = useState("");

  const hoursOptions = useMemo(() => {
    const output = ["hh"];
    for (let i = 1; i <= 12; i++) {
      output.push(i.toString().padStart(2, "0"));
    }
    return output;
  }, []);

  const monthOptions = useMemo(() => {
    const output = [];
    for (let i = 0; i < 60; i++) {
      output.push(i.toString().padStart(2, "0"));
    }
    return output;
  }, []);

  const meridiemOptions = useMemo(() => {
    return ["AM", "PM"];
  }, []);

  useEffect(() => {
    let time = "";
    if (!(onChange !== undefined && Boolean(hour))) {
      return;
    }
    if (hour === "hh") {
      setMinutes("mm");
      setMeridiem("AM/PM");
      onChange("");
      return;
    }
    if (!Boolean(minutes) && !Boolean(meridiem)) {
      time = moment(`${hour}: 00 AM`, PICKER_TIME_PATTERN);
    } else {
      time = moment(`${hour}: ${minutes} ${meridiem}`, PICKER_TIME_PATTERN);
    }
    onChange(time.format(TIME_PATTERN));
  }, [hour, minutes, meridiem, onChange]);

  useEffect(() => {
    if (!Boolean(value)) {
      return;
    }
    const parsedValue = moment(value, TIME_PATTERN);
    setHour(parsedValue.format("hh"));
    setMinutes(parsedValue.format("mm"));
    setMeridiem(parsedValue.format("A"));
  }, [value]);

  if (!visibility) {
    return false;
  }

  return (
    <Grid container direction="row" alignItems="center" className="time-picker">
      <Typography
        className={`timepicker-label timepicker-label-${lang}`}
        style={labelStyles}
      >
        {label}
      </Typography>
      <Grid item xs container direction="row" justifyContent="space-between">
        <Select
          variant="outlined"
          native
          value={hour}
          onChange={(e) => {
            setHour(e.target?.value ?? "");
          }}
          style={{ width: "32%" }}
          disabled={disabled}
        >
          <option selected hidden>
            {Placholder.hours}
          </option>
          {hoursOptions.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Select
          variant="outlined"
          native
          value={minutes}
          onChange={(e) => setMinutes(e.target?.value ?? "")}
          style={{ width: "32%" }}
          disabled={moment(hour, "HH").isValid() ? false : true}
        >
          <option selected hidden>
            {Placholder.minute}
          </option>
          {monthOptions.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Select
          variant="outlined"
          native
          value={meridiem}
          onChange={(e) => setMeridiem(e.target?.value ?? "")}
          style={{ width: "32%" }}
          disabled={moment(hour, "HH").isValid() ? false : true}
        >
          <option selected hidden>
            {Placholder.meridiem}
          </option>
          {meridiemOptions.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </Grid>
      {mode === Mode.interactive && showInteractiveActions && (
        <FormInteractiveButton
          onClickInteractiveClear={onClickInteractiveClear}
          onClickInteractiveSave={onClickInteractiveSave}
        />
      )}
    </Grid>
  );
};

export default FormTimePicker;
