import React, { Component } from "react";
import { Paper, Grid, CircularProgress } from "@material-ui/core";

import Info from "../../images/info.png";

class RemovedAd extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "8vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <div className="disable-div" style={{ marginTop: 0 }}>
                <p align="center" style={{ marginBottom: 0 }}>
                  <img
                    src={Info}
                    alt="info"
                    style={{ width: 50, opacity: 0.7 }}
                  />
                </p>
                <p className="message">
                  {this.props.lng ? "Ad is unavailable" : "දැන්වීම නොමැත"}
                </p>
                <p align="center" className="desc">
                  {this.props.lng ? (
                    "This advertisement has been taken down by its owner."
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත.
                    </span>
                  )}
                </p>
              </div>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default RemovedAd;
