import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";
import _12FromTo24Hours from "12fromto24hours";
import isEqual from "lodash/isEqual";

export default class Horoscope extends Component {
  state = {
    amIaddedHoro: false,
    imageData: [],
  };

  componentDidMount = async () => {
    if (localStorage.getItem("auth_token")) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/account/posts`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        );
        this.setState({
          amIaddedHoro:
            response.data[0].horoscopeInfo.birthCity &&
            response.data[0].horoscopeInfo.birthTime
              ? true
              : false,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  getHoroPhotos = async () => {
    const horoscopeInfo = this.props.horoscopeInfo ?? {};
    const horoscopeImages = horoscopeInfo.images ?? [];
    if (!(Array.isArray(horoscopeImages) && horoscopeImages.length > 0)) {
      return;
    }
    for (let i = 0; i < this.props.horoscopeInfo.images.length; i++) {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
            this.props.intId
          }/images/${this.props.horoscopeInfo.images[i]}?thumbnail=false`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            responseType: "blob",
          }
        );
        let data = URL.createObjectURL(response.data);

        this.setState({
          imageData: [...this.state.imageData, data],
          preLoading: false,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (isEqual(this.props, prevProps) && isEqual(this.state, prevState)) {
      return;
    }

    if (!isEqual(this.props, prevProps)) {
      if (!isEqual(this.props.horoscopeInfo, prevProps.horoscopeInfo)) {
        this.getHoroPhotos();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "10vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <Typography
                  variant="h6"
                  className="heading"
                  style={{ marginBottom: 30 }}
                >
                  {this.props.lng ? (
                    "Horoscope Info"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      කේන්දර විස්තර
                    </span>
                  )}
                </Typography>

                <div className="row">
                  <div className="review-col rev-col-left">
                    <div className="con-row">
                      <div className="con-col">
                        {this.props.lng ? "Matching" : "ගැලපීම"}
                      </div>
                      <div className="con-col">
                        {this.props.horoscopeMatching
                          ? this.props.lng
                            ? "Essential"
                            : "අත්‍යවශ්‍යයි"
                          : this.props.lng
                          ? "Not essential"
                          : "අත්‍යවශ්‍ය නොවේ"}
                      </div>
                    </div>
                  </div>
                  {this.props.interestInfo?.matched !== undefined ||
                  this.props.viewType === "full" ? (
                    <div className="review-col rev-col-right">
                      <div className="con-row">
                        <div className="con-col">
                          {this.props.lng ? "Birthday" : "උපන් දිනය"}
                        </div>
                        <div className="con-col">{this.props.dob}</div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {this.props?.isProfile && this.props.applyMatch ? (
                  <div className="row">
                    <HoroDetails
                      lng={this.props.lng}
                      originCountry={this.props.originCountry}
                      originCountry_si={this.props.originCountry_si}
                      horosDetails={this.props.horoscopeInfo}
                    />
                    <div>
                      {this.props.imageHoroData &&
                      this.props.imageHoroData.length > 0 ? (
                        <PhotoProvider>
                          {this.props.imageHoroData &&
                            this.props.imageHoroData.map((item, index) => (
                              <PhotoConsumer
                                key={index}
                                src={item}
                                intro={item}
                              >
                                <img
                                  src={item}
                                  alt="horoscope images"
                                  className="photos"
                                />
                              </PhotoConsumer>
                            ))}
                        </PhotoProvider>
                      ) : null}
                    </div>
                  </div>
                ) : this.state.amIaddedHoro && this.props.applyMatch ? (
                  this.props.horoscopeInfo.birthTime &&
                  this.props.horoscopeInfo.birthCity ? (
                    <div className="row">
                      <HoroDetails
                        lng={this.props.lng}
                        horosDetails={this.props.horoscopeInfo}
                        horoscopeMatching={this.props.horoscopeMatching}
                        originCountry={this.props.originCountry}
                        originCountry_si={this.props.originCountry_si}
                      />

                      <div>
                        {this.props.horoscopeInfo &&
                        this.props.horoscopeInfo.images &&
                        this.props.horoscopeInfo.images.length > 0 ? (
                          <PhotoProvider>
                            {this.state.imageData &&
                              this.state.imageData.map((item, index) => (
                                <PhotoConsumer
                                  key={index}
                                  src={item}
                                  intro={item}
                                >
                                  <img
                                    src={item}
                                    alt="horescope images"
                                    className="photos"
                                  />
                                </PhotoConsumer>
                              ))}
                          </PhotoProvider>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <p className="invisible-photos">
                      <i className="fas fa-eye-slash"></i>{" "}
                      {this.props.lng
                        ? "This user has not added horoscope information to their Ad. Your horoscope details will not be shared with them."
                        : "මෙම පරිශීලකයා ඔවුන්ගේ දැන්වීමට කේන්දර තොරතුරු ඇතුලත් කර නොමැත. එම නිසා ඔබේ කේන්දර තොරතුරු ඔවුන්ට පෙන්වන්නේ ද නොමැත."}
                    </p>
                  )
                ) : this.props.applyMatch ? (
                  <p className="invisible-photos" style={{ marginBottom: 0 }}>
                    <i className="fas fa-eye-slash"></i>{" "}
                    {this.props.lng
                      ? "Please add your birth city and the time to view horoscope details of your matches."
                      : "ඔබගේ ගැලපීම් වල කේන්දර විස්තර බැලීමට ඔබගේ උපන් නගරය සහ උපන් වෙලාව එක් කරන්න."}{" "}
                    <Link
                      to="/edit-my-ad#horoscope-section"
                      style={{ color: "#2196F3" }}
                    >
                      {this.props.lng
                        ? "Add horoscope info"
                        : "කේන්දර තොරතුරු එක් කරන්න"}
                    </Link>
                  </p>
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

class HoroDetails extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="review-col rev-col-left">
          <div className="con-row" style={{ background: "#fff" }}>
            <div className="con-col">
              {this.props.lng ? "Birth City" : "උපන් ස්ථානය"}
            </div>
            <div className="con-col">
              {this.props.horosDetails.birthCity
                ? this.props.horosDetails.birthCity
                : "-"}
            </div>
          </div>
          <div className="con-row" style={{ background: "#f2f2f2" }}>
            <div className="con-col">
              {this.props.lng ? "Birth Country" : "උපන් රට"}
            </div>
            <div className="con-col">
              {this.props.lng
                ? this.props.originCountry
                : this.props.originCountry_si}
            </div>
          </div>
        </div>

        <div className="review-col rev-col-right">
          <div className="con-row" style={{ background: "#fff" }}>
            <div className="con-col">
              {this.props.lng ? "Birth Time" : "උපන් වේලාව"}
            </div>
            <div className="con-col">
              {this.props.horosDetails.birthTime
                ? _12FromTo24Hours(this.props.horosDetails.birthTime)
                : "-"}
            </div>
          </div>

          <div className="con-row" style={{ background: "#f2f2f2" }}>
            <div className="con-col">
              {this.props.lng ? "Additional Info" : "අමතර තොරතුරු"}
            </div>
            <div className="con-col">
              {this.props.horosDetails.additionalData
                ? this.props.horosDetails.additionalData
                : "-"}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
