import { base64ToBlob } from "base64-blob";
import FileResizer from "react-image-file-resizer";
import Config from "../config";

export const createReduxAction = (type, payload) => ({ type, payload });

export const FileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Base64ToBlob = async (base64) => {
  const blob = await base64ToBlob(base64);
  return blob;
};

export const resizeImageToUpload = ({ blob, onResize }) => {
  return FileResizer.imageFileResizer(
    blob,
    1920,
    1080,
    "JPEG",
    80,
    0,
    (outputBlob) => onResize(outputBlob),
    "blob",
    200,
    200
  );
};

export const fieldVisibility = (fieldMeta) => {
  const metaPlatforms =
    Array.isArray(fieldMeta.platforms) && fieldMeta.platforms;
  const currentPlatform = Config.CURRENT_PLATFORM;
  return metaPlatforms ? metaPlatforms.includes(currentPlatform) : true;
};

export const buildModelByFormMeta = (formMeta) => {
  const currentPlatform = Config.CURRENT_PLATFORM;
  const formEntries = Object.values(formMeta);
  const model = {};
  for (const meta of formEntries) {
    const platforms = meta.platforms ?? [];
    if (meta.ignoreField || !platforms.includes(currentPlatform)) {
      continue;
    }
    const fieldName = meta.fieldName;
    const fieldType = meta.type;
    const defaultValue = meta.defaultValue;
    switch (fieldType) {
      case "array":
        model[fieldName] = [];
        break;
      case "boolean":
        model[fieldName] = defaultValue ? JSON.parse(defaultValue) : false;
        break;
      default: {
        model[fieldName] = !isEmptyOrNullOrUndefined(defaultValue)
          ? defaultValue
          : "";
        break;
      }
    }
  }
  return model;
};

export const isEmptyOrNullOrUndefined = (value) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === "string" && value.length === 0) {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (value.constructor === Object && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};

export const sanitizeEmptyNullUndefinedValues = (input, ignoreKeys = []) => {
  if (input.constructor !== Object && input.constructor !== Array) {
    return input;
  }
  if (input.constructor === Object) {
    for (const [key, value] of Object.entries(input)) {
      if (ignoreKeys.includes(key)) continue;
      if (isEmptyOrNullOrUndefined(value)) {
        delete input[key];
        continue;
      }
      if (value.constructor === Object || value.constructor === Array) {
        sanitizeEmptyNullUndefinedValues(value);
      }
    }
  }
  if (input.constructor === Array) {
    for (const value of input) {
      if (value.constructor === Array || value.constructor === Object) {
        sanitizeEmptyNullUndefinedValues(value);
      }
    }
  }
  return input;
};

export const scrollOnError = () => {
  try {
    const errorElements = document.getElementsByClassName("form-error-text");

    for (const temp of errorElements) {
      if (typeof temp.textContent === "string" && temp.textContent.length > 0) {
        temp.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        break;
      }
    }
  } catch (e) {
    console.log("Error! ", e);
  }
};

export const scrollToTop = () => {
  if (document.body?.scrollTop) {
    document.body.scrollTop = 0;
  } else if (document.documentElement?.scrollTop) {
    document.documentElement.scrollTop = 0;
  }
};

export const isOtherProfession = (input) => {
  if (isEmptyOrNullOrUndefined(input)) {
    return false;
  }
  const comparator = typeof input === "string" ? parseInt(input) : input;
  return comparator === Config.OTHER_PROFESSION_ID;
};

export const FormErrorMessage = Object.freeze({
  REQUIRED_FIELD: "REQUIRED_FIELD",
});
export const buildFormErrorMessage = (t, type, fieldName) => {
  let output = "";

  if (type === FormErrorMessage.REQUIRED_FIELD) {
    output = t("errors.formRequiredField", { fieldName: t(fieldName) });
  }
  return output;
};

export const parseToInteger = (input) => {
  let output = input;
  if (typeof input === "string") {
    output = parseInt(input);
  }
  return output;
};
