import store from "../../../../../store";
import { DIFFERENTLY_ABLED_OPTIONS } from "../../../../static-data";
import UserApi from "../../../../services/api/user";
import {
  getAuthTempProfile,
  getAuthUserAccount,
} from "../../../../features/application";
import { checkSensitiveData } from "../../../../features/generic-data";
import { isEmpty } from "lodash";
import { setAppSnackNotification } from "../../../../store/actions/application";
import Config from "../../../../config";

export const bindTemporaryProfile = ({
  input,
  authAccount,
  authTempProfile,
}) => {
  const { CURRENT_PLATFORM, PLATFORMS } = Config;
  const postData = authTempProfile.postData ?? {};
  const personalInfo = postData.personalInfo ?? {};

  // Common bindings
  input.accountOwner = postData.accountOwner ?? "";
  input.fname = personalInfo.fname ?? "";
  input.lname = personalInfo.lname ?? "";
  input.displayNameFormat = personalInfo.displayNameFormat ?? "";
  input.email = authAccount.email ?? "";
  input.dob = personalInfo.dob ?? "";
  input.gender = personalInfo.gender ?? "";
  input.religionId = personalInfo.religionId ?? "";
  input.height = personalInfo.height ?? "";
  input.civilStatusId = personalInfo.civilStatusId ?? "";
  input.children = personalInfo.children ?? "";
  input.residentCountryCode = personalInfo.residentCountryCode ?? "";
  input.residentCountryCodeOther = personalInfo.residentCountryCodeOther ?? "";
  input.residentRegionId = personalInfo.residentRegionId ?? "";
  input.residentCity = personalInfo.residentCity ?? "";
  input.visaTypeId = personalInfo.visaTypeId ?? "";
  input.educationLevelId = personalInfo.educationLevelId ?? "";
  input.professionId = personalInfo.professionId ?? "";
  input.otherProfession = personalInfo.otherProfession ?? "";
  input.drinking = personalInfo.drinking ?? "";
  input.smoking = personalInfo.smoking ?? "";
  input.foodPreferenceId = personalInfo.foodPreferenceId ?? "";
  input.additionalInfo = personalInfo.additionalInfo ?? "";
  input.differentlyAbled =
    postData.differentlyAbled ?? DIFFERENTLY_ABLED_OPTIONS[1].id;

  // LK specific bindings
  if (CURRENT_PLATFORM === PLATFORMS.LK) {
    input.ethnicityId = personalInfo.ethnicityId ?? "";
    input.caste = personalInfo.caste ?? "";
  }

  // IN specific bindings
  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    if (personalInfo.communityId) {
      input.communityId = personalInfo.communityId;
    } else {
      input.communityId = personalInfo.communityOther || "";
    }

    input.motherTongueId = personalInfo.motherTongueId;
    input.subCommunity = personalInfo.subCommunity;
    input.gothraId = personalInfo.gothraId;
  }
};

const handleSaveAuthTempProfile = async ({ formValues }) => {
  const {
    CURRENT_PLATFORM,
    PLATFORMS,
    OTHER_COUNTRY_CODE,
    BASE_COUNTRY_CODE,
    OTHER_PROFESSION_ID,
  } = Config;
  const { authTempProfile } = store.getState().application;
  const postData = authTempProfile.postData ?? {};

  // Common Form Values
  postData.accountOwner = formValues.accountOwner;
  postData.differentlyAbled = JSON.parse(formValues.differentlyAbled) ?? false;
  postData.makePhonePublic = postData.makePhonePublic ?? false;
  postData.horoscopeMatching = postData.horoscopeMatching ?? false;
  postData.email = formValues.email;

  postData.personalInfo = postData.personalInfo ?? {};
  postData.personalInfo.fname = formValues.fname;
  postData.personalInfo.lname = formValues.lname;
  postData.personalInfo.displayNameFormat = formValues.displayNameFormat;
  postData.personalInfo.dob = formValues.dob;
  postData.personalInfo.gender = formValues.gender;
  postData.personalInfo.height = parseFloat(formValues.height);
  postData.personalInfo.religionId = formValues.religionId;
  postData.personalInfo.residentCountryCode = formValues.residentCountryCode;
  postData.personalInfo.residentCountryCodeOther =
    formValues.residentCountryCode === OTHER_COUNTRY_CODE
      ? formValues.residentCountryCodeOther
      : null;
  postData.personalInfo.residentRegionId =
    formValues.residentCountryCode !== OTHER_COUNTRY_CODE
      ? formValues.residentRegionId
      : null;
  postData.personalInfo.residentCity = formValues.residentCity;
  postData.personalInfo.visaTypeId =
    formValues.residentCountryCode === BASE_COUNTRY_CODE
      ? "citizen"
      : formValues.visaTypeId;
  postData.personalInfo.educationLevelId = formValues.educationLevelId;
  postData.personalInfo.professionId = formValues.professionId;
  postData.personalInfo.otherProfession =
    parseInt(formValues.professionId) === OTHER_PROFESSION_ID
      ? formValues.otherProfession
      : null;
  postData.personalInfo.civilStatusId = formValues.civilStatusId;
  postData.personalInfo.children =
    formValues.civilStatusId !== "never_married" ? formValues.children : null;
  postData.personalInfo.educationLevelId = formValues.educationLevelId;
  postData.personalInfo.smoking = formValues.smoking;
  postData.personalInfo.drinking = formValues.drinking;
  postData.personalInfo.foodPreferenceId = formValues.foodPreferenceId;
  postData.personalInfo.additionalInfo = formValues.additionalInfo;

  // LK specific form values
  if (CURRENT_PLATFORM === PLATFORMS.LK) {
    postData.personalInfo.ethnicityId = formValues.ethnicityId;
    postData.personalInfo.caste = formValues.caste;
  }

  // IN specific form values
  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    if (
      formValues.communityId === "OTHER" ||
      formValues.communityId === "UNDISCLOSED"
    ) {
      postData.personalInfo.communityId = null;
      postData.personalInfo.communityOther = formValues.communityId;
    } else {
      postData.personalInfo.communityOther = null;
      postData.personalInfo.communityId = formValues.communityId;
    }

    postData.personalInfo.motherTongueId = formValues.motherTongueId;
    postData.personalInfo.subCommunity = formValues.subCommunity;
    postData.personalInfo.gothraId = formValues.gothraId;
  }

  const response = await UserApi.postUserTempProfile({
    tempProfile: postData,
  });
  return response;
};

export const removeTempUserEmail = () => async (dispatch) => {
  const response = await UserApi.removeUserEmail();
  if (response.success) {
    dispatch(getAuthUserAccount());
  }
  return response;
};

export const updateTempUserEmail =
  ({ email }) =>
  async () => {
    const response = await UserApi.postUserEmail({ email });
    return response;
  };

export const saveTempProfile = async ({
  dispatch,
  formValues,
  currentProfile,
  history,
  setFieldError,
  t,
}) => {
  try {
    // Description field validation
    if (!isEmpty(formValues.additionalInfo)) {
      const isDescriptionValid = await checkSensitiveData({
        content: formValues.additionalInfo,
      });
      if (!isDescriptionValid) {
        setFieldError(
          "additionalInfo",
          t("createflow.descriptionSensitiveDataError")
        );
        return;
      }
    }
    // Remove email
    if (isEmpty(formValues.email) && !isEmpty(currentProfile.email)) {
      const response = await dispatch(removeTempUserEmail());
      if (!response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to update email",
          })
        );
        return;
      }
    }
    // Update email
    if (
      !isEmpty(formValues.email) &&
      formValues.email !== currentProfile.email
    ) {
      const response = await dispatch(
        updateTempUserEmail({ email: formValues.email })
      );

      if (!response.success) {
        setFieldError("email", t("createflow.emailUsedByOtherAccountError"));
        return;
      }
    }
    
    // save temp profile data
    const response = await handleSaveAuthTempProfile({ formValues });
    if (!response.success) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unable to save profile",
        })
      );
      return;
    }
    dispatch(
      setAppSnackNotification({
        severity: "success",
        message: "Data Saved Successfully",
      })
    );
    await dispatch(getAuthUserAccount());
    await dispatch(getAuthTempProfile());
    history.push("/complete-ad-parent");
  } catch (error) {
    dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unknown Error Occurred, Try Again",
      })
    );
  }
};
