import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import "../../styles/settings.css";

const initialStates = {
  currentPwd: "",
  newPwd: "",
  confirmPwd: "",
  isShowPwd: false,
  isShowConPwd: false,
  hasPassword: false,

  // errors
  currentPwdError: "",
  newPwdError: "",
  confirmPwdError: "",

  // disabled
  dissableBtn: false,

  // loadings
  loading: false,
  loadingCancel: false,

  // alerts
  successAlert: false,
  failAlert: false,
};

export default class PwdChange extends Component {
  state = initialStates;
  componentDidMount = () => {
    let ownerUrl = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      ownerUrl = `operator/managed_posts/${localStorage.getItem(
        "postId"
      )}/owner`;
    } else {
      ownerUrl = "account";
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${ownerUrl}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({ hasPassword: res.data.hasPassword });
        localStorage.setItem("user_name", res.data.name);
        localStorage.setItem(
          "user_email",
          res.data.email ? res.data.email : ""
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        // dissable buttons with changes
        if (
          this.state.currentPwd === "" &&
          this.state.newPwd === "" &&
          this.state.confirmPwd === ""
        ) {
          this.setState({ dissableBtn: false });
        } else {
          this.setState({ dissableBtn: true });
        }
      }
    );
  };

  // validate
  validate = () => {
    let currentPwdError = "";
    let newPwdError = "";
    let confirmPwdError = "";

    if (this.state.hasPassword && this.state.currentPwd === "") {
      currentPwdError =
        this.props.lng === true
          ? "Current password is required"
          : "වත්මන් මුරපදය අවශ්‍ය වේ";
    }

    if (this.state.newPwd === "") {
      newPwdError =
        this.props.lng === true
          ? "New password is required"
          : "නව මුරපදය අවශ්‍ය වේ";
    } else if (this.state.newPwd.length < 8) {
      newPwdError = this.props.lng
        ? "Password should be at least 8 characters long"
        : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය";
    }

    if (this.state.confirmPwd === "") {
      confirmPwdError =
        this.props.lng === true
          ? "Confirmation password is required"
          : "තහවුරු කිරීමේ මුරපදය අවශ්‍ය වේ";
    } else if (this.state.newPwd !== this.state.confirmPwd) {
      confirmPwdError =
        this.props.lng === true
          ? "Passwords should match"
          : "මුරපද ගැලපිය යුතුය";
    }

    if (currentPwdError || newPwdError || confirmPwdError) {
      this.setState({
        currentPwdError,
        newPwdError,
        confirmPwdError,
      });
      return false;
    }

    return true;
  };

  // submit sign-up form
  handleSave = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        currentPwdError: "",
        newPwdError: "",
        confirmPwdError: "",
      });

      const data = `oldPassword=${this.state.currentPwd}&newPassword=${this.state.newPwd}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/update_password`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            successAlert: true,
            currentPwd: "",
            newPwd: "",
            confirmPwd: "",
            dissableBtn: false,
            loading: false,
            hasPassword: true,
          });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("userId", res.data.memberId);
          let ownerUrl = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            ownerUrl = `operator/managed_posts/${localStorage.getItem(
              "postId"
            )}/owner`;
          } else {
            ownerUrl = "account";
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${ownerUrl}`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem(
                "user_email",
                res.data.email ? res.data.email : ""
              );
            })
            .catch((err) => {
              console.log(err);
            });

          let url = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            url = `operator/managed_posts/${localStorage.getItem("postId")}`;
          } else {
            url = "account/posts";
          }
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (
                localStorage.getItem("user_role") === "OPERATOR" ||
                localStorage.getItem("user_role") === "OPERATOR_L2"
              ) {
                res.data[0] = res.data;
              }
              if (res.data.length > 0) {
                localStorage.setItem(
                  "post_details",
                  JSON.stringify(res.data[0])
                );
                localStorage.setItem("postId", res.data[0].id);
              }
            })
            .catch((err) => {
              this.setState({ loadingLogin: false });
            });
        })
        .catch((err) => {
          if (err.response.data.code === 1115) {
            this.setState({
              currentPwdError:
                this.props.lng === true
                  ? "Your current password is incorrect"
                  : "ඔබගේ වර්තමාන මුරපදය වැරදිය",
            });
          } else if (err.response.data.code === 1106) {
            this.setState({
              error:
                this.props.lng === true
                  ? "Password too short"
                  : "මුරපදය ඉතා කෙටිය",
            });
          } else {
            this.setState({ failAlert: true });

            setTimeout(() => {
              this.setState({
                failAlert: false,
              });
            }, 2000);
          }

          this.setState({ loading: false });
        });
    }
  };

  handleCancel = () => {
    this.setState(initialStates);
  };

  handleClose = () => {
    this.setState({ successAlert: false, failAlert: false });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div settings-container"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <p className="sec-heading">
                    {this.props.lng ? (
                      "Change Password"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        මුරපදය වෙනස් කරන්න
                      </span>
                    )}
                  </p>
                  <div className="col">
                    {this.state.hasPassword && (
                      <div className="field-row">
                        <div className="field-col">
                          <p>
                            {this.props.lng
                              ? "Current Password"
                              : "වත්මන් මුරපදය"}
                          </p>
                        </div>
                        <div className="field-col">
                          <input
                            type="password"
                            className={
                              this.state.currentPwdError ? "red-border" : ""
                            }
                            name="currentPwd"
                            value={this.state.currentPwd}
                            onChange={this.handleChange}
                          />
                          <p className="fields-error">
                            {this.state.currentPwdError}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "New Password" : "නව මුරපදය"}</p>
                      </div>
                      <div className="field-col">
                        <div style={{ position: "relative" }}>
                          <input
                            type={this.state.isShowPwd ? "text" : "password"}
                            className={
                              this.state.newPwdError ? "red-border" : ""
                            }
                            name="newPwd"
                            value={this.state.newPwd}
                            onChange={this.handleChange}
                          />
                          {this.state.isShowPwd ? (
                            <i
                              className="fas fa-eye-slash password-show-hide-btn"
                              onClick={() =>
                                this.setState({ isShowPwd: false })
                              }
                            ></i>
                          ) : (
                            <i
                              className="fas fa-eye password-show-hide-btn"
                              onClick={() => this.setState({ isShowPwd: true })}
                            ></i>
                          )}
                        </div>
                        <p className="fields-error">{this.state.newPwdError}</p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Confirm New Password"
                            : "නව මුරපදය තහවුරු කරන්න"}
                        </p>
                      </div>
                      <div className="field-col">
                        <div style={{ position: "relative" }}>
                          <input
                            type={this.state.isShowConPwd ? "text" : "password"}
                            className={
                              this.state.confirmPwdError ? "red-border" : ""
                            }
                            name="confirmPwd"
                            value={this.state.confirmPwd}
                            onChange={this.handleChange}
                          />
                          {this.state.isShowConPwd ? (
                            <i
                              className="fas fa-eye-slash password-show-hide-btn"
                              onClick={() =>
                                this.setState({ isShowConPwd: false })
                              }
                            ></i>
                          ) : (
                            <i
                              className="fas fa-eye password-show-hide-btn"
                              onClick={() =>
                                this.setState({ isShowConPwd: true })
                              }
                            ></i>
                          )}
                        </div>
                        <p style={{ fontSize: "12px", color: "#787777" }}>
                          {this.props.lng
                            ? "(Min length: 8 characters)"
                            : "(අවම දිග: අකුරු 8 යි)"}
                        </p>
                        <p className="fields-error">
                          {this.state.confirmPwdError}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col"></div>

                  <div style={{ paddingBottom: 70 }}>
                    {this.state.dissableBtn ? (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          // className="edit-btn"
                          className="common-save-btn"
                          onClick={this.handleSave}
                          style={{ float: "right", marginTop: "20px" }}
                        >
                          {this.state.loading ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Save"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  තහවුරු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="common-cancel-btn"
                          onClick={this.handleCancel}
                          style={{
                            float: "right",
                            marginRight: "12px",
                            marginTop: "20px",
                          }}
                        >
                          {this.state.loadingCancel ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Cancel"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  අවලංගු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="edit-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Save"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              තහවුරු කරන්න
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="cancel-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Cancel"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              අවලංගු කරන්න
                            </span>
                          )}
                        </Button>{" "}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Password has been changed successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    මුරපදය සාර්ථකව වෙනස් කරන ලදි.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    මුරපදය සාර්ථකව වෙනස් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
