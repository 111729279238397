import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import NewFeatureBanner from "../components/banners/NewFeatureBanner";
import GoogleReviewBanner from "../components/banners/GoogleReviewBanner";
import HelpBar from "../components/help-bar/HelpBar";
import Footer from "../components/common/Footer";
import Container from "../components/home/Container";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import "../styles/all-ads.css";
import FooterMobile from "../components/common/FooterMobile";
import NICBanner from "../components/banners/NICBanner";
import GoogleAds from "../components/common/GoogleAds.jsx";
import GoogleDisplayAds from "../components/common/GoogleDisplayAds";
import HytchAwarenessBanner from "../components/hytch-awareness-banner";
import PageMetaDescription from "../components/common/PageMetaDescription";
import Constants from "../v2/config/constant";
import AppContext from "../v2/context/index.js";
// import AppLogger from "../v2/utils/logger/index.js";
// import SupportServiceUnavailableBanner from "../components/banners/SupportServiceUnavailable.jsx";

import "../v2/pages/home/style.css";
import NotificationBar from "../v2/pages/create-flow/templates/notification-bar/index.jsx";
import { useSelector } from "react-redux";
import ProfileCompletionAlert from "../components/banners/profile-completion-alert/index.jsx";

const FunctionalProxy = ({ children }) => {
  const { snackNotification } = useSelector((state) => state.application);

  return (
    <>
      {children}
      <NotificationBar
        severity={snackNotification.severity}
        message={snackNotification.message}
      />
    </>
  );
};

export default class Home extends Component {
  state = {
    showMagazineBanner: false,
    showSupportBanner: "0",
    localHideBanner: false,
    isDev: false,
    isMobileDevice: false,
    currentUserProfile: undefined,
    allowNICbannerFromDate: false,
    steps: [
      {
        target: "#logged_msg_icon",
        disableBeacon: true,
        content: "Check your messeges here",
      },
      {
        target: "#logged_menu_btn",
        content: "Main menu",
      },
      {
        target: ".side-panel-filters",
        content: "This is the filter options",
        placement: "right-start",
      },
      {
        target: ".search-results",
        content: "Preferred search option",
      },
      {
        target: "#first-post",
        content: "Search result posts",
        placement: "right-start",
      },
    ],
    metaContent: Constants.META_DESCRIPTION,
    post: {},
  };

  componentWillMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get("lng");
    // const postDetails = JSON.parse(localStorage.getItem("post_details"));
    // this.setState({ post: postDetails });

    this.setState({
      lng: lng
        ? lng === "si"
          ? false
          : true
        : localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    if (lng) {
      if (lng === "si") {
        localStorage.setItem("lng", false);
      } else {
        localStorage.setItem("lng", true);
      }
    }
  };

  componentDidMount = () => {
    if (this.state.post !== null) {
      const userProfile = this.state.post;
      this.setState({ currentUserProfile: userProfile });
    }

    if (localStorage.getItem("magazine-banner") == "false") {
      this.setState({ showMagazineBanner: false });
    } else {
      this.setState({ showMagazineBanner: true });
    }

    if (localStorage.getItem("support-banner") === "0") {
      this.setState({ showSupportBanner: "0" });
    } else if (localStorage.getItem("support-banner") === "1") {
      this.setState({ showSupportBanner: "1" });
    } else if (localStorage.getItem("support-banner") === "2") {
      this.setState({ showSupportBanner: "2" });
    } else {
      this.setState({ showSupportBanner: "0" });
      localStorage.setItem("support-banner", "0");
    }

    this.setState({
      isDev: Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP,
    });
  };

  handleCloseMagazineBanner = () => {
    localStorage.setItem("magazine-banner", "false");
    this.setState({ showMagazineBanner: false });
  };

  handleCloseSupportBanner = () => {
    this.setState({ localHideBanner: true });
    if (localStorage.getItem("support-banner")) {
      if (localStorage.getItem("support-banner") === "1") {
        this.setState({ showSupportBanner: "2" });
        localStorage.setItem("support-banner", "2");
      } else if (localStorage.getItem("support-banner") === "0") {
        this.setState({ showSupportBanner: "1" });
        localStorage.setItem("support-banner", "1");
      }
    } else {
      this.setState({ showSupportBanner: "1" });
      localStorage.setItem("support-banner", "1");
    }
  };

  render() {
    const { steps, isDev, currentUserProfile: userProfile } = this.state;
    return (
      <AppContext.Consumer>
        {({ lng, handleLng }) => (
          <FunctionalProxy>
            {/* <OnboardingTour run={isDev} steps={steps}/> */}
            <PageHead
              title={`${Config.DOMAIN_DISPLAY_NAME} | ${
                Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
                  ? "Sri Lankan Matrimony"
                  : "Indian Matrimony"
              }`}
            />
            <PageMetaDescription name="Home" content={this.state.metaContent} />
            <Navbar handleLng={handleLng} lng={lng} />
            {/* <ServiceUnavailableBanner lng={lng} /> */}
            {/* {!this.state.localHideBanner &&
          this.state.showSupportBanner !== "2" &&
          new Date() <= new Date("August 30 2022 22:00") && (
            <SupportLineChangingBanner
              lng={lng}
              handleCloseSupportBanner={this.handleCloseSupportBanner}
            />
          )} */}

            {/* <SupportServiceUnavailableBanner lng={lng} /> */}
            <ProfileCompletionAlert lng={lng}/>
            {this.state.post && (
              <NICBanner lng={lng} profile={this.state.post} />
            )}

            {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
              <GoogleReviewBanner lng={lng} />
            )}
            <NewFeatureBanner lng={lng} />
            <HelpBar />
            {/* {userProfile !== undefined &&
              userProfile.personalInfo?.gender === "FEMALE" &&
              userProfile.createdBy === "SELF" &&
              ENABLE_HYTCH && (
                <div
                  style={{
                    backgroundColor: "#dfdfdf",
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <HytchAwarenessBanner />
                </div>
              )} */}
            <div
              className="all-ads-main-container"
              style={{ marginTop: 0, paddingTop: 1 }}
            >
              {/* <div style={{ maxWidth: "960px", margin: "auto" }}>
                <GoogleDisplayAds slot="1637238158" />
              </div> */}
              <Grid
                container
                xs={12}
                sm={10}
                md={8}
                className="all-ads-container"
              >
                <Container lng={lng} />
              </Grid>
              {/* <div
                container
                xs={12}
                sm={10}
                md={8}
                className="all-ads-container"
                style={{ background: "#dfdfdf", marginTop: "20px" }}
              >
                <GoogleAds slot="8873193363" />
              </div> */}
            </div>
            <div className="create-floor-footer-desktop">
              <Footer lng={lng} />
            </div>
            <div className="create-floor-footer-mobile">
              <FooterMobile lng={lng} />
            </div>
          </FunctionalProxy>
        )}
      </AppContext.Consumer>
    );
  }
}
