import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/common/Navbar";
import InterestSideView from "../components/my-matches/InterestSideView";
import MessageView from "../components/my-matches/InterestMessageView";
import InterestProfile from "../components/my-matches/InterestProfile";
import InactiveProfile from "../components/my-matches/InactiveProfile";
// import ReceivedInterest from "../components/my-matches/ReceivedInterest";
// import UnautorizedProfile from "../components/my-matches/interest-com/UnautorizedProfile";
// import IncorrectToken from "../components/my-matches/interest-com/IncorrectToken";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import { handleIntBtns } from "../actions/handleIntBtns";
import { getMatchedPosts } from "../actions/getMatchedPosts";
import { updateSeen } from "../actions/updateSeen";
import { fetchNotificationCount } from "../actions/fetchNotificationCount";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/my-matches.css";
import { fetchMessagePostData } from "../actions/fetchMessagePostData";
import Config from "../v2/config";

const Messages = (props) => {
  const [authProfile, setAuthProfile] = useState(undefined);
  const [chatTokenObj, setChatTokenObj] = useState({});
  const [lng, setLng] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [post, setPost] = useState([]);
  const [inactivePost, setInactivePost] = useState(false);
  const [loading, setLoading] = useState(false);

  const markMatchSeen = () => {
    axios.post(
      `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem(
        "postId"
      )}/matches/${selectedId}/seen
        `,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
  };

  const fetchSelectedMatch = async () => {
    try {
      const { fetchMessagePostData, history } = props;
      fetchMessagePostData(selectedId, history, setLoading);
    } catch (error) {
      console.log("[Error!] fetchSelectedMatch ", error);
    }
  }

  useEffect(() => {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("id")) {
      setSelectedId(searchParams.get("id"));
    } else {
      setSelectedId(undefined);
    }
  }, [props.location]);

  useEffect(() => {
    if (!selectedId) {
      return;
    }
    fetchSelectedMatch();
  }, [selectedId]);

  useEffect(() => {
    const {
      messagePostData: selectedPost,
      fetchNotificationCount,
      updateSeen,
      inactiveProfile,
    } = props;
    if (
      selectedPost.constructor === Object &&
      Object.keys(selectedPost).length
    ) {
      try {
        setInactivePost(inactiveProfile);
        fetchNotificationCount();
        updateSeen(selectedId);
        const { matchSeen } = selectedPost;
        if (matchSeen === false) {
          markMatchSeen();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.messagePostData]);

  const fetchChatToken = async () => {
    try {
      let URL = `${process.env.REACT_APP_API_URL}/v1`;
      URL += `/account/posts/${authProfile.id}/chat_token`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      if (response.status === 200) {
        setChatTokenObj(response.data);
        sessionStorage.setItem("chat_token", response.data.token);
        sessionStorage.setItem(
          "chat_token_expires_in",
          response.data.expiresAt
        );

        window.ws = openWebSocket(response.data.token);
        window.ws.onclose = (event) => {
          window.ws = openWebSocket(response.data.token);
        };
        setInterval(() => {
          window.ws.send("ping");
        }, 3000);
      }
    } catch (error) {
      console.log("Error! Messages fetchChatToken ", error);
    }
  };


  const openWebSocket = (token) => {
      // web socket
    const socket = new WebSocket(
      `wss://${Config.WEB_SOCKET_URL}/v1/ws/chat_subscribe?token=${token}`
    );
    return socket;
  };

  useEffect(() => {
    if (authProfile === undefined) {
      return;
    }
    fetchChatToken();

    return () => {
      if (window.ws.readyState === 1) {
        window.ws.close();
      }
    };
  }, [authProfile]);

  useEffect(() => {
    setIsAccessDenied(
      !(localStorage.getItem("auth_token") && localStorage.getItem("postId"))
    );

    if (localStorage.getItem("post_details") === null) {
      // TODO: if auth token present, fetch AuthProfile from backend
      setIsAccessDenied(true);
    }
    const _authProfile = JSON.parse(localStorage.getItem("post_details"));
    setAuthProfile(_authProfile);
   
    const language = JSON.parse(localStorage.getItem("lng") ?? "true");
    localStorage.setItem("lng", language);
    setLng(language);
  }, []);

  const handleLng = () => {
    setLng((lng) => !lng);
    localStorage.setItem("lng", !lng);
  };

  const RightContent = () => {
    if (!selectedId) {
      return false;
    }
    const { messagePostData } = props;
    if (
      messagePostData.constructor !== Object ||
      Array.isArray(messagePostData) ||
      Object.entries(messagePostData).length === 0
    ) {
      return false;
    }
    return (
      <>
        <MessageView
          lng={lng}
          post={inactivePost ? undefined : props.messagePostData}
          inactivePost={inactivePost}
          intId={selectedId}
          chatToken={chatTokenObj}
          fetchChatToken={fetchChatToken}
          processing={loading}
        />
        {inactivePost ? (
          <InactiveProfile intId={selectedId} lng={lng} />
        ) : (
          <InterestProfile
            intId={selectedId}
            lng={lng}
            post={props.messagePostData}
            loading={loading}
          />
        )}
      </>
    );
  };

  return isAccessDenied ? (
    <Unauthorize title={`Messages | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Messages | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div kk">
          <InterestSideView
            lng={lng}
            intId={selectedId}
          />
          <RightContent />
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMatchedPosts,
      handleIntBtns,
      updateSeen,
      fetchNotificationCount,
      fetchMessagePostData,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    // sideMenuMatches: state.matches.sideMenuPosts.matched,
    post: state.matches.post,
    // matchedPosts: state.matches.allInterests.matches
    //   ? state.matches.allInterests.matches
    //   : [],
    messagePostData: state.matches.messagePostData,
    inactiveProfile: state.matches.inactiveProfile,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Messages);
