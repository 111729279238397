import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";

import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import NotLoggedBanner from "../components/banners/NotLoggedBanner";
import Header from "../components/post-details/Header";
import TimeLine from "../components/post-details/TimeLine";
import Connect from "../components/post-details/Connect";
import Personal from "../components/post-details/Personal";
import Parent from "../components/post-details/Parent";
import Horoscope from "../components/post-details/Horoscope";
import Config from "../v2/config";

import "../styles/post-details.css";
import "../styles/ad-details.css";
import CancelRequestModal from "../components/modals/CancelRequestModal";
import FooterMobile from "../components/common/FooterMobile";
import NeedToCreateAnAccount from "../components/modals/NeedToCreateAnAccount";
import { getBaseUrlBasedOnCountry } from "../methods/getBaseUrlBasedOnCountry";

import Unavailable from "../pages/DisabledAcc";
import PageNotFound from "../pages/PageNotFound";
import UnauthorizedUser from "../pages/IncorrectToken";
import RestrictedAccount from "../pages/RestrictedProfile";
import Outage from "../pages/Outage";
import PageHead from "../components/common/PageHead";
import GoogleAds from "../components/common/GoogleAds.jsx";
import GoogleInfeedAds from "../components/common/GoogleInfeedAds.jsx";
import GoogleDisplayAds from "../components/common/GoogleDisplayAds.jsx";
import Alerts from "../components/post-details/Alerts";

class postDetails extends Component {
  state = {
    lng: true, // default en
    offlineId: "",
    // pages
    applyMatch: false,
    applyInterest: false,
    applyNormalPost: false,

    isInterestInfo: false,
    interestInfo: {},

    postData: {},
    // personal
    postId: "",
    isOffline: true,
    publishedAt: "",
    isExpired: false,
    country: "",
    country_si: "",
    gender: "",
    createdBy: "",
    differentlyAbled: "",
    nicVerified: "",
    isFeatured: false,
    fName: "",
    lname: "",
    lnameMasked: "",
    age: "",
    residentCity: "",
    residentRegion: "",
    countryCode: "",
    description: "",
    ethnicity: "",
    ethnicity_si: "",
    religion: "",
    religion_si: "",
    caste: "",
    height: "",
    status: "",
    status_si: "",
    originCountry: "",
    originCountry_si: "",
    visaType: "",
    visaType_si: "",
    eduLevel: "",
    eduLevel_si: "",
    profession: "",
    profession_si: "",
    drinking: "",
    smoking: "",
    displayName: "",
    children: "",
    ignorePreferences: false,

    //Alerts
    lastOnline: "",
    hasImages: false,
    hasHoroscopeData: false,

    // parent
    professionF: "",
    ethnicityF: "",
    religionF: "",
    casteF: "",
    fCountryOfResidency: "",
    fCountryOfResidency_si: "",
    additionalInfoF: "",
    professionM: "",
    ethnicityM: "",
    religionM: "",
    casteM: "",
    mCountryOfResidency: "",
    mCountryOfResidency_si: "",
    additionalInfoM: "",

    // private
    imageData: [],
    images: [],
    phone: "",
    showPhone: false,
    phoneVisibilityModal: false,
    email: "",
    horoscopeMatching: false,
    birthDate: "",
    birthTime: "",
    birthCity: "",
    areThereMyPhotos: "",
    horoscopeInfo: {},
    dob: "",
    isHoroscope: false,
    showPhotos: false,
    receivedInterestImages: [],
    isIgnored: false,
    autoIgnored: false,
    receivedTime: "",
    receivedTo: "",
    acceptTime: "",
    acceptBy: "",
    unmatchedTime: "",
    unmatchedBy: "",

    likeMessage: "",
    matchMessage: "",
    sentTime: "",
    cancelSentTime: "",
    cancelAcceptTime: "",
    isHideConnect: false,

    undoAutoRejectTime: "",
    undoAutoRejectedBy: "",

    // page loading
    preLoading: true,
    btnLoading: false,

    // alert modal
    alertModal: false,
    alertHeading: "",
    alertMessage: "",
    cancelRequest: false,
    ignoreModal: false,
    ignoreReason: "",
    ignoredBy: "",

    // show interest msg modal
    intMessageModal: false,
    messageModal: false,
    sendingPostId: "",
    sensitiveDataError: "",

    // preference mismatch modal
    preferenceMismatchModal: false,
    preferenceMismatchMessage: "",
    preferenceMismatchMessageSinhala: "",

    // need to create an account
    needToCreateAnAccount: false,

    // report modal
    matchPostId: "",
    reportedPosts: [],
    reasons: [],
    reportModal: false,
    isDissable: true,
    error: false,
    whyReport: "",
    reasonDesc: "",

    // redirects
    RedirectToMatches: false,
    redirectToUnavailabele: false,
    redirectToPageNotFound: false,
    redirectToUnauthorizedUser: false,
    redirectToRestrictedAccount: false,
    outagePage: false,

    // loading
    loadingIgnore: false,
    loading: false,

    intId: "",

    // showIntTooltip
    showIntTooltip: true,

    siblingsInfo: [],
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    this.getPostInfo();

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getPostInfo = () => {
    if (
      localStorage.getItem("postId") &&
      localStorage.getItem("post_details")
    ) {
      var header = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      };
    } else {
      var header = {};
    }

    const id = document.location.href.split("=");

    axios
      .get(
        `${
          getBaseUrlBasedOnCountry()
            ? getBaseUrlBasedOnCountry()
            : process.env.REACT_APP_API_URL
        }/v1/posts/${id[1]}${
          localStorage.getItem("postId")
            ? localStorage.getItem("post_details")
              ? `?context=${localStorage.getItem("postId")}`
              : ""
            : ""
        }
        `,
        header
      )
      .then((res) => {
        this.setState({
          createdBy: res.data.createdBy ? res.data.createdBy : "",
          offlineId: res.data.offlineId ? res.data.offlineId : "",
          siblingsInfo: res.data.siblingInfo ? res.data.siblingInfo : [],
          differentlyAbled: res.data.differentlyAbled ? "Yes" : "No",
          nicVerified: res.data.idVerified ? "Yes" : "No",
          isFeatured: res.data.isFeatured,
          horoscopeMatching: res.data.horoscopeMatching,
        });

        this.setState({ isInterestInfo: res.data.interestInfo ? true : false });

        if (res.data.lastOnline) {
          this.setState({ lastOnline: res.data.lastOnline });
        }
        if (res.data.hasImages) {
          this.setState({ hasImages: res.data.hasImages });
        }
        if (res.data.hasHoroscopeData) {
          this.setState({ hasHoroscopeData: res.data.hasHoroscopeData });
        }
        if (res.data.interestInfo) {
          this.setState({
            interestInfo: res.data.interestInfo,
          });

          if (res.data.interestInfo.matched) {
            this.setState({
              applyMatch: true,
              applyInterest: false,
              applyNormalPost: false,
            });
            this.getMatchedData(res);
          } else {
            this.setState({
              applyInterest: true,
              applyMatch: false,
              applyNormalPost: false,
            });
            this.getInterestedData(res);
          }
        } else {
          this.setState({
            applyNormalPost: true,
            applyMatch: false,
            applyInterest: false,
          });
          this.getNonInterestedData(res);
        }
      })
      .catch((err) => {
        console.log(err);
        // if (err.message == "Network Error" || err.response.status === 501 || err.response.status === 502 || err.response.status === 503 || err.response.status === 520) {
        if (err.message && err.message == "Network Error") {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 501
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 502
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 503
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 520
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.code === 1001
        ) {
          // this.props.history.push("/404");
          this.setState({ redirectToPageNotFound: true });
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.code === 1006
        ) {
          // this.props.history.push("/unavailable-profile");
          this.setState({ redirectToUnavailabele: true });
        }
      });
  };

  getInterestedData = (res) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          res.data.interestInfo.interestId
        }
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        const father = res.data.post.parentInfo.filter(
          (filter) => filter.type === "FATHER"
        );

        const mother = res.data.post.parentInfo.filter(
          (filter) => filter.type === "MOTHER"
        );

        this.setState({
          postData: res.data,
          intId: res.data.id,
          postId: res.data.post.id,
          isOffline: res.data.isOffline,
          publishedAt: res.data.likedAt
            ? res.data.likedAt
            : res.data.post.publishedAt,
          gender: res.data.post.personalInfo.gender,

          fName: res.data.post.personalInfo.fname,
          displayName: res.data.post.personalInfo.displayName,
          lnameMasked: res.data.post.personalInfo.lnameMasked,
          residentCity: res.data.post.personalInfo.residentCity,
          residentRegion: res.data.post.personalInfo.residentRegion
            ? res.data.post.personalInfo.residentRegion.regionName
            : "",
          residentRegion_si: res.data.post.personalInfo.residentRegion
            ? res.data.post.personalInfo.residentRegion.regionName_si
            : "",
          country:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.name
                : ""
              : res.data.post.personalInfo.residentCountry.name,
          country_si:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.name
                : ""
              : res.data.post.personalInfo.residentCountry.name_si,
          countryCode:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.code
                : ""
              : res.data.post.personalInfo.residentCountry.code,

          age: res.data.post.personalInfo.age,

          profession:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name,
          profession_si:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name_si,

          ethnicity: res.data.post.personalInfo.ethnicity.name,
          ethnicity_si: res.data.post.personalInfo.ethnicity.name_si,

          religion: res.data.post.personalInfo.religion.name,
          religion_si: res.data.post.personalInfo.religion.name_si,

          caste: res.data.post.personalInfo.caste,
          height: res.data.post.personalInfo.height,

          status: res.data.post.personalInfo.civilStatus.status,
          status_si: res.data.post.personalInfo.civilStatus.status_si,

          originCountry: res.data.post.personalInfo.originCountry.name,
          originCountry_si: res.data.post.personalInfo.originCountry.name_si,

          visaType: res.data.post.personalInfo.visaType.visaType,
          visaType_si: res.data.post.personalInfo.visaType.visaType_si,

          eduLevel: res.data.post.personalInfo.educationLevel.level,
          eduLevel_si: res.data.post.personalInfo.educationLevel.level_si,

          drinking: res.data.post.personalInfo.drinking,
          smoking: res.data.post.personalInfo.smoking,

          description: res.data.post.personalInfo.additionalInfo,

          foodPref: res.data.post.personalInfo.foodPreference.type,
          foodPref_si: res.data.post.personalInfo.foodPreference.type_si,

          professionF: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name
            : "",
          professionF_si: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name_si
            : "",
          ethnicityF: father[0].ethnicity.name,
          ethnicityF_si: father[0].ethnicity.name_si,
          religionF: father[0].religion.name,
          religionF_si: father[0].religion.name_si,
          fCountryOfResidency: father[0].residentCountry
            ? father[0].residentCountry.name
            : "",
          fCountryOfResidency_si: father[0].residentCountry
            ? father[0].residentCountry.name_si
            : "",
          casteF: father[0].caste,
          additionalInfoF: father[0].additionalInfo,

          professionM: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name
            : "",
          professionM_si: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name_si
            : "",
          ethnicityM: mother[0].ethnicity.name,
          ethnicityM_si: mother[0].ethnicity.name_si,
          religionM: mother[0].religion.name,
          religionM_si: mother[0].religion.name_si,
          casteM: mother[0].caste,
          mCountryOfResidency: mother[0].residentCountry
            ? mother[0].residentCountry.name
            : "",
          mCountryOfResidency_si: mother[0].residentCountry
            ? mother[0].residentCountry.name_si
            : "",
          additionalInfoM: mother[0].additionalInfo,

          likeMessage: res.data.likeSeen === false ? "" : res.data.likeMessage,
          matchMessage:
            res.data.likeSeen === false ? res.data.matchMessage : "",

          showInt: res.data.likeSeen !== undefined ? false : true,
          showAccept: res.data.likeSeen !== undefined ? true : false,
          refuseReason: res.data.refuseReason ? res.data.refuseReason : "",
          phone: res.data.post.phone,
          isIgnored: res.data.ignored,
          autoIgnored: res.data.autoIgnored,
          likedAt: res.data.likedAt,
          isHideConnect:
            res.data.timeline &&
            res.data.timeline.filter(
              (el) =>
                (el.eventType === "LIKED" && el.actionBy === "SELF") ||
                el.eventType === "LIKE_REFUSED"
            ).length > 0
              ? true
              : false,
          preLoading: false,
          showPhotos: res.data.showPhotos,
          receivedInterestImages:
            res.data.showPhotos && res.data.images ? res.data.images : [],
          children:
            res.data.personalInfo &&
            res.data.personalInfo.civilStatus &&
            res.data.personalInfo.civilStatus.status !== "Never Married" &&
            res.data.personalInfo.children
              ? res.data.personalInfo.children
              : "",
        });

        if (res.data.lastOnline) {
          this.setState({ lastOnline: res.data.lastOnline });
        }
        if (res.data.hasImages) {
          this.setState({ hasImages: res.data.hasImages });
        }
        if (res.data.hasHoroscopeData) {
          this.setState({ hasHoroscopeData: res.data.hasHoroscopeData });
        }
        if (res.data.timeline.length > 0) {
          const sentTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKED"
          );

          if (sentTime.length > 0) {
            this.setState({ sentTime: sentTime[0].eventTime });
          }

          const cancelSentTime = res.data.timeline.filter(
            (filter) => filter.eventType === "UNLIKED"
          );

          if (cancelSentTime.length > 0) {
            this.setState({
              cancelSentTime: cancelSentTime[0].eventTime,
            });
          }

          const receivedTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKED"
          );

          if (receivedTime.length > 0) {
            if (receivedTime[0].actionBy === "OTHER_PARTY") {
              this.setState({
                receivedTime: receivedTime[0].eventTime,
                receivedTo: receivedTime[0].actionBy,
              });
            }
          }

          const cancelAcceptTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKE_REFUSED"
          );

          if (cancelAcceptTime.length > 0) {
            this.setState({
              cancelAcceptTime: cancelAcceptTime[0].eventTime,
              ignoredBy: cancelAcceptTime[0].actionBy,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1006) {
          this.setState({ redirectToUnavailabele: true });
        } else if (err.response.data.code === 1004) {
          // this.props.history.push("/unauthorized-user");
          this.setState({ redirectToUnauthorizedUser: true });
        }
      });
  };

  getNonInterestedData = (res) => {
    const father = res.data.parentInfo.filter(
      (filter) => filter.type === "FATHER"
    );

    const mother = res.data.parentInfo.filter(
      (filter) => filter.type === "MOTHER"
    );

    this.setState({
      postData: res.data,
      postId: res.data.id,
      isOffline: res.data.isOffline,
      publishedAt: res.data.publishedAt,
      isExpired: res.data.isExpired,
      country:
        res.data.personalInfo.residentCountry.code === "ZZ"
          ? res.data.personalInfo.residentCountryOther
            ? res.data.personalInfo.residentCountryOther.name
            : ""
          : res.data.personalInfo.residentCountry.name,
      country_si:
        res.data.personalInfo.residentCountry.code === "ZZ"
          ? res.data.personalInfo.residentCountryOther
            ? res.data.personalInfo.residentCountryOther.name
            : ""
          : res.data.personalInfo.residentCountry.name_si,
      gender: res.data.personalInfo.gender,

      fName: res.data.personalInfo.fname,
      displayName: res.data.personalInfo.displayName,
      lnameMasked: res.data.personalInfo.lnameMasked,
      age: res.data.personalInfo.age,
      residentCity: res.data.personalInfo.residentCity,
      residentRegion: res.data.personalInfo.residentRegion
        ? res.data.personalInfo.residentRegion.regionName
        : "",
      residentRegion_si: res.data.personalInfo.residentRegion
        ? res.data.personalInfo.residentRegion.regionName_si
        : "",

      countryCode:
        res.data.personalInfo.residentCountry.code === "ZZ"
          ? res.data.personalInfo.residentCountryOther
            ? res.data.personalInfo.residentCountryOther.code
            : ""
          : res.data.personalInfo.residentCountry.code,

      description: res.data.personalInfo.additionalInfo,

      ethnicity: res.data.personalInfo.ethnicity.name,
      ethnicity_si: res.data.personalInfo.ethnicity.name_si,

      religion: res.data.personalInfo.religion.name,
      religion_si: res.data.personalInfo.religion.name_si,
      caste: res.data.personalInfo.caste,
      height: res.data.personalInfo.height,

      status: res.data.personalInfo.civilStatus.status,
      status_si: res.data.personalInfo.civilStatus.status_si,

      originCountry: res.data.personalInfo.originCountry.name,
      originCountry_si: res.data.personalInfo.originCountry.name_si,

      visaType: res.data.personalInfo.visaType.visaType,
      visaType_si: res.data.personalInfo.visaType.visaType_si,

      eduLevel: res.data.personalInfo.educationLevel.level,
      eduLevel_si: res.data.personalInfo.educationLevel.level_si,

      profession:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name,
      profession_si:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name_si,

      drinking: res.data.personalInfo.drinking,
      smoking: res.data.personalInfo.smoking,

      foodPref: res.data.personalInfo.foodPreference.type,
      foodPref_si: res.data.personalInfo.foodPreference.type_si,

      professionF: father[0].profession
        ? father[0].profession.name === "Other"
          ? father[0].professionOther
          : father[0].profession.name
        : "",
      professionF_si: father[0].profession
        ? father[0].profession.name === "Other"
          ? father[0].professionOther
          : father[0].profession.name_si
        : "",
      ethnicityF: father[0].ethnicity.name,
      ethnicityF_si: father[0].ethnicity.name_si,
      religionF: father[0].religion.name,
      religionF_si: father[0].religion.name_si,
      casteF: father[0].caste,
      fCountryOfResidency: father[0].residentCountry
        ? father[0].residentCountry.name
        : "",
      fCountryOfResidency_si: father[0].residentCountry
        ? father[0].residentCountry.name_si
        : "",
      additionalInfoF: father[0].additionalInfo,

      professionM: mother[0].profession
        ? mother[0].profession.name === "Other"
          ? mother[0].professionOther
          : mother[0].profession.name
        : "",
      professionM_si: mother[0].profession
        ? mother[0].profession.name === "Other"
          ? mother[0].professionOther
          : mother[0].profession.name_si
        : "",
      ethnicityM: mother[0].ethnicity.name,
      ethnicityM_si: mother[0].ethnicity.name_si,
      religionM: mother[0].religion.name,
      religionM_si: mother[0].religion.name_si,
      casteM: mother[0].caste,
      mCountryOfResidency: mother[0].residentCountry
        ? mother[0].residentCountry.name
        : "",
      mCountryOfResidency_si: mother[0].residentCountry
        ? mother[0].residentCountry.name_si
        : "",
      additionalInfoM: mother[0].additionalInfo,

      liked: res.data.liked ? res.data.liked : "",
      matched: res.data.matched ? res.data.matched : "",
      phone: res.data.phone,
      preLoading: false,
      children:
        res.data.personalInfo &&
        res.data.personalInfo.civilStatus &&
        res.data.personalInfo.civilStatus.status !== "Never Married" &&
        res.data.personalInfo.children
          ? res.data.personalInfo.children
          : "",
    });

    if (res.data.lastOnline) {
      this.setState({ lastOnline: res.data.lastOnline });
    }
    if (res.data.hasImages) {
      this.setState({ hasImages: res.data.hasImages });
    }
    if (res.data.hasHoroscopeData) {
      this.setState({ hasHoroscopeData: res.data.hasHoroscopeData });
    }
  };

  getMatchedData = (result) => {
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        if (res.data.length > 0) {
          this.setState({
            areThereMyPhotos: res.data[0].images.length > 0 ? true : false,
            reportedPosts: res.data[0].reportedPosts
              ? res.data[0].reportedPosts
              : [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // report reasons
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/report_reasons`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          reasons: res.data,
        });
      });

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
          result.data.interestInfo.interestId
        }
      `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        const father = res.data.post.parentInfo.filter(
          (filter) => filter.type === "FATHER"
        );

        const mother = res.data.post.parentInfo.filter(
          (filter) => filter.type === "MOTHER"
        );
        this.setState({
          isHoroscope: true,
          intId: res.data.id,
          postData: res.data,
          postId: res.data.id,
          isOffline: res.data.isOffline,
          isExpired: res.data.post.isExpired,
          matchPostId: res.data.post.id,
          matchedAt: res.data.matchedAt,
          gender: res.data.post.personalInfo.gender,
          fName: res.data.post.personalInfo.fname,
          displayName: res.data.post.personalInfo.displayName,
          lnameMasked: res.data.post.personalInfo.lnameMasked,
          lname: res.data.post.personalInfo.lname,
          residentCity: res.data.post.personalInfo.residentCity,
          residentRegion: res.data.post.personalInfo.residentRegion
            ? res.data.post.personalInfo.residentRegion.regionName
            : "",
          residentRegion_si: res.data.post.personalInfo.residentRegion
            ? res.data.post.personalInfo.residentRegion.regionName_si
            : "",
          countryCode:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.code
                : ""
              : res.data.post.personalInfo.residentCountry.code,
          country:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.name
                : ""
              : res.data.post.personalInfo.residentCountry.name,
          country_si:
            res.data.post.personalInfo.residentCountry.code === "ZZ"
              ? res.data.post.personalInfo.residentCountryOther
                ? res.data.post.personalInfo.residentCountryOther.name
                : ""
              : res.data.post.personalInfo.residentCountry.name_si,
          age: res.data.post.personalInfo.age,
          profession:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name,
          profession_si:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name_si,

          ethnicity: res.data.post.personalInfo.ethnicity.name,
          ethnicity_si: res.data.post.personalInfo.ethnicity.name_si,

          religion: res.data.post.personalInfo.religion.name,
          religion_si: res.data.post.personalInfo.religion.name_si,

          caste: res.data.post.personalInfo.caste,
          height: res.data.post.personalInfo.height,

          status: res.data.post.personalInfo.civilStatus.status,
          status_si: res.data.post.personalInfo.civilStatus.status_si,

          originCountry: res.data.post.personalInfo.originCountry.name,
          originCountry_si: res.data.post.personalInfo.originCountry.name_si,

          visaType: res.data.post.personalInfo.visaType.visaType,
          visaType_si: res.data.post.personalInfo.visaType.visaType_si,

          eduLevel: res.data.post.personalInfo.educationLevel.level,
          eduLevel_si: res.data.post.personalInfo.educationLevel.level_si,

          drinking: res.data.post.personalInfo.drinking,
          smoking: res.data.post.personalInfo.smoking,

          description: res.data.post.personalInfo.additionalInfo,

          foodPref: res.data.post.personalInfo.foodPreference.type,
          foodPref_si: res.data.post.personalInfo.foodPreference.type_si,

          likeMessage: res.data.likeMessage,
          matchMessage: res.data.matchMessage,
          professionF: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name
            : "",
          professionF_si: father[0].profession
            ? father[0].profession.name === "Other"
              ? father[0].professionOther
              : father[0].profession.name_si
            : "",
          ethnicityF: father[0].ethnicity.name,
          ethnicityF_si: father[0].ethnicity.name_si,
          religionF: father[0].religion.name,
          religionF_si: father[0].religion.name_si,
          casteF: father[0].caste,
          fCountryOfResidency: father[0].residentCountry
            ? father[0].residentCountry.name
            : "",
          fCountryOfResidency_si: father[0].residentCountry
            ? father[0].residentCountry.name_si
            : "",
          additionalInfoF: father[0].additionalInfo,

          professionM: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name
            : "",
          professionM_si: mother[0].profession
            ? mother[0].profession.name === "Other"
              ? mother[0].professionOther
              : mother[0].profession.name_si
            : "",
          ethnicityM: mother[0].ethnicity.name,
          ethnicityM_si: mother[0].ethnicity.name_si,
          religionM: mother[0].religion.name,
          religionM_si: mother[0].religion.name_si,
          casteM: mother[0].caste,
          mCountryOfResidency: mother[0].residentCountry
            ? mother[0].residentCountry.name
            : "",
          mCountryOfResidency_si: mother[0].residentCountry
            ? mother[0].residentCountry.name_si
            : "",
          additionalInfoM: mother[0].additionalInfo,

          images: res.data.post.images,
          phone: res.data.post.phone,
          showPhone: res.data.showPhone,
          email: res.data.post.email,
          horoscopeMatching: res.data.post.horoscopeMatching,
          horoscopeInfo: res.data?.post?.horoscopeInfo ?? {},
          dob: res.data.post.personalInfo.dob
            ? res.data.post.personalInfo.dob
            : "",
          showInt: res.data.timeline
            ? res.data.timeline.filter((filter) => filter.eventType === "LIKED")
                .length > 0
              ? res.data.timeline.filter(
                  (filter) => filter.eventType === "LIKED"
                )[0].actionBy === "SELF"
                ? true
                : false
              : false
            : false,
          isIgnored: res.data.ignored,
          children:
            res.data.personalInfo &&
            res.data.personalInfo.civilStatus &&
            res.data.personalInfo.civilStatus.status !== "Never Married" &&
            res.data.personalInfo.children
              ? res.data.personalInfo.children
              : "",
        });

        if (res.data.lastOnline) {
          this.setState({ lastOnline: res.data.lastOnline });
        }
        if (res.data.hasImages) {
          this.setState({ hasImages: res.data.hasImages });
        }
        if (res.data.hasHoroscopeData) {
          this.setState({ hasHoroscopeData: res.data.hasHoroscopeData });
        }

        if (res.data.post.images.length > 0) {
          for (let i = 0; i < res.data.post.images.length; i++) {
            axios
              .get(
                `${
                  process.env.REACT_APP_API_URL
                }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
                  result.data.interestInfo.interestId
                }/images/${res.data.post.images[i]}?thumbnail=false`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "auth_token"
                    )}`,
                  },
                  responseType: "blob",
                }
              )
              .then((res) => {
                let data = URL.createObjectURL(res.data);

                this.setState({
                  imageData: [...this.state.imageData, data],
                  preLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.setState({
            preLoading: false,
          });
        }

        // if (res.data.timeline.length > 0) {
        const sentTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (sentTime.length > 0) {
          if (sentTime[0].actionBy === "SELF") {
            this.setState({ sentTime: sentTime[0].eventTime });
          }
        }

        const receivedTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (receivedTime.length > 0) {
          if (receivedTime[0].actionBy === "OTHER_PARTY") {
            this.setState({
              receivedTime: receivedTime[0].eventTime,
              receivedTo: receivedTime[0].actionBy,
            });
          }
        }

        const acceptTime = res.data.timeline.filter(
          (filter) => filter.eventType === "MATCHED"
        );

        if (acceptTime.length > 0) {
          this.setState({
            acceptTime: acceptTime[0].eventTime,
            acceptBy: acceptTime[0].actionBy,
          });
        } else {
          this.setState({
            acceptTime: res.data.matchedAt ? res.data.matchedAt : "",
          });
        }

        const unmatchedTime = res.data.timeline.filter(
          (filter) => filter.eventType === "UNMATCHED"
        );

        if (unmatchedTime.length > 0) {
          this.setState({
            unmatchedTime: unmatchedTime[0].eventTime,
            unmatchedBy: unmatchedTime[0].actionBy,
          });
        }
        // }

        const cancelAcceptTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKE_REFUSED"
        );

        if (cancelAcceptTime.length > 0) {
          this.setState({
            cancelAcceptTime: cancelAcceptTime[0].eventTime,
            ignoredBy: cancelAcceptTime[0].actionBy,
          });
        }

        const undoAutoRejectTime = res.data.timeline.filter(
          (filter) => filter.eventType === "UNDO_AUTO_REJECT"
        );

        if (undoAutoRejectTime.length > 0) {
          this.setState({
            undoAutoRejectTime: undoAutoRejectTime[0].eventTime,
            undoAutoRejectedBy: undoAutoRejectTime[0].actionBy,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.message == "Network Error" ||
          err.response.status === 501 ||
          err.response.status === 502 ||
          err.response.status === 503 ||
          err.response.status === 520
        ) {
          this.setState({ outagePage: true });
        } else if (err.response.data.code === 1006) {
          this.setState({ redirectToUnavailabele: true });
        } else if (err.response.data.code === 1004) {
          // this.props.history.push("/unauthorized-user");
          this.setState({ redirectToUnauthorizedUser: true });
        } else if (err.response.data.code === 1514) {
          // this.props.history.push("/restricted");
          this.setState({ redirectToRestrictedAccount: true });
        }
      });
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  handleMessageModal = (gender) => {
    this.setState({ showIntTooltip: false, btnLoading: true });
    if (localStorage.getItem("auth_token")) {
      if (localStorage.getItem("post_details")) {
        if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "IN_REVIEW"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.state.lng
              ? "Ad Under Review"
              : "සමාලෝචනය යටතේ දැන්වීම පවති",
            alertMessage: this.state.lng
              ? "Our team is reviewing your ad at the moment. You will be able to show interest as soon as the ad goes live."
              : "අපගේ කණ්ඩායම මේ මොහොතේ ඔබගේ දැන්වීම සමාලෝචනය කරමින් සිටී. දැන්වීම සජීවීව ගිය විගස ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "PAYMENT_PENDING"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.state.lng
              ? "Complete Your Ad"
              : "ඔබේ දැන්වීම සම්පූර්ණ කරන්න",
            alertMessage: this.state.lng
              ? "Please make the payment to complete your ad. You will be able to show interest as soon as the ad goes live."
              : "කරුණාකර ඔබේ දැන්වීම සම්පුර්ණ කිරීම සඳහා ගෙවීමක් කරන්න. දැන්වීම සජීවීව ගිය වහාම ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.state.lng
              ? "Ad Expired"
              : "දැන්වීම කල් ඉකුත් වි ඇත",
            alertMessage: this.state.lng
              ? "Your ad is expired. Please renew your add to respond."
              : "දැන්වීම කල් ඉකුත් වී ඇත. ප්‍රතිචාර දැක්වීම සඳහා දැන්වීම අලුත් කරන්න.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "DISABLED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.state.lng ? "Ad Disabled" : "දැන්වීම අක්‍රීයයි",
            alertMessage: this.state.lng
              ? "You have disabled your ad. Please contact support to get your ad account re-activated."
              : "ඔබ ඔබේ දැන්වීම අක්‍රීය කර ඇත. ඔබේ දැන්වීම නැවත සක්‍රිය කිරීමට කරුණාකර අපව අමතන්න.",
          });
        } else {
          if (
            JSON.parse(localStorage.getItem("post_details")).personalInfo
              .gender === gender
          ) {
            this.setState({
              alertModal: true,
              alertHeading: this.state.lng
                ? "Response not accepted"
                : "ප්‍රතිචාරය බාර නොගනී",
              alertMessage: this.state.lng
                ? `This profile only accepts responses from ${
                    gender === "MALE" ? "bride" : "groom"
                  } profiles.`
                : `මෙම දැන්වීම පිළිගන්නේ ${
                    gender === "MALE" ? "මනාලියන්" : "මනාලයන්"
                  }ගේ ගිණුම් වලින් ලැබෙන ප්‍රතිචාර පමණි.`,
            });
          } else {
            this.interestValidate();
          }
        }
      } else {
        this.setState({
          alertModal: true,
          alertHeading: this.state.lng
            ? "Complete your ad"
            : "දැන්වීම සම්පූර්ණ කරන්න",
          alertMessage: this.state.lng
            ? "Please complete your ad to send/receive interests."
            : "ප්‍රතිචාර දැක්වීම සදහා ඔබගේ දැන්වීම සම්පූර්ණ කල යුතුය.",
        });
      }
    } else {
      this.setState({ needToCreateAnAccount: true });
    }
    // this.setState({btnLoading: false})
  };

  interestValidate = () => {
    const data = `likedPostId=${this.state.postId}`;

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/validate_interest`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ intMessageModal: true });
      })
      .catch((err) => {
        // this.setState({
        //   preferenceMismatchModal: true,
        // });

        if (err.response.data.code === 1800) {
          this.setState({
            preferenceMismatchMessage:
              "Your age does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              " ඔබේ වයස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (err.response.data.code === 1805) {
          this.setState({
            preferenceMismatchMessage:
              "Your country of residence does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ පදිංචි රට මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (
          err.response.data.code === 1802 ||
          err.response.data.code === 1803 ||
          err.response.data.code === 1804
        ) {
          this.setState({
            preferenceMismatchMessage:
              "Your civil status, ethnicity, religion or height does not match the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ සිවිල් තත්වය, ජාතිය, ආගම හෝ උස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        }
        this.showInterestOnMismatchedProfile();
      });
  };

  handleShowInterest = (id, message, showPhone, showPhotos = null) => {
    if (localStorage.getItem("auth_token")) {
      const data = `likedPostId=${id}&message=${message}&showPhone=${showPhone}&showPhoto=${showPhotos}&ignorePreferences=${this.state.ignorePreferences}`;
      this.setState({ loading: true });

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/show_interest`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            showAlert: true,
            messageModal: false,
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.code === 1801) {
            this.setState({
              sensitiveDataError: this.state.lng
                ? "Your message contains sensitive personal information such as email addresses, phone numbers or web URLs. Please remove them and retry."
                : "ඔබගේ පණිවිඩයේ විද්‍යුත් ලිපින, දුරකථන අංක හෝ වෙබ් URL වැනි සංවේදී පුද්ගලික තොරතුරු අඩංගු වේ. කරුණාකර ඒවා ඉවත් කර නැවත උත්සාහ කරන්න.",
            });
          }
          this.setState({ loading: false });
        });
    }
  };

  handleAcceptMessageModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.state.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.state.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({
        messageModal: true,
        sendingPostId: this.state.postId,
      });
    }
  };

  handleCancelReqModal = () => {
    this.setState({ cancelRequest: true });
  };

  handleIgnoreReason = (e) => {
    this.setState({ ignoreReason: e.target.value });
  };

  handleIgnoreAllInt = (type) => {
    this.setState({ loadingIgnore: true });
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }?${type === "unmatch" ? "unmatchReasonId" : "rejectReason"}=${
          this.state.ignoreReason
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loadingIgnore: false,
          cancelRequest: false,
          ignoreModal: false,
        });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingIgnore: false });
      });
  };

  handleIgnoreModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.state.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.state.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({ ignoreModal: true });
    }
  };

  handleUnmatchModal = () => {
    this.setState({ unmatchModal: true });
  };

  handleModalClose = () => {
    this.setState({
      alertModal: false,
      messageModal: false,
      intMessageModal: false,
      cancelRequest: false,
      ignoreModal: false,
      unmatchModal: false,
      btnLoading: false,
    });
  };

  handleReport = () => {
    this.setState({ reportModal: true });
  };

  handleReportOptions = (e) => {
    this.setState({ whyReport: e.target.value, isDissable: false });
  };

  handleChange = (e) => {
    this.setState({ reasonDesc: e.target.value });
  };

  // report Ad
  handleReportAd = () => {
    if (this.state.whyReport !== "") {
      this.setState({
        loading: true,
      });

      const reasonId = this.state.reasons.filter(
        (filter) => filter.reason === this.state.whyReport
      );

      const data = `reportedInterest=${this.state.postId}&reasonId=${reasonId[0].id}&reasonOther=${this.state.reasonDesc}`;

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/report_post`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: false,
            isDissable: true,
            reportModal: false,
            successAlert: true,
            whyReport: "",
            reasonDesc: "",
          });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    } else {
      this.setState({ error: true });
    }
  };

  handleReportClose = () => {
    this.setState({
      reportModal: false,
      isDissable: true,
      failAlert: false,
      successAlert: false,
      whyReport: "",
      reasonDesc: "",
    });
  };

  freeRenew = () => {
    this.setState({ loading: true });
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/free_renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((res) => {
            if (
              localStorage.getItem("user_role") === "OPERATOR" ||
              localStorage.getItem("user_role") === "OPERATOR_L2"
            ) {
              res.data[0] = res.data;
            }
            if (res.data.length > 0) {
              localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            }

            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handlePhoneVisibilityModal = () => {
    this.setState({ phoneVisibilityModal: true });
  };

  handleGivePhoneVisibility = (e) => {
    e.preventDefault();

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }/phone_visibility`,
        "showPhone=true",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ phoneVisibilityModal: false });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  phoneVisibilityModalClose = () => {
    this.setState({ phoneVisibilityModal: false });
  };

  handlePreferenceMismatchModalClose = () => {
    this.setState({ preferenceMismatchModal: false, btnLoading: false });
  };

  needToCreateAnAccountClose = () => {
    this.setState({ needToCreateAnAccount: false, btnLoading: false });
  };

  showInterestOnMismatchedProfile = () => {
    this.setState({
      preferenceMismatchModal: false,
      btnLoading: false,
      intMessageModal: true,
      ignorePreferences: true,
    });
  };

  render() {
    if (this.state.redirectToUnavailabele) {
      return <Unavailable title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else if (this.state.redirectToPageNotFound) {
      return <PageNotFound title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else if (this.state.redirectToUnauthorizedUser) {
      return <UnauthorizedUser title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else if (this.state.redirectToRestrictedAccount) {
      return <RestrictedAccount title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else if (this.state.outagePage) {
      return <Outage title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else {
      return (
        <React.Fragment>
          <PageHead title={this.state.displayName} name={` | ${Config.DOMAIN_DISPLAY_NAME}`} />
          <Navbar handleLng={this.handleLng} lng={this.state.lng} />
          <div style={{ background: "#dfdfdf" }} className="post-det-div">
            <div style={{ maxWidth: "820px", margin: "auto" }}>
              <GoogleDisplayAds slot="9743846933" />
            </div>
            <Grid
              container
              xs={12}
              sm={10}
              md={8}
              className="cus-inner-page cus-container-post"
            >
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Button
                  variant="contained"
                  className="back-btn-in-detail-pages "
                  onClick={() => this.props.history.goBack()}
                >
                  <i className="fas fa-angle-left"></i> &nbsp;
                  {this.state.lng ? "Back" : "ආපසු යන්න"}
                </Button>
              )}
              <NotLoggedBanner lng={this.state.lng} />
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Header
                  lng={this.state.lng}
                  isMyProfile={false}
                  applyMatch={this.state.applyMatch}
                  preLoading={this.state.preLoading}
                  createdBy={this.state.createdBy}
                  fName={this.state.fName}
                  lName={this.state.lname}
                  lnameMasked={this.state.lnameMasked}
                  gender={this.state.gender}
                  age={this.state.age}
                  height={this.state.height}
                  residentCity={this.state.residentCity}
                  profession={this.state.profession}
                  profession_si={this.state.profession_si}
                  countryCode={this.state.countryCode}
                  country={this.state.country}
                  country_si={this.state.country_si}
                  interestInfo={this.state.interestInfo}
                  description={this.state.description}
                  images={this.state.images}
                  imageData={this.state.imageData}
                  areThereMyPhotos={this.state.areThereMyPhotos}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  cancelSentTime={this.state.cancelSentTime}
                  unmatchedTime={this.state.unmatchedTime}
                  loading={this.state.loading}
                  differentlyAbled={this.state.differentlyAbled}
                  nicVerified={this.state.nicVerified}
                  displayName={this.state.displayName}
                  isFeatured={this.state.isFeatured}
                  isMyAdFeatured={this.state.postData.isFeatured}
                  showPhotos={this.state.showPhotos}
                  receivedInterestImages={this.state.receivedInterestImages}
                  messageModal={this.state.messageModal}
                  postId={this.state.postId}
                  // lastOnline={this.state.lastOnline}
                />
              )}

              {!this.state.intMessageModal && !this.state.messageModal && (
                <Alerts
                  lng={this.state.lng}
                  isFeatured={this.state.isFeatured}
                  lastOnline={this.state.lastOnline}
                  hasImages={this.state.hasImages}
                  applyMatch={this.state.applyMatch}
                  hasHoroscopeData={this.state.hasHoroscopeData}
                  preLoading={this.state.preLoading}
                />
              )}
              {!this.state.isHideConnect && (
                <Connect
                  applyMatch={this.state.applyMatch}
                  lng={this.state.lng}
                  preLoading={this.state.preLoading}
                  phone={this.state.phone}
                  isExpired={this.state.isExpired}
                  showPhone={this.state.showPhone}
                  offlineId={this.state.offlineId}
                  email={this.state.email}
                  phoneVisibilityModal={this.state.phoneVisibilityModal}
                  handlePhoneVisibilityModal={this.handlePhoneVisibilityModal}
                  handleGivePhoneVisibility={this.handleGivePhoneVisibility}
                  phoneVisibilityModalClose={this.phoneVisibilityModalClose}
                  isMyProfile={false}
                  fName={this.state.fName}
                  lnameMasked={this.state.lnameMasked}
                  gender={this.state.gender}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  cancelSentTime={this.state.cancelSentTime}
                  unmatchedTime={this.state.unmatchedTime}
                  applyInterest={this.state.applyInterest}
                  applyNormalPost={this.state.applyNormalPost}
                  isInterestInfo={this.state.isInterestInfo}
                  alertModal={this.state.alertModal}
                  alertHeading={this.state.alertHeading}
                  alertMessage={this.state.alertMessage}
                  preferenceMismatchModal={this.state.preferenceMismatchModal}
                  showInterestOnMismatchedProfile={
                    this.showInterestOnMismatchedProfile
                  }
                  preferenceMismatchMessage={
                    this.state.preferenceMismatchMessage
                  }
                  handlePreferenceMismatchModalClose={
                    this.handlePreferenceMismatchModalClose
                  }
                  handleMessageModal={this.handleMessageModal}
                  handleModalClose={this.handleModalClose}
                  postId={this.state.postId}
                  matchPostId={this.state.matchPostId}
                  messageModal={this.state.messageModal}
                  intMessageModal={this.state.intMessageModal}
                  handleShowInterest={this.handleShowInterest}
                  showAccept={this.state.showAccept}
                  isIgnored={this.state.isIgnored}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  handleIgnoreModal={this.handleIgnoreModal}
                  ignoreModal={this.state.ignoreModal}
                  ignoreReason={this.state.ignoreReason}
                  handleIgnoreReason={this.handleIgnoreReason}
                  loadingIgnore={this.state.loadingIgnore}
                  handleAcceptMessageModal={this.handleAcceptMessageModal}
                  sendingPostId={this.state.sendingPostId}
                  commingMsg={this.state.matchMessage}
                  sensitiveDataError={this.state.sensitiveDataError}
                  loading={this.state.loading}
                  showIntTooltip={this.state.showIntTooltip}
                  displayName={this.state.displayName}
                  btnLoading={this.state.btnLoading}
                  likeMessage={this.state.likeMessage}
                  showPhotos={this.state.showPhotos}
                  receivedInterestImages={this.state.receivedInterestImages}
                  intId={this.state.intId}
                  height={this.state.height}
                  age={this.state.age}
                  profession={this.state.profession}
                  profession_si={this.state.profession_si}
                  residentCity={this.state.residentCity}
                  preferenceMismatchMessageSinhala={
                    this.state.preferenceMismatchMessageSinhala
                  }
                />
              )}

              {this.state.isInterestInfo && !this.state.messageModal && (
                <TimeLine
                  lng={this.state.lng}
                  fName={this.state.fName}
                  preLoading={this.state.preLoading}
                  likeMessage={this.state.likeMessage}
                  matchMessage={this.state.matchMessage}
                  showInt={this.state.showInt}
                  isIgnored={this.state.isIgnored}
                  autoIgnored={this.state.autoIgnored}
                  refuseReason={this.state.refuseReason}
                  likedAt={this.state.likedAt}
                  cancelSentTime={this.state.cancelSentTime}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  ignoredBy={this.state.ignoredBy}
                  unmatchedTime={this.state.unmatchedTime}
                  unmatchedBy={this.state.unmatchedBy}
                  acceptTime={this.state.acceptTime}
                  matchedAt={this.state.matchedAt}
                  acceptBy={this.state.acceptBy}
                  receivedTo={this.state.receivedTo}
                  sentTime={this.state.sentTime}
                  receivedTime={this.state.receivedTime}
                  applyMatch={this.state.applyMatch}
                  applyInterest={this.state.applyInterest}
                  handleCancelReqModal={this.handleCancelReqModal}
                  unmatchModal={this.state.unmatchModal}
                  handleUnmatchModal={this.handleUnmatchModal}
                  handleModalClose={this.handleModalClose}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  loadingIgnore={this.state.loadingIgnore}
                  isMyProfile={false}
                  matchPostId={this.state.matchPostId}
                  reportedPosts={this.state.reportedPosts}
                  reasons={this.state.reasons}
                  handleChange={this.handleChange}
                  handleReport={this.handleReport}
                  reportModal={this.state.reportModal}
                  whyReport={this.state.whyReport}
                  handleReportAd={this.handleReportAd}
                  handleReportOptions={this.handleReportOptions}
                  handleReportClose={this.handleReportClose}
                  error={this.state.error}
                  undoAutoRejectTime={this.state.undoAutoRejectTime}
                  undoAutoRejectedBy={this.state.undoAutoRejectedBy}
                  displayName={this.state.displayName}
                />
              )}

              {/* <div style={{width: '100%', marginBottom: '16px'}}><GoogleInfeedAds slot="7432721829"/></div> */}

              {!this.state.intMessageModal && !this.state.messageModal && (
                <Personal
                  lng={this.state.lng}
                  preLoading={this.state.preLoading}
                  ethnicity={this.state.ethnicity}
                  ethnicity_si={this.state.ethnicity_si}
                  religion={this.state.religion}
                  religion_si={this.state.religion_si}
                  age={this.state.age}
                  status={this.state.status}
                  status_si={this.state.status_si}
                  country={this.state.country}
                  country_si={this.state.country_si}
                  visaType={this.state.visaType}
                  visaType_si={this.state.visaType_si}
                  residentRegion={this.state.residentRegion}
                  residentRegion_si={this.state.residentRegion_si}
                  residentCity={this.state.residentCity}
                  caste={this.state.caste}
                  height={this.state.height}
                  eduLevel={this.state.eduLevel}
                  eduLevel_si={this.state.eduLevel_si}
                  profession={this.state.profession}
                  profession_si={this.state.profession_si}
                  drinking={this.state.drinking}
                  smoking={this.state.smoking}
                  foodPref={this.state.foodPref}
                  foodPref_si={this.state.foodPref_si}
                  horoscopeMatching={this.state.horoscopeMatching}
                  children={this.state.children}
                />
              )}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Parent
                  lng={this.state.lng}
                  preLoading={this.state.preLoading}
                  professionF={this.state.professionF}
                  professionF_si={this.state.professionF_si}
                  ethnicityF={this.state.ethnicityF}
                  ethnicityF_si={this.state.ethnicityF_si}
                  religionF={this.state.religionF}
                  religionF_si={this.state.religionF_si}
                  casteF={this.state.casteF}
                  fCountryOfResidency={this.state.fCountryOfResidency}
                  fCountryOfResidency_si={this.state.fCountryOfResidency_si}
                  additionalInfoF={this.state.additionalInfoF}
                  professionM={this.state.professionM}
                  professionM_si={this.state.professionM_si}
                  ethnicityM={this.state.ethnicityM}
                  ethnicityM_si={this.state.ethnicityM_si}
                  religionM={this.state.religionM}
                  religionM_si={this.state.religionM_si}
                  casteM={this.state.casteM}
                  mCountryOfResidency={this.state.mCountryOfResidency}
                  mCountryOfResidency_si={this.state.mCountryOfResidency_si}
                  additionalInfoM={this.state.additionalInfoM}
                  siblings={this.state.siblingsInfo}
                />
              )}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Horoscope
                  lng={this.state.lng}
                  preLoading={this.state.preLoading}
                  intId={this.state.intId}
                  horoscopeInfo={this.state.horoscopeInfo}
                  dob={this.state.dob}
                  interestInfo={this.state.interestInfo}
                  originCountry={this.state.originCountry}
                  originCountry_si={this.state.originCountry_si}
                  horoscopeMatching={this.state.horoscopeMatching}
                  applyMatch={this.state.applyMatch}
                />
              )}
            </Grid>
            <GoogleAds slot="6370303041" />
          </div>
          <div className="create-floor-footer-desktop">
            <Footer lng={this.state.lng} />
          </div>
          <div className="create-floor-footer-mobile">
            <FooterMobile lng={this.state.lng} />
          </div>

          <CancelRequestModal
            lng={this.state.lng}
            cancelRequest={this.state.cancelRequest}
            handleModalClose={this.handleModalClose}
            handleIgnoreAllInt={this.handleIgnoreAllInt}
            loadingIgnore={this.state.loadingIgnore}
          />

          <NeedToCreateAnAccount
            lng={this.state.lng}
            needToCreateAnAccount={this.state.needToCreateAnAccount}
            needToCreateAnAccountClose={this.needToCreateAnAccountClose}
          />
        </React.Fragment>
      );
    }
  }
}

export default withRouter(postDetails);
