export const getInnerHeight = (id, amount) => {
  // Handler to call on window resize
  function handleResize() {
    if (window.innerWidth < 575) {
      document.getElementById(id).style.height = `${window.innerHeight - amount}px`;
    } else if (window.innerWidth < 1200 && window.innerWidth > 575) {
      if(id === "message-content"){
        document.getElementById(id).style.height = `${window.innerHeight - amount + 49}px`;
      } else {
        document.getElementById(id).style.height = `${window.innerHeight - amount}px`;
      }
    } else {
      document.getElementById(id).style.height = "";
    }
  }

  // Add event listener
  window.addEventListener("resize", handleResize);

  // Call handler right away so state gets updated with initial window size
  handleResize();

  // Remove event listener on cleanup
  return () => window.removeEventListener("resize", handleResize);
};
