import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/common/Navbar";
import PreferenceMismatchedInterestSideView from "../components/my-matches/PreferenceMismatchedInterestSideView";
import MessageView from "../components/my-matches/InterestMessageView";
import InterestProfile from "../components/my-matches/InterestProfile";
import InactiveProfile from "../components/my-matches/InactiveProfile";
import ReceivedInterest from "../components/my-matches/ReceivedInterestProfile";
import UnautorizedProfile from "../components/my-matches/interest-com/UnautorizedProfile";
import IncorrectToken from "../components/my-matches/interest-com/IncorrectToken";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import { handleIntBtns } from "../actions/handleIntBtns";
import { getInterestPosts } from "../actions/getInterestPosts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/my-matches.css";
import Config from "../v2/config";

const PreferenceMismatchInterests = (props) => {
  const [lng, setLng] = useState(true);
  const [preLoading, setPreLoading] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [post, setPost] = useState([]);
  const [inactivePost, setInactivePost] = useState(false);

  useEffect(() => {
    // props.getInterestPosts();
  }, []);

  useEffect(() => {
    // props.getInterestPosts();
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    setSelectedId(id);
  });

  // useEffect(() => {
  //   props.getInterestPosts();
  // }, [selectedId]);

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    if (window.ws) {
      window.ws.close();
    }

    // chat_token
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("chat_token", res.data.token);
        sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

        // openWS(res.data.token);
      })
      .catch((err) => console.log(err));

    setLng(
      localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true
    );
  }, []);

  const handleLng = () => {
    setLng((lng) => !lng);
    localStorage.setItem("lng", !lng);
  };

  return isAccessDenied ? (
    <Unauthorize title={`Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div kk">
          <PreferenceMismatchedInterestSideView
            // posts={props.sideMenuInterests}
            lng={lng}
            intId={selectedId}
          />
          {!inactivePost && selectedId && (
            <ReceivedInterest lng={lng} intId={selectedId} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ getInterestPosts, handleIntBtns }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    sideMenuInterests: state.matches.sideMenuInterests.interests,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(PreferenceMismatchInterests);
