import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import Reset from "../components/password-reset/Reset";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

export default class PaymentForAd extends Component {
  state = {
    lng: true, // default en
  };

  componentDidMount = () => {
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Reset Password | ${Config.DOMAIN_DISPLAY_NAME}`} />

        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
          >
            <Reset align="center" lng={this.state.lng} />
          </Grid>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}
