import FormMeta from "../../../meta/post-filter-bar.json";
import { isEmptyOrNullOrUndefined } from "../../../utils";
import isEqual from "lodash/isEqual";

export const constructSearchParamsByValues = ({ values }) => {
  const searchParams = new URLSearchParams();
  for (const currentMeta of Object.values(FormMeta)) {
    const { fieldName, paramExtractor } = currentMeta;
    if (
      isEmptyOrNullOrUndefined(fieldName) ||
      isEmptyOrNullOrUndefined(paramExtractor) ||
      isEmptyOrNullOrUndefined(values[fieldName])
    ) {
      continue;
    }
    let value = values[fieldName];
    if (Array.isArray(value)) {
      value = value.join(",");
    }
    searchParams.append(paramExtractor, value);
  }
  return searchParams;
};

export const sanitizeSearchFilterValues = ({ values }) => {
  const output = {};
  for (const currentMeta of Object.values(FormMeta)) {
    const { fieldName, defaultValue } = currentMeta;
    let fieldValue = values[fieldName];
    if (isEmptyOrNullOrUndefined(fieldValue)) {
      continue;
    }
    // handling default value
    if (!isEmptyOrNullOrUndefined(defaultValue)) {
      const ignoredFields = [
        FormMeta.pageNum.fieldName,
        FormMeta.pageSize.fieldName,
      ];
      if (!ignoredFields.includes(fieldName)) {
        switch (fieldName) {
          case FormMeta.minAge.fieldName:
          case FormMeta.maxAge.fieldName:
            if (typeof fieldValue === "string") {
              fieldValue = parseInt(fieldValue);
            }
            break;
          case FormMeta.minHeight.fieldName:
          case FormMeta.maxHeight.fieldName:
            if (typeof fieldValue === "string") {
              fieldValue = parseFloat(fieldValue);
            }
            break;
          case FormMeta.showInterests.fieldName:
            fieldValue = JSON.stringify(fieldValue);
            break;
          default:
            break;
        }
        const ageLimit = [FormMeta.minAge.fieldName, FormMeta.maxAge.fieldName];

        if (ageLimit.includes(fieldName)) {
          if (!isEqual(fieldValue, defaultValue)) {
            ageLimit.forEach((element) => {
              output[element] = values[element];
            });
          }
        } else {
          if (!isEqual(fieldValue, defaultValue)) {
            output[fieldName] = fieldValue;
          }
        }
      } else {
        output[fieldName] = fieldValue;
      }
    } else {
      // resident region handling
      if (fieldName === FormMeta.regionOrDistrict.fieldName) {
        const countries = values[FormMeta.residentCountries.fieldName];
        if (
          !isEmptyOrNullOrUndefined(countries) &&
          Array.isArray(countries) &&
          countries.length === 1
        ) {
          output[fieldName] = fieldValue;
        }
      }
      // handling communities
      else if (fieldName === FormMeta.communities.fieldName) {
        const religions = values[FormMeta.religions.fieldName];
        if (
          !isEmptyOrNullOrUndefined(religions) &&
          Array.isArray(religions) &&
          religions.length === 1
        ) {
          output[fieldName] = fieldValue;
        }
      }
      // handling remainings
      else {
        output[fieldName] = fieldValue;
      }
    }
  }

  return output;
};

export const constructFilterCriteriaBySearchParams = ({ searchParams }) => {
  const criteria = {};
  for (const fieldMeta of Object.values(FormMeta)) {
    if (
      isEmptyOrNullOrUndefined(fieldMeta.fieldName) ||
      isEmptyOrNullOrUndefined(fieldMeta.paramExtractor) ||
      !searchParams.has(fieldMeta.paramExtractor)
    ) {
      continue;
    }
    let paramValue = searchParams.get(fieldMeta.paramExtractor);
    switch (fieldMeta.type) {
      case "array":
        paramValue = paramValue.split(",");
        switch (fieldMeta.arrayType) {
          case "number":
            paramValue = paramValue.map((current) => JSON.parse(current));
            break;
          case "string":
          default:
            break;
        }
        break;
      case "boolean":
      case "number":
        paramValue = JSON.parse(paramValue);
        break;
      default:
        break;
    }
    criteria[fieldMeta.fieldName] = paramValue;
  }
  return criteria;
};
