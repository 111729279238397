import Config from "../../config";
import { OTHER_COUNTRY_CODE } from "../../config/constant";
import { isEmpty, capitalize, isEqual } from "lodash";

export const convertFeetInchesToCentimeters = (input) => {
  const output = input.feet * 30.48 + input.inches * 2.54;
  return output;
};

export const convertCentimetersToFeetInches = (input) => {
  const accurateFeets = (input * 0.3937) / 12;
  const feet = Math.floor(accurateFeets);
  const inches = Math.round((accurateFeets - feet) * 12);
  return { feet, inches };
};

export const generateHeightDropdownOptions = ({ from, to }) => {
  const output = [];
  let hasNextValue = true;
  const iterator = { ...from };
  while (hasNextValue) {
    const currentCm = convertFeetInchesToCentimeters(iterator);
    const roundedCm = Math.round(currentCm);
    const currentOption = {
      id: Math.round(currentCm).toString(),
      name: `${iterator.feet}ft ${iterator.inches}in (${roundedCm}cm)`,
    };
    output.push(currentOption);
    iterator.inches = iterator.inches + 1;
    const shouldIncrementFeet =
      iterator.inches !== 0 && iterator.inches % 12 === 0;
    if (shouldIncrementFeet) {
      iterator.feet = iterator.feet + 1;
      iterator.inches = 0;
    }
    if (iterator.feet === to.feet && iterator.inches === to.inches) {
      hasNextValue = false;
    }
  }
  return output;
};

export const capitalizeEachWord = (input) => {
  if (typeof input !== "string") {
    return input;
  }
  let output = input
    .trim()
    .split(" ")
    .map((current) => capitalize(current))
    .join(" ");
  return output;
};

export const generateDisplayNameView = ({
  displayNameFormat,
  firstname,
  lastname,
}) => {
  if (
    typeof displayNameFormat !== "string" ||
    firstname === undefined ||
    lastname === undefined
  ) {
    return "";
  }

  let output = "";
  let fname = capitalizeEachWord(firstname);
  let lname = capitalizeEachWord(lastname);

  switch (displayNameFormat) {
    case "FIRST":
      output = fname;
      break;
    case "LAST":
      output = lname;
      break;
    case "FIRST_LAST":
      output = `${fname} ${lname}`;
      break;
    case "FIRST_LAST_CHAR1":
      output = `${fname} ${lname[0] ?? ""}`;
      break;
    case "FIRST_CHAR1_LAST":
      output = `${fname[0] ?? ""} ${lname}`;
      break;
    default:
      output = "";
      break;
  }

  return output;
};

export const centimertsIntoFeetInches = (heightInCm) => {
  let height = "";
  if (heightInCm !== undefined && heightInCm !== "") {
    const totalInches = Math.round(heightInCm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;

    height = `${feet}' ${inches}"`;
  }
  return height;
};

const generateExtractionKeyByLang = (lang, extractionKey) => {
  const isEnglish = lang === Config.APP_LANGS.EN;
  return isEnglish ? extractionKey : `${extractionKey}_${lang}`;
};

const generateDisplayValueByMeta = (meta, extractionKey, lang) => {
  return isEmpty(meta)
    ? ""
    : meta[generateExtractionKeyByLang(lang, extractionKey)];
};

export const getResidentCountry = (personalInfo, lang) => {
  let country = "";
  if (personalInfo.residentCountry?.code === OTHER_COUNTRY_CODE) {
    country = personalInfo.residentCountryOther?.name;
  } else {
    country = generateDisplayValueByMeta(
      personalInfo.residentCountry,
      "name",
      lang
    );
  }
  return country;
};

export const getResidentCountryCode = (personalInfo) => {
  return personalInfo?.residentCountry?.code === OTHER_COUNTRY_CODE
    ? personalInfo?.residentCountryOther?.code
    : personalInfo?.residentCountry?.code;
};

export const getLocaleLabelByFieldMeta = ({
  fieldMeta,
  language,
  fallbackKey = "",
  input,
}) => {
  let labelExtractor = fieldMeta.labelExtractor ?? fallbackKey;
  if (language !== Config.APP_LANGS.EN) {
    labelExtractor = `${labelExtractor}_${language}`;
  }
  if (!isEmpty(input) && input.constructor === Object) {
    return input[labelExtractor];
  }

  return undefined;
};

export const transformNameFormatsByName = ({
  nameFormats,
  firstname,
  lastname,
}) => {
  const output = nameFormats.map((current) => {
    const transformedValue = generateDisplayNameView({
      displayNameFormat: current.value,
      firstname,
      lastname,
    });
    return {
      ...current,
      label: transformedValue,
    };
  });
  return output;
};

export const hasChangedFormValue = ({ meta, values, initialValues }) => {
  const fieldName = meta.fieldName;
  const value = values[fieldName];
  const oldValue = initialValues[fieldName];
  return !isEqual(value, oldValue);
};

export const getLocaleLabelExtractorByMeta = ({ fieldMeta, language }) => {
  let labelExtractor = fieldMeta.labelExtractor;
  if (language !== Config.APP_LANGS.EN) {
    labelExtractor = `${labelExtractor}_${language}`;
  }
  return labelExtractor;
};
