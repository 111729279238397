import {
  SET_EDIT_AD_PAGE_ADVERTISEMENT,
  SET_EDIT_AD_PAGE_COUNTRY_REGIONS,
  SET_EDIT_AD_PAGE_HOROSCOPE_IMAGES,
  SET_EDIT_AD_PAGE_NOTIFICATION,
  SET_EDIT_AD_PAGE_OLD_SHADOW,
  SET_EDIT_AD_PAGE_PROFILE_IMAGES,
  SET_EDIT_AD_PAGE_BOOSTRAPPED,
  SET_EDIT_AD_COMMUNITIES,
  SET_EDIT_AD_SHOW_SIBILING_FORM,
} from "../../actions/edit-ad-page";

const initialState = {
  boostrapped: false,
  advertisement: {},
  profileImages: [],
  horoscopeImages: [],
  countryRegions: [],
  communities: [],
  oldShadow: {},
  notification: {},
  showEditAdForm: false,
};

const editAdPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EDIT_AD_PAGE_ADVERTISEMENT:
      return { ...state, advertisement: payload };
    case SET_EDIT_AD_PAGE_PROFILE_IMAGES:
      return { ...state, profileImages: payload };
    case SET_EDIT_AD_PAGE_HOROSCOPE_IMAGES:
      return { ...state, horoscopeImages: payload };
    case SET_EDIT_AD_PAGE_COUNTRY_REGIONS:
      return { ...state, countryRegions: payload };
    case SET_EDIT_AD_PAGE_OLD_SHADOW:
      return { ...state, oldShadow: payload };
    case SET_EDIT_AD_PAGE_NOTIFICATION:
      return { ...state, notification: payload };
    case SET_EDIT_AD_PAGE_BOOSTRAPPED:
      return { ...state, boostrapped: payload };
    case SET_EDIT_AD_COMMUNITIES:
      return { ...state, communities: payload };
    case SET_EDIT_AD_SHOW_SIBILING_FORM:
      return { ...state, showEditAdForm: payload };
    default:
      return state;
  }
};

export default editAdPageReducer;
