import { sanitizeEmptyNullUndefinedValues } from "../../../utils";
import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
  ResponseType,
} from "../../../utils/http-adapter";
import { v4 as UUIDv4 } from "uuid";

const UserApi = {
  postUserAnalyticsCookies: async ({ cookies }) => {
    return await httpAdapter.request({
      method: HttpMethod.PUT,
      apiVersion: ApiVersion.V1,
      path: "account/analytics_ids",
      authneticated: true,
      body: cookies,
      contentType: ContentType.FORM_URL_ENCODED,
      responseType: ResponseType.JSON,
    });
  },
  postUserReviews: async ({ payload }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/reviews",
      authneticated: true,
      body: payload,
      contentType: ContentType.FORM_URL_ENCODED,
      responseType: ResponseType.JSON,
    });
  },
  getUserAccount: async () =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "account",
      authneticated: true,
    }),
  getUserProfiles: async () =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "account/posts",
      authneticated: true,
    }),
  getUserTempProfile: async () =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "account/posts/temp",
      authneticated: true,
    }),
  postUserTempProfile: async ({ tempProfile }) => {
    sanitizeEmptyNullUndefinedValues(tempProfile);
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/posts/temp",
      authneticated: true,
      body: tempProfile,
      contentType: ContentType.JSON,
    });
  },
  postUserPreferences: async ({ preferences }) =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/preferences",
      authneticated: true,
      body: preferences,
      contentType: ContentType.JSON,
    }),
  postUserEmail: async ({ email }) =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/update_email",
      authneticated: true,
      body: { email },
      contentType: ContentType.FORM_URL_ENCODED,
    }),
  removeUserEmail: async () =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "/account/remove_email",
      authneticated: true,
      contentType: ContentType.JSON,
    }),
  postUserAddress: async ({ addressFields }) =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/update_address",
      authneticated: true,
      body: addressFields,
      contentType: ContentType.FORM_URL_ENCODED,
    }),
  postUserProfile: async ({ tempProfile }) => {
    let idopotencyKey = localStorage.getItem("x-request-id");
    if (idopotencyKey === null) {
      idopotencyKey = UUIDv4();
      localStorage.setItem("x-request-id", idopotencyKey);
    }
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/posts",
      authneticated: true,
      body: tempProfile,
      contentType: ContentType.JSON,
      headers: { "x-request-id": idopotencyKey },
    });
  },
  getTempProfileImage: async ({ id }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/temp_images/${id}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPublicPost: async ({ id, context }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `posts/${id}?context=${context}`,
      authneticated: true,
    });
  },
  getPostImages: async ({ postId, imageId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/images/${imageId}?thumbnail=false`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPostHoroscopeImages: async ({ postId, imageId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/horoscope_images/${imageId}?thumbnail=false`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPreferredSearchCriteriaByOperator: async ({ postId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `operator/managed_posts/${postId}/owner`,
      authneticated: true,
    });
  },
  updatePost: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.PUT,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}`,
      body: data,
      authneticated: true,
      contentType: ContentType.JSON,
    });
  },
  getOperatorManagedProfile: async ({ postId }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `operator/managed_posts/${postId}`,
      authneticated: true,
    }),
  createPaymentSession: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/checkout_tiered`,
      body: data,
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
    });
  },
  createPaymentSessionForTopAd: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/featured_posts/checkout`,
      body: data,
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
    });
  },
  postSibling: async ({ postId, sibling }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      authneticated: true,
      path: `account/posts/${postId}/siblings`,
      body: sibling,
      contentType: ContentType.JSON,
    });
  },
  deleteSibling: async ({ postId, siblingId }) => {
    return await httpAdapter.request({
      method: HttpMethod.DELETE,
      apiVersion: ApiVersion.V1,
      authneticated: true,
      path: `account/posts/${postId}/siblings/${siblingId}`,
    });
  },
  postEditRequest: async ({ postId, editRequest }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/edit_request`,
      authneticated: true,
      body: editRequest,
      contentType: ContentType.JSON,
    });
  },
};

export default UserApi;
