import React, { Component } from 'react';
import "../../styles/google-ads.css";

class GoogleDisplayAds extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

    render() {
      return (
        <ins
          className='adsbygoogle'
          style={{ display: 'block', textAlign: 'center', minWidth: '400px'}}
          data-ad-client= 'ca-pub-2370761850448977'
          data-ad-slot={this.props.slot}
          data-ad-format= 'fluid'
          // data-full-width-responsive= "true"
          data-ad-layout-key="-fb+5w+4e-db+86"
          // data-adtest="on"
        >
        </ins>
      );
    }
}

export default GoogleDisplayAds;