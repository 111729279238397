import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "./store";
import { setIsOutsage } from "./actions/globalActions";
import { setIsNetwork } from "./actions/globalNetwork";
import { logErrors } from "./components/log-errors/logErrors";
import UserApi from "./v2/services/api/user";

import Home from "./pages/Home";
import PostDetails from "./pages/PostDetails";
import RedirectIntPost from "./pages/RedirectIntPost";
import MyMatches from "./pages/MyMatches";
import InterestsMatched from "./pages/InterestsMatched";
import InterestsSent from "./pages/InterestsSent";
import RedirectMatchPost from "./pages/RedirectMatchPost";
import MyAdd from "./pages/MyAdd";
// import EditAd from "./pages/EditAd";
import Settings from "./pages/Settings";
import PartnerPref from "./pages/PartnerPref";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Pricing from "./pages/Pricing";
import Magazine from "./pages/Magazine";
import Login from "./pages/Login";
// import Register from "./pages/Register";
import RegisterNew from "./pages/RegisterNew";
import OTPLogin from "./pages/OTPLogin";
// import CompletePersonal from "./pages/CompletePersonal";
// import CompleteParent from "./pages/CompleteParent";
// import CompletePrivate from "./pages/CompletePrivate";
// import CompletePay from "./pages/CompletePay";
import PaymentLink from "./pages/PaymentLink";
import InvalidPaymentLink from "./pages/InvalidPaymentLink";
import PaymentForAd from "./pages/PaymentForAd";
import Checkout from "./pages/Checkout";
import BankTransfer from "./pages/BankTransfer";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentError from "./pages/PaymentError";
import EmailActivation from "./pages/EmailActivation";
import EmailAlreadyActivated from "./pages/EmailAlreadyActivated";
import PasswordReset from "./pages/PasswordReset";
import DisabledAcc from "./pages/DisabledAcc";
import Unauthorize from "./pages/Unauthorize";
import RestrictedProfile from "./pages/RestrictedProfile";
import IncorrectToken from "./pages/IncorrectToken";
import PageNotFound from "./pages/PageNotFound";
import ReviewComments from "./pages/ReviewComments";

import { isAuthenticated } from "./auth/auth";
import Unsubscribed from "./pages/Unsubscribed";
import PaymentForTopAd from "./pages/PaymentForTopAd";
import TopAdCheckout from "./pages/TopAdCheckout";
import TopAdBankTransfer from "./pages/TopAdBankTransfer";
import TopAdPaymentStatus from "./pages/TopAdPaymentStatus";
import CompleteYourAd from "./pages/CompleteYourAd";
import ManagePosts from "./pages/ManagePosts";
import Outage from "./pages/Outage";
import NetworkError from "./pages/NetworkError";
import Messages from "./pages/Messages";
import Interests from "./pages/Interests";
import PreferenceMismatchInterests from "./pages/PreferenceMismatchInterests";
import SentInterests from "./pages/SentInterests";
import Reviews from "./pages/Reviews";
import SampleDatePicker from "./pages/SampleDatePicker";
import FAQ from "./pages/FAQ";
import Help from "./pages/Help";
import AppContext from "./v2/context";

import { extractAnalyticsCookies, serializeCookie } from "./v2/utils/extractor";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PersonalCreateAccount from "./v2/pages/create-flow/personal-create-account";
import Constants from "./v2/config/constant";
import LocaleHandler from "./v2/utils/locale-handler";
import { initializedGenericOptions } from "./v2/features/generic-data";
import ParentsCreateAccount from "./v2/pages/create-flow/parents-create-account";
import PrivateCreateAccount from "./v2/pages/create-flow/private-create-account";
import ReviewAndPayCreateAccount from "./v2/pages/create-flow/review-and-pay";
import { initializeAuth, initializeConfig } from "./v2/features/application";
import Config from "./v2/config";
import ViewMyAd from "./v2/pages/view-my-ad";
import post from "./v2/pages/post";
import PricingPageIn from "./v2/pages/pricing";
import EditMyAd from "./v2/pages/edit-my-ad";
import PrivacyPolicyPageIn from "./v2/pages/privacy-policy-page";
import TermsAndConditionsPageIn from "./v2/pages/terms-and-conditions-page";
import HelpPageIn from "./v2/pages/help-page";
import FaqPageIn from "./v2/pages/faq-page";
import NotificationBar from "./v2/pages/create-flow/templates/notification-bar";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

const AppContainer = withRouter(({ children, history, ...restProps }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { snackNotification } = useSelector((state) => state.application);
  const location = useLocation();
  // TODO: Refacor this code when revamping OTP Login
  const { authenticated, initialized } = useSelector(
    (state) => state.application
  );
  const [previousLocation, setPreviousLocation] = useState({});
  const previouseLocationRef = useRef({});

  useEffect(() => {
    const listener = history.block((location, _action) => {
      const previousLocation = previouseLocationRef.current;
      if (isEmpty(previousLocation)) {
        return;
      }
      // Block same route navigation
      if (isEqual(location.pathname, previousLocation.pathname)) {
        // Check same route query params
        const shouldBlock = !isEqual(location.search, previousLocation.search);
        return shouldBlock;
      }
      return true;
    });

    return () => {
      listener();
    };
  });

  useEffect(() => {
    if (isEqual(location, previousLocation)) {
      return;
    }
    const authToken = localStorage.getItem("auth_token");
    if (
      previousLocation.pathname === "/auth" &&
      authToken !== null &&
      !authenticated &&
      initialized
    ) {
      dispatch(initializeAuth());
    }
    setPreviousLocation(location);
    previouseLocationRef.current = location;
  }, [location, previousLocation, authenticated, initialized, dispatch]);

  return (
    <>
      {children}
      <NotificationBar
        severity={snackNotification.severity}
        message={snackNotification.message}
      />
    </>
  );
});

// const ParentRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       localStorage.getItem("auth_token") &&
//       localStorage.getItem("personalInfo") &&
//       !localStorage.getItem("postId") ? (
//         <Component {...props} />
//       ) : (
//         props.history.push("/complete-ad-personal")
//       )
//     }
//   />
// );

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       localStorage.getItem("auth_token") &&
//       localStorage.getItem("parentInfo") &&
//       !localStorage.getItem("postId") ? (
//         <Component {...props} />
//       ) : (
//         props.history.push("/complete-ad-parent")
//       )
//     }
//   />
// );

// const ReviewRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       localStorage.getItem("auth_token") &&
//       JSON.parse(localStorage.getItem("privateInfo")) &&
//       JSON.parse(localStorage.getItem("privateInfo")).horoscopeMatching !==
//         null &&
//       !localStorage.getItem("postId") ? (
//         <Component {...props} />
//       ) : (
//         props.history.push("/complete-ad-private")
//       )
//     }
//   />
// );

const MatchRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return localStorage.getItem("auth_token") &&
        localStorage.getItem("postId") ? (
        <Component {...props} />
      ) : (
        props.history.push(
          `/access-denied?path=${props.history.location.pathname}`
        )
      );
    }}
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        props.history.push("/?page=1")
      )
    }
  />
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error && error.message == "Network Error") {
    //   window.location.href = "/network-error";
    //   logErrors(error.message);
    // } else if (error && error.response && (error.response.status === 502 || error.response.status === 503)) {
    //   window.location.href = "/outage";
    //   logErrors(error.message);
    // } else {
    if (error.message === "Network Error") {
      store.dispatch(setIsNetwork(true));
    }

    if (
      error.response.status === 501 ||
      error.response.status === 502 ||
      error.response.status === 503 ||
      error.response.status === 520
    ) {
      store.dispatch(setIsOutsage(true));
    }

    if (error && error.response) {
      if (error.response.status !== 401) {
        if (error.response.status !== 410) {
          logErrors(error.response);
        }
      }
    }
    if (
      error.response &&
      error.response.status === 401 &&
      localStorage.getItem("auth_token")
    ) {
      logout();
    }
    return Promise.reject(error);
    // }
  }
);

const logout = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("post_details");
  localStorage.removeItem("postId");
  localStorage.removeItem("session_creation");
  localStorage.removeItem("personalInfo");
  localStorage.removeItem("parentInfo");
  localStorage.removeItem("privateInfo");
  localStorage.removeItem("user_name");
  localStorage.removeItem("user_email");
  sessionStorage.removeItem("chat_token");
  sessionStorage.removeItem("chat_token_expires_in");
  localStorage.removeItem("user_role");
  localStorage.removeItem("operator_id");

  // Refactor this with react router dom history
  window.location.href = "/login";
};

function App(props) {
  const dispatch = useDispatch();
  const { isOutage } = useSelector((state) => state.globalReducer);
  const { isNetwork } = useSelector((state) => state.globalNetwork);
  const [appLanguage, setAppLanguage] = useState(
    LocaleHandler.getLanguageFromStorage()
  );
  const [lng, handleLng] = useState(
    LocaleHandler.getLanguageFromStorage() === Constants.APP_LANG.EN
  );

  useEffect(() => {
    LocaleHandler.changeLanguage(appLanguage);
    localStorage.setItem("lng", appLanguage === Constants.APP_LANG.EN);
  }, [appLanguage]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  // Refer this function as a function in utility layer
  const storeUtmTags = useCallback(() => {
    const utmTags = {};
    for (const tag of Constants.UTM_TAG_KEYS) {
      const value = searchParams.get(tag);
      if (value !== null) {
        utmTags[tag] = value;
      }
    }
    if (Object.entries(utmTags).length > 0) {
      if (utmTags.utm_campaign !== undefined) {
        document.cookie = serializeCookie("utm_campaign", utmTags.utm_campaign);
      }
      if (utmTags.utm_medium !== undefined) {
        document.cookie = serializeCookie("utm_medium", utmTags.utm_medium);
      }
      if (utmTags.utm_source !== undefined) {
        document.cookie = serializeCookie("utm_source", utmTags.utm_source);
      }
    }
  }, [searchParams]);

  const initializeApp = useCallback(async () => {
    try {
      dispatch(initializeConfig());
      dispatch(initializeAuth());
      dispatch(initializedGenericOptions(appLanguage));
    } catch (e) {
      console.error("Error! [initializeApp]", e);
    }
    storeUtmTags();
    // send analytics cookie values to API
    if (
      !isEmpty(document.cookie) &&
      localStorage.getItem("auth_token") !== null
    ) {
      const analyticsCookies = extractAnalyticsCookies();
      UserApi.postUserAnalyticsCookies({ cookies: analyticsCookies });
    }
  }, [appLanguage, dispatch, storeUtmTags]);

  useEffect(() => {
    initializeApp();
  });

  if (isNetwork) {
    return <NetworkError />;
  }

  if (isOutage) {
    return <Outage />;
  }

  return (
    <AppContext.Provider
      value={{
        monthlyMagazineVisibility: Config.MAGAZINE_VISIBILITY,
        appLanguage,
        setAppLanguage,
        lng,
        handleLng,
      }}
    >
      <Router>
        <AppContainer>
          <Switch>
            <Route
              path="/sample-date-picker"
              exact
              component={SampleDatePicker}
            />
            <Route
              path="/privacy"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? PrivacyPolicyPageIn
                  : Privacy
              }
            />
            <Route path="/reviews" exact component={Reviews} />
            <Route
              path="/terms"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? TermsAndConditionsPageIn
                  : Terms
              }
            />
            <Route
              path="/pricing"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? PricingPageIn
                  : Pricing
              }
            />
            {Config.MAGAZINE_VISIBILITY && (
              <Route path="/magazine" exact component={Magazine} />
            )}
            <Route
              path="/faq"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? FaqPageIn
                  : FAQ
              }
            />
            <Route
              path="/help"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? HelpPageIn
                  : Help
              }
            />
            <Route path="/pricing" exact component={Pricing} />
            {Config.MAGAZINE_VISIBILITY && (
              <Route path="/magazine" exact component={Magazine} />
            )}
            <Route
              path="/faq"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? FaqPageIn
                  : FAQ
              }
            />
            <Route
              path="/help"
              exact
              component={
                Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
                  ? HelpPageIn
                  : Help
              }
            />
            <LoginRoute path="/login" exact component={Login} />
            {/* <LoginRoute path="/create-an-account" exact component={Register} /> */}
            <LoginRoute path="/register" exact component={RegisterNew} />
            <LoginRoute path="/auth" exact component={OTPLogin} />
            {/* <LoginRoute path="/create-an-account" exact component={OTPLogin} /> */}
            {/* <Route
              path="/complete-ad-personal"
              exact
              component={CompletePersonal}
            />
            <ParentRoute
              path="/complete-ad-parent"
              exact
              component={CompleteParent}
            />
            <PrivateRoute
              path="/complete-ad-private"
              exact
              component={CompletePrivate}
            />
            <ReviewRoute
              path="/complete-ad-review"
              exact
              component={CompletePay}
            /> */}
            <Route
              path="/complete-ad-personal"
              exact
              component={PersonalCreateAccount}
            />
            <Route
              path="/complete-ad-parent"
              exact
              component={ParentsCreateAccount}
            />
            <Route
              path="/complete-ad-private"
              exact
              component={PrivateCreateAccount}
            />
            <Route
              path="/complete-ad-review"
              exact
              component={ReviewAndPayCreateAccount}
            />
            {/* TODO: Delete above and replace this when revamp is totally stable */}
            {/* <Route
              path="/complete-ad-personal"
              exact
              component={PersonalCreateAccount}
            />
            <Route
              path="/complete-ad-parent"
              exact
              component={ParentsCreateAccount}
            />
            <Route
              path="/complete-ad-private"
              exact
              component={PrivateCreateAccount}
            />
            <Route
              path="/complete-ad-review"
              exact
              component={ReviewAndPayCreateAccount}
            /> */}
            <Route path="/pay/:id" exact component={PaymentLink} />
            <Route
              path="/invalid-payment-link"
              exact
              component={InvalidPaymentLink}
            />
            <Route path="/pay-for-ad" exact component={PaymentForAd} />
            <Route path="/checkout" exact component={Checkout} />
            <Route
              path="/checkout/bank-transfer"
              exact
              component={BankTransfer}
            />
            <Route path="/restricted" exact component={RestrictedProfile} />
            <Route path="/unauthorized-user" exact component={IncorrectToken} />
            <LoginRoute path="/access-denied" exact component={Unauthorize} />
            <Route path="/unavailable-profile" exact component={DisabledAcc} />
            <Route path="/payment-success" exact component={PaymentSuccess} />
            <Route path="/renew/success" exact component={PaymentSuccess} />
            <Route path="/unsubscribe" exact component={Unsubscribed} />
            <Route path="/payment-failed" exact component={PaymentError} />
            <Route path="/activated" exact component={EmailActivation} />
            <Route
              path="/already-activated"
              exact
              component={EmailAlreadyActivated}
            />
            <Route path="/reset-password" exact component={PasswordReset} />
            <Route path="/post-v1" exact component={PostDetails} />
            <Route path="/post" exact component={post} />
            <Route path="/my-matches" exact component={MyMatches} />
            <Route
              path="/interests-matched"
              exact
              component={InterestsMatched}
            />
            <Route path="/interests-sent" exact component={InterestsSent} />
            <MatchRoute
              path="/post-interests"
              exact
              component={RedirectIntPost}
            />
            <Route path="/match" exact component={RedirectMatchPost} />
            <Route path="/my-ad-v1" exact component={MyAdd} />
            <Route path="/my-ad" exact component={ViewMyAd} />
            {/* <Route path="/edit-my-ad" exact component={EditAd} /> */}
            <Route path="/edit-my-ad" exact component={EditMyAd} />
            {/* <Route path="/edit-my-ad-v2" exact component={EditMyAd} /> */}
            <Route path="/settings" exact component={Settings} />
            <Route path="/interest-preferences" exact component={PartnerPref} />
            <Route path="/" exact component={Home} />
            <Route path="/payments/top-ad" exact component={PaymentForTopAd} />
            <Route path="/top-ad-checkout" exact component={TopAdCheckout} />
            <Route
              path="/checkout/top-ad-bank-transfer"
              exact
              component={TopAdBankTransfer}
            />
            <Route
              path="/checkout/top-ad"
              exact
              component={TopAdPaymentStatus}
            />
            <Route path="/complete-your-ad" exact component={CompleteYourAd} />
            <Route path="/manage-posts" exact component={ManagePosts} />
            <Route path="/outage" exact component={Outage} />
            <Route path="/network-error" exact component={NetworkError} />
            <MatchRoute path="/messages" exact component={Messages} />
            <MatchRoute path="/interests" exact component={Interests} />
            <MatchRoute
              path="/preference-mismatch-interests"
              exact
              component={PreferenceMismatchInterests}
            />
            <MatchRoute
              path="/sent-interests"
              exact
              component={SentInterests}
            />
            <Route
              path="/review-comments"
              exact
              component={ReviewComments}
            ></Route>
            <Route exact component={PageNotFound} />
          </Switch>
        </AppContainer>
      </Router>
    </AppContext.Provider>
  );
}

export default withRouter(App);
