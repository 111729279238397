import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Paper,
  FormControlLabel,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty.js";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import Config from "../../v2/config/index.js";
import { ageArr } from "../../contant-data/ageArr";
import { heightsWithShortLabels as heightsArr } from "../../contant-data/heightsArr";

import MultiCheckBoxSelector from "../../v2/pages/home/filter-bar/templates/multi-checkbox-selector/index.jsx";
import FormMeta from "../../v2/meta/post-filter-bar.json";
import { buildModelByFormMeta, fieldVisibility } from "../../v2/utils/index.js";
import AppContext from "../../v2/context/index.js";
import FilterCriteriaView from "../../v2/pages/home/filter-bar/templates/filter-criteria-view/index.jsx";
import Dropdown from "../../v2/components/atoms/dropdown/index.jsx";
import { onSavePrefferredSearch } from "../../v2/features/home-page/index.js";
import "../../styles/filter-bar.css";
import {
  GenderSelector,
  InterestMatchToggle,
  OrderBySelector,
  RangeSelector,
} from "../../v2/pages/home/filter-bar/templates/form-elements/index.jsx";
import { setHomePageMobileFilterDrawer } from "../../v2/store/actions/home-page/index.js";
import _ from "lodash";

const excludedProfessionIds = [Config.HOUSE_WIFE_PROFESSION_ID];

const FilterBar = ({ isMobileViewport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const headerContainerRef = useRef(null);
  const footerContainerRef = useRef(null);

  const { values, setFieldValue, setValues } = useFormikContext();
  const { authenticated } = useSelector((state) => state.application);
  const {
    religions,
    ethnicities,
    civilStatusOptions,
    countries,
    educationLevels,
    professions,
    foodPreferences,
    drinkingPreferences,
    smokingPrefernces,
    accountOwnership,
    differentlyAbledOptions,
    nicVerifiedOptions,
    motherTongues,
  } = useSelector((state) => state.genericData);
  const {
    countryRegions,
    communities,
    totalCount,
    showSavePrefferredCriteriaBtn,
    savingPrefferredCriteria,
    mobileSavePrefferredCriteria,
  } = useSelector((state) => state.homePage);

  const { lng } = useContext(AppContext);

  const onClickSaveFilterBtn = () => {
    dispatch(onSavePrefferredSearch({ criteria: values }));
  };

  useEffect(() => {
    const pageSize = isMobileViewport
      ? Config.HOME_PAGE_POST_LIMIT_MOBILE
      : Config.HOME_PAGE_POST_LIMIT_LAPTOP;

    if (values.pageSize !== pageSize) {
      setFieldValue(FormMeta.pageSize.fieldName, pageSize);
    }
  }, [setValues, isMobileViewport, values.pageSize, setFieldValue]);

  const regionSelectionHelperText = useMemo(() => {
    if (Array.isArray(values.residentCountries)) {
      if (values.residentCountries.length !== 1) {
        return values.residentCountries.length === 0
          ? t("filterbar.countryToFilterByRegion")
          : t("filterbar.singleCountryToFilterByItsRegions");
      } else {
        if (countryRegions.length === 0) {
          return t("filterbar.noRegionsToSelect");
        }
        return false;
      }
    }
    return false;
  }, [countryRegions.length, t, values.residentCountries]);

  const filteredProfessions = useMemo(() => {
    return professions.filter(
      (element) => !excludedProfessionIds.includes(element.id)
    );
  }, [professions]);

  const communityHelperText = useMemo(() => {
    const selectedReligions = values[FormMeta.religions.fieldName];
    if (Array.isArray(selectedReligions)) {
      if (selectedReligions.length !== 1) {
        return selectedReligions.length === 0
          ? t("filterbar.religionToFIlterByCommunities")
          : t("filterbar.singleCommunityToFilterByItsReligion");
      } else {
        if (communities.length === 0) {
          return t("filterbar.communityAvailability");
        }
        return false;
      }
    }
    return false;
  }, [communities.length, t, values]);

  let filterContainerHeight = 0;
  if (isMobileViewport) {
    const headerHeight =
      headerContainerRef.current?.getBoundingClientRect().height ?? 0;
    const footerHeight =
      footerContainerRef.current?.getBoundingClientRect().height ?? 0;
    filterContainerHeight = `calc(100% - ${headerHeight + footerHeight}px)`;
  }

  return (
    <Grid container direction="column" className="filter-bar-root-container">
      {isMobileViewport ? (
        <Grid
          container
          direction="row"
          className="mobile-filter-header"
          ref={headerContainerRef}
        >
          <p
            className="actionable-text"
            onClick={() => {
              const initialModel = buildModelByFormMeta(FormMeta);
              setValues(initialModel);
            }}
          >
            Clear Filters
          </p>
          <p className="filter-search-label">{t("common.search")}</p>
          <IconButton
            onClick={() => {
              dispatch(setHomePageMobileFilterDrawer(false));
            }}
          >
            <HighlightOffIcon style={{ color: "black", opacity: 1 }} />
          </IconButton>
        </Grid>
      ) : (
        <p
          className="actionable-text"
          onClick={() => {
            const initialModel = buildModelByFormMeta(FormMeta);
            setValues(initialModel);
          }}
        >
          Clear Filters
        </p>
      )}
      <Grid
        container
        direction="column"
        className="filters-container"
        style={{
          ...(isMobileViewport && filterContainerHeight
            ? { height: filterContainerHeight }
            : {}),
        }}
      >
        <OrderBySelector
          meta={FormMeta.sortBy}
          value={values[FormMeta.sortBy.fieldName]}
          setFieldValue={setFieldValue}
        />
        <GenderSelector
          meta={FormMeta.gender}
          value={values[FormMeta.gender.fieldName]}
          setFieldValue={setFieldValue}
        />
        {authenticated && (
          <InterestMatchToggle
            meta={FormMeta.showInterests}
            value={values[FormMeta.showInterests.fieldName]}
            setFieldValue={setFieldValue}
          />
        )}
        <FilterCriteriaView
          fieldMeta={FormMeta.age}
          showResetOption={
            !(
              values.minAge === FormMeta.minAge.defaultValue &&
              values.maxAge === FormMeta.maxAge.defaultValue
            )
          }
          onClickReset={() => {
            setFieldValue(
              FormMeta.minAge.fieldName,
              FormMeta.minAge.defaultValue
            );
            setFieldValue(
              FormMeta.maxAge.fieldName,
              FormMeta.maxAge.defaultValue
            );
          }}
        >
          <RangeSelector
            startFieldMeta={FormMeta.minAge}
            startValue={values[FormMeta.minAge.fieldName]}
            endFieldMeta={FormMeta.maxAge}
            endValue={values[FormMeta.maxAge.fieldName]}
            setFieldValue={setFieldValue}
            items={ageArr}
            t={t}
          />
        </FilterCriteriaView>
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.residentCountries}
          options={_.sortBy(countries, [
            (item) => item.name === "Other",
            "name",
          ])}
          values={values.residentCountries}
          setFieldValues={setFieldValue}
          visibility={fieldVisibility(FormMeta.residentCountries)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.regionOrDistrict}
          values={values.regionOrDistrict}
          setFieldValues={setFieldValue}
          options={countryRegions}
          visibility={fieldVisibility(FormMeta.regionOrDistrict)}
          helperText={regionSelectionHelperText}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.motherTongues}
          values={values[FormMeta.motherTongues.fieldName]}
          setFieldValues={setFieldValue}
          options={motherTongues}
          visibility={fieldVisibility(FormMeta.motherTongues)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.ethnicities}
          values={values.ethnicities}
          setFieldValues={setFieldValue}
          options={ethnicities}
          visibility={fieldVisibility(FormMeta.ethnicities)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.religions}
          values={values.religions}
          setFieldValues={setFieldValue}
          options={_.sortBy(religions, [
            (item) => item.name === "Other",
            "name",
          ])}
          visibility={fieldVisibility(FormMeta.religions)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.communities}
          values={values[FormMeta.communities.fieldName]}
          setFieldValues={setFieldValue}
          options={communities}
          visibility={fieldVisibility(FormMeta.communities)}
          helperText={communityHelperText}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.civilStatuses}
          values={values.civilStatuses}
          setFieldValues={setFieldValue}
          options={civilStatusOptions}
          visibility={fieldVisibility(FormMeta.civilStatuses)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.professions}
          values={values.professions}
          setFieldValues={setFieldValue}
          options={filteredProfessions}
          visibility={fieldVisibility(FormMeta.professions)}
        />
        <FilterCriteriaView
          fieldMeta={FormMeta.educationLevels}
          showResetOption={!isEmpty(values.educationLevels)}
          onClickReset={() => {
            setFieldValue("educationLevels", []);
          }}
        >
          <Box className="accordian-content-wrapper-v2">
            <Dropdown
              fieldMeta={FormMeta.educationLevels}
              options={educationLevels}
              value={
                isEmpty(values.educationLevels) ? "" : values.educationLevels[0]
              }
              onChange={(e) => {
                setFieldValue("educationLevels", [e.target.value]);
              }}
              menuItemProps={{ className: "sort-value" }}
            />
          </Box>
        </FilterCriteriaView>
        <FilterCriteriaView
          fieldMeta={FormMeta.height}
          showResetOption={
            !(
              values.minHeight === FormMeta.minHeight.defaultValue &&
              values.maxHeight === FormMeta.maxHeight.defaultValue
            )
          }
          onClickReset={() => {
            setFieldValue(
              FormMeta.minHeight.fieldName,
              FormMeta.minHeight.defaultValue
            );
            setFieldValue(
              FormMeta.maxHeight.fieldName,
              FormMeta.maxHeight.defaultValue
            );
          }}
        >
          <RangeSelector
            startFieldMeta={FormMeta.minHeight}
            startValue={values[FormMeta.minHeight.fieldName]}
            endFieldMeta={FormMeta.maxHeight}
            endValue={values[FormMeta.maxHeight.fieldName]}
            items={heightsArr}
            setFieldValue={setFieldValue}
            t={t}
          />
        </FilterCriteriaView>
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.foodPreferences}
          values={values.foodPreferences}
          setFieldValues={setFieldValue}
          options={foodPreferences}
          visibility={fieldVisibility(FormMeta.foodPreferences)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.drinking}
          values={values[FormMeta.drinking.fieldName]}
          setFieldValues={setFieldValue}
          options={drinkingPreferences}
          visibility={fieldVisibility(FormMeta.drinking)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.smoking}
          values={values.smoking}
          setFieldValues={setFieldValue}
          options={smokingPrefernces}
          visibility={fieldVisibility(FormMeta.smoking)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.differentlyAbled}
          values={values[FormMeta.differentlyAbled.fieldName]}
          setFieldValues={setFieldValue}
          options={differentlyAbledOptions}
          visibility={fieldVisibility(FormMeta.differentlyAbled)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.accountCreatedBy}
          values={values.accountCreatedBy}
          setFieldValues={setFieldValue}
          options={accountOwnership}
          visibility={fieldVisibility(FormMeta.accountCreatedBy)}
        />
        <MultiCheckBoxSelector
          fieldMeta={FormMeta.idVerification}
          values={values.idVerification}
          setFieldValues={setFieldValue}
          options={nicVerifiedOptions}
          visibility={fieldVisibility(FormMeta.idVerification)}
        />
      </Grid>
      {isMobileViewport ? (
        <Paper className="mobile-footer-container" ref={footerContainerRef}>
          <Grid container direction="column">
            {showSavePrefferredCriteriaBtn && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mobileSavePrefferredCriteria}
                    onChange={(e) => {
                      e.preventDefault();
                      dispatch(
                        onSavePrefferredSearch({
                          criteria: values,
                        })
                      );
                    }}
                  />
                }
                label={
                  <Typography
                    className={`checkbox-label checkbox-label-${lng}`}
                  >
                    {t("filterbar.savePrefferredSearch")}
                  </Typography>
                }
              />
            )}
            <Grid container direction="column" className="mobile-footer-btn">
              <Typography
                variant="body1"
                className="mobile-footer-btn-primary-txt"
                onClick={() => dispatch(setHomePageMobileFilterDrawer(false))}
              >
                {t("filterbar.show")}
              </Typography>
              {Config.CURRENT_PLATFORM !== Config.PLATFORMS.IN && (
                <Typography
                  variant="body2"
                  className="mobile-footer-btn-secondry-txt"
                >
                  {totalCount > 0
                    ? t("filterbar.noOfPostsFooterBtn", {
                        totalPosts: totalCount,
                      })
                    : t("filterbar.noPosts")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <div
          className="default-search-div"
          style={{
            margin: 0,
            borderRadius: 0,
            border: 0,
            borderTop: "0.5px solid #0000001f",
          }}
          id="default-search-save"
        >
          <p align="center">{t("filterbar.saveThisSearch")}</p>

          {!showSavePrefferredCriteriaBtn ? (
            <p
              style={{
                color: "#28a745",
                fontWeight: 400,
                fontSize: 13,
                textAlign: "center",
              }}
            >
              <i className="fas fa-check"></i> &nbsp; Saved
            </p>
          ) : (
            <Grid
              container
              direction="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                columnGap: 8,
              }}
            >
              {!savingPrefferredCriteria ? (
                <Button
                  onClick={onClickSaveFilterBtn}
                  style={{ marginInline: 0 }}
                >
                  {t("filterbar.save")}
                </Button>
              ) : (
                <CircularProgress size={16} style={{ color: "#cca326" }} />
              )}
            </Grid>
          )}
        </div>
      )}
    </Grid>
  );
};

export default FilterBar;
