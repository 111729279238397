import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Paper, Grid, CircularProgress, } from "@material-ui/core";
import "react-photo-view/dist/index.css";

class Alerts extends Component {
  getLastOnline = (time) => {
    let timeVal = "";
    switch (time) {
      case "0_DAY":
        timeVal = this.props.lng ? "Today" : "අද දින";
        break;
      case "1_DAY":
        timeVal = this.props.lng ? "Yesterday" : "ඊයේ දින";
        break;
      case "7_DAY":
        timeVal = this.props.lng ? "Within 7 days" : "දින 7ක් ඇතුළත";
        break;
      case "30_DAY":
        timeVal = this.props.lng ? "Within 30 days" : "දින 30ක් ඇතුළත";
        break;
      case ">30_DAY":
        timeVal = this.props.lng ? "Before 30 days" : "දින 30කට පෙර";
        break;
    }
    return timeVal;
  };
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "8vh", position: "relative", paddingBottom: '10px' }}
            className={"complete-ad-div settings-container post-details-con"}
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (<React.Fragment>
              {this.props.lastOnline && (
              <table className="respond-ways" style={{ marginTop: "0" }}>
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: "0", width: "30px" }}>
                      <i
                        className="fas fa-circle alert-online-icon"
                      ></i>
                    </td>
                    <td style={{ position: "relative" }}>
                      <p
                        className="alert-text"
                      >
                        {this.props.lng ? (
                          <>
                            <span>Last online : </span>
                            <span style={{ fontWeight: "500" }}>
                              {this.getLastOnline(this.props.lastOnline)}
                            </span>
                          </>
                        ) : (
                          <span className="sinhala-size-14">
                            <span>අවසාන ඔන්ලයින් දිනය : </span>
                            <span style={{ fontWeight: "bold" }}>
                              {this.getLastOnline(this.props.lastOnline)}
                            </span>
                          </span>
                        )}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <table className="respond-ways" style={{ marginTop: "0" }}>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "0", width: "30px" }}>
                    {this.props.hasImages ? <i className="fas fa-check-circle alert-enable-icon"></i>:<i className="fas fa-times-circle alert-disable-icon"></i>}
                  </td>
                  <td style={{ position: "relative" }}>
                    <p
                      className="alert-text"
                    >
                      {this.props.lng ? (
                        <>
                          <span> Pictures </span>
                        </>
                      ) : (
                        <span className="sinhala-size-14">
                          <span>පින්තූර</span>
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="respond-ways" style={{ marginTop: "0" }}>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "0", width: "30px" }}>
                    {this.props.hasHoroscopeData ? <i className="fas fa-check-circle alert-enable-icon"></i>:<i className="fas fa-times-circle alert-disable-icon"></i>}
                  </td>
                  <td style={{ position: "relative" }}>
                    <p
                      className="alert-text"
                    >
                      {this.props.lng ? (
                        <>
                          <span>Horoscope information</span>
                        </>
                      ) : (
                        <span className="sinhala-size-14">
                          <span>කේන්දර විස්තර</span>
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            {!this.props.applyMatch && (
                  <p
                    className="invisible-photos"
                    style={{ marginBottom: 0, marginTop: 0 }}
                  >
                    <i className="fas fa-eye-slash"></i>{" "}
                    {this.props.lng
                      ? "Private information such as full name, birth date, pictures, contact details and horoscope information are only visible to matched profiles."
                      : "සම්පූර්ණ නම, උපන් දිනය, පින්තූර, දුරකථන අංක සහ කේන්දර තොරතුරු පෙන්වන්නේ ගැලපීම් වලට පමණි."}
                  </p>
            )}
            </React.Fragment>)}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(Alerts);
