import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { Formik } from "formik";

import AppContext from "../../context";
import Unauthorize from "../../../pages/Unauthorize";
import PageHead from "../../components/atoms/page-head";
import Navbar from "../../../components/common/Navbar";
import FooterMobile from "../../../components/common/FooterMobile";
import FooterDekstop from "../../../components/common/Footer";
import EditMyAdForm from "./edit-my-ad-form";
import FormMeta from "../../meta/edit-self-profile.json";
import { buildModelByFormMeta } from "../../utils";
import Config from "../../../v2/config";

import "./style.css";
import {
  downloadAdProfileImages,
  downloadHoroscopeImages,
  getAdvertisement,
} from "../../features/edit-ad";
import NotificationBar from "../create-flow/templates/notification-bar";
import { setEditAdPageBoostrapped } from "../../store/actions/edit-ad-page";

const initialValues = buildModelByFormMeta(FormMeta);

const EditMyAdPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobiileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { lng, handleLng } = useContext(AppContext);
  const { authenticated, initialized, authProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { advertisement, notification, boostrapped } = useSelector(
    (state) => state.editAdPage
  );
  const contentLoading = useMemo(() => {
    return !initialized || (authenticated && isEmpty(authProfile));
  }, [initialized, authenticated, authProfile]);

  const isUnauthorized = useMemo(() => {
    return !(!initialized || authenticated);
  }, [initialized, authenticated]);

  useEffect(() => {
    if (!isEmpty(authAccount) && !isEmpty(authProfile)) {
      dispatch(getAdvertisement());
    }
  }, [authProfile, authAccount, dispatch]);

  useEffect(() => {
    if (!isEmpty(advertisement) && !boostrapped) {
      dispatch(
        downloadAdProfileImages({
          postId: advertisement.id,
          sources: advertisement.images ?? [],
        })
      );
      dispatch(
        downloadHoroscopeImages({
          postId: advertisement.id,
          sources: advertisement.horoscopeInfo?.images ?? [],
        })
      );
      dispatch(setEditAdPageBoostrapped(true));
    }
  }, [advertisement, boostrapped, dispatch]);

  if (isUnauthorized) {
    return <Unauthorize title={`Edit My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />;
  }

  return (
    <>
      <PageHead title={`Edit My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <Grid
        container
        direction="row"
        className="edit-my-page-container"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          container
          direction="column"
          alignItems="center"
          className="form-container"
        >
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <EditMyAdForm contentLoading={contentLoading} />
          </Formik>
        </Grid>

        <Grid item xs={12}>
          {!isMobiileScreen ? (
            <FooterDekstop lng={lng} />
          ) : (
            <FooterMobile lng={lng} />
          )}
        </Grid>

        <NotificationBar
          severity={notification.severity}
          message={notification.message}
        />
      </Grid>
    </>
  );
};

export default EditMyAdPage;
