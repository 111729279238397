import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import Config from "../../../../config";

const {
  OTHER_COUNTRY_CODE,
  BASE_COUNTRY_CODE,
  OTHER_PROFESSION_ID,
  PLATFORMS,
  CURRENT_PLATFORM,
} = Config;

const CommonSchema = (t) => {
  return Yup.object().shape({
    fname: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.firstname") })
      )
      .min(
        2,
        t("errors.formFieldTooShort", { fieldName: t("common.firstname") })
      )
      .matches(/^\D+$/, {
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.firstname"),
        }),
      }),
    lname: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.lastname") })
      )
      .matches(/^[a-zA-Z ]+$/, {
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.lastname"),
        }),
      }),
    accountOwner: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.accountCreatedBy"),
      })
    ),
    displayNameFormat: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.displayNameFormat"),
      })
    ),
    email: Yup.string()
      .optional()
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        t("errors.invalidFieldValue", { fieldName: t("common.email") })
      ),
    dob: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.birthdate") })
    ),
    gender: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.gender") })
    ),
    height: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.height") })
    ),
    religionId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.religion") })
    ),
    civilStatusId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.civilStatus") })
    ),
    children: Yup.string().when("civilStatusId", ([civilStatusId], schema) => {
      if (Boolean(civilStatusId) && civilStatusId !== "never_married") {
        return schema.required(
          t("errors.formRequiredField", {
            fieldName: t("common.doYouHaveChildren"),
          })
        );
      }
    }),
    residentCity: Yup.string()
      .required(t("errors.formRequiredField", { fieldName: t("common.city") }))
      .matches(/^\D+$/, {
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.city"),
        }),
      }),
    residentCountryCode: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.countryOfResidence"),
      })
    ),
    residentRegionId: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (residentCountryCode !== Config.OTHER_COUNTRY_CODE) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("common.stateOrDistrict"),
            })
          );
        }
      }
    ),
    residentCountryCodeOther: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (
          Boolean(residentCountryCode) &&
          residentCountryCode === OTHER_COUNTRY_CODE
        ) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("common.country"),
            })
          );
        }
      }
    ),
    visaTypeId: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (
          Boolean(residentCountryCode) &&
          residentCountryCode !== BASE_COUNTRY_CODE
        ) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("createflow.visaType"),
            })
          );
        }
      }
    ),
    educationLevelId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.educationLevel") })
    ),
    professionId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.profession") })
    ),
    otherProfession: Yup.string().when(
      "professionId",
      ([_professionId], schema) => {
        let professionId =
          typeof _professionId === "string"
            ? parseInt(_professionId)
            : _professionId;
        if (professionId === OTHER_PROFESSION_ID) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("common.typeProfession"),
            })
          );
        }
      }
    ),
    drinking: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.drinking") })
    ),
    smoking: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.smoking") })
    ),
    foodPreferenceId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.foodPreference") })
    ),
    additionalInfo: Yup.string().optional(),
  });
};

const LankaSpecificSchema = (t) => {
  return Yup.object().shape({
    ethnicityId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.ethnicity") })
    ),
  });
};

const IndiaSpecificSchema = (t) => {
  return Yup.object().shape({
    communityId: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.communityField"),
      })
    ),
    motherTongueId: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.motherTounge"),
      })
    ),
    gothraId: Yup.string().when("communityId", ([communityId], schema) => {
      if (!isEmpty(communityId) && communityId === "hindu") {
        return schema.required(t("errors.formRequiredField"), {
          fieldName: t("createflow.gothra"),
        });
      }
    }),
  });
};

const PlatformSpecificSchema = (t) => {
  switch (CURRENT_PLATFORM) {
    case PLATFORMS.IN:
      return IndiaSpecificSchema(t);
    default:
      return LankaSpecificSchema(t);
  }
};

const FormSchema = (t) => {
  const schema = CommonSchema(t);
  const platformSchema = PlatformSpecificSchema(t);
  return schema.concat(platformSchema);
};

export default FormSchema;
