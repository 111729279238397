import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import moment from "moment";

import FormSection from "../../../../../components/molecules/form-section";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import FormTextInput from "../../../../../components/molecules/form-text-input/index";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import FormDatePicker, {
  DATE_PATTERN,
} from "../../../../../components/molecules/form-date-picker";
import { useFormikContext } from "formik";
import AppContext from "../../../../../context";
import FormTextView from "../../../../../components/molecules/form-text-view";
import { generateDisplayNameView } from "../../../../../utils/data-generator";
import { fieldVisibility } from "../../../../../utils";
import Config from "../../../../../config";
import { getCommunitiesByReligionId } from "../../../../../features/generic-data";
import { setAppSnackNotification } from "../../../../../store/actions/application";
import {
  ACCOUNT_CREATED_BY_OPTIONS,
  ADDITIONAL_COMMUNITY_OPTIONS,
} from "../../../../../static-data";

const { CURRENT_PLATFORM, PLATFORMS } = Config;

const BasicInformation = ({ formMeta }) => {
  const dispatch = useDispatch();
  const { values, handleChange, errors, setFieldValue, submitCount } =
    useFormikContext();
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    genderOptions,
    civilStatusOptions,
    ethnicities,
    nameFormatOptions,
    religions,
    heightOptions,
    hasChildrenOptions,
    gothraOptions,
    motherTongues,
  } = useSelector((state) => state.genericData);
  const [displayNamePreview, setDisplayNamePreview] = useState("");
  const [communities, setCommunities] = useState([]);

  const labelStyles = {
    ...(isMobileScreen ? {} : { width: "20%" }),
  };

  const showFieldErrors = submitCount > 0;
  const dobStartDate = moment().subtract(60, "years").format(DATE_PATTERN);
  const dobEndDate = moment().subtract(18, "years").format(DATE_PATTERN);

  const fetchCommunities = useCallback(
    async ({ religionId }) => {
      try {
        const response = await getCommunitiesByReligionId({ religionId });
        if (!response.success) {
          throw new Error("unable to fetch communities");
        }
        setCommunities([...response.body, ...ADDITIONAL_COMMUNITY_OPTIONS]);
      } catch (e) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to fetch communities",
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const { fname, lname, displayNameFormat, religionId } = values;
    if (Boolean(displayNameFormat)) {
      const displayNamePreview = generateDisplayNameView({
        displayNameFormat,
        firstname: fname,
        lastname: lname,
      });
      setDisplayNamePreview(displayNamePreview);
    }
    if (CURRENT_PLATFORM === PLATFORMS.IN) {
      if (!isEmpty(religionId)) {
        fetchCommunities({ religionId });
      }
    }
  }, [values, fetchCommunities]);

  const showGothraField = useMemo(() => {
    return (
      fieldVisibility(formMeta.gothraId) &&
      values[formMeta.religion.fieldName] === "hindu"
    );
  }, [formMeta.gothraId, formMeta.religion.fieldName, values]);

  const showChildrenField = useMemo(() => {
    const civilStatus = values[formMeta.civilStatus.fieldName];
    return !isEmpty(civilStatus) && civilStatus !== "never_married";
  }, [formMeta.civilStatus.fieldName, values]);

  return (
    <FormSection heading={t("common.basic")}>
      <Grid container direction="row" style={{ rowGap: 8 }}>
        <Grid item xs={12} style={{ marginBlock: 8 }}>
          <Notice
            varaint={Varaint.primary}
            label={t("createflow.basic_information_notice")}
            lang={appLanguage}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          direction="column"
          className="row-gap-16"
        >
          <FormTextInput
            fieldName={formMeta.firstName.fieldName}
            value={values[formMeta.firstName.fieldName]}
            onChange={handleChange}
            label={t(formMeta.firstName.label)}
            lang={appLanguage}
            labelStyles={labelStyles}
            errorText={showFieldErrors && errors[formMeta.firstName.fieldName]}
            visibility={fieldVisibility(formMeta.firstName)}
          />
          <FormTextInput
            fieldName={formMeta.lastName.fieldName}
            value={values[formMeta.lastName.fieldName]}
            onChange={handleChange}
            label={t(formMeta.lastName.label)}
            lang={appLanguage}
            labelStyles={labelStyles}
            errorText={showFieldErrors && errors[formMeta.lastName.fieldName]}
            visibility={fieldVisibility(formMeta.lastName)}
          />
          <FormDropdown
            fieldName={formMeta.accountOwner.fieldName}
            value={values[formMeta.accountOwner.fieldName]}
            onChange={handleChange}
            label={t(formMeta.accountOwner.label)}
            lang={appLanguage}
            labelStyles={labelStyles}
            options={ACCOUNT_CREATED_BY_OPTIONS}
            keyExtractor={formMeta.accountOwner.keyExtractor}
            labelExtractor={formMeta.accountOwner.labelExtractor}
            errorText={
              showFieldErrors && errors[formMeta.accountOwner.fieldName]
            }
            visibility={fieldVisibility(formMeta.accountOwner)}
          />
          <FormDropdown
            fieldName={formMeta.displayNameFormat.fieldName}
            value={values[formMeta.displayNameFormat.fieldName]}
            onChange={handleChange}
            options={nameFormatOptions}
            label={t(formMeta.displayNameFormat.label)}
            lang={appLanguage}
            labelStyles={labelStyles}
            errorText={
              showFieldErrors && errors[formMeta.displayNameFormat.fieldName]
            }
            visibility={fieldVisibility(formMeta.displayNameFormat)}
          />
          <FormTextView
            label={t("common.displayNameText")}
            text={displayNamePreview}
            labelStyles={labelStyles}
            lang={appLanguage}
          />
          <FormTextInput
            fieldName={formMeta.email.fieldName}
            value={values[formMeta.email.fieldName]}
            onChange={handleChange}
            label={t(formMeta.email.label)}
            lang={appLanguage}
            labelStyles={labelStyles}
            errorText={showFieldErrors && errors[formMeta.email.fieldName]}
            visibility={fieldVisibility(formMeta.email)}
          />
        </Grid>
        <Grid container direction="row" className="row-gap-16 margin-top-8">
          {fieldVisibility(formMeta.dob) && (
            <Grid item className="form-element-container">
              <FormDatePicker
                value={values[formMeta.dob.fieldName]}
                onChange={(value) =>
                  setFieldValue(formMeta.dob.fieldName, value)
                }
                startDate={dobStartDate}
                endDate={dobEndDate}
                label={t(formMeta.dob.label)}
                lang={appLanguage}
                errorText={showFieldErrors && errors[formMeta.dob.fieldName]}
                visibility={fieldVisibility(formMeta.dob)}
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.gender) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.gender.fieldName}
                value={values[formMeta.gender.fieldName]}
                onChange={handleChange}
                options={genderOptions}
                label={t(formMeta.gender.label)}
                lang={appLanguage}
                errorText={showFieldErrors && errors[formMeta.gender.fieldName]}
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.ethnicity) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.ethnicity.fieldName}
                value={values[formMeta.ethnicity.fieldName]}
                onChange={handleChange}
                options={ethnicities}
                keyExtractor={formMeta.ethnicity.keyExtractor}
                labelExtractor={formMeta.ethnicity.labelExtractor}
                label={t(formMeta.ethnicity.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.ethnicity.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.religion) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.religion.fieldName}
                value={values[formMeta.religion.fieldName]}
                onChange={handleChange}
                options={religions}
                keyExtractor={formMeta.religion.keyExtractor}
                labelExtractor={formMeta.religion.labelExtractor}
                label={t(formMeta.religion.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.religion.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.motherTongueId) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.motherTongueId.fieldName}
                value={values[formMeta.motherTongueId.fieldName]}
                onChange={handleChange}
                options={motherTongues}
                keyExtractor={formMeta.motherTongueId.keyExtractor}
                labelExtractor={formMeta.motherTongueId.labelExtractor}
                label={t(formMeta.motherTongueId.label)}
                lang={appLanguage}
                igonoreLocaleLabels
                errorText={
                  showFieldErrors && errors[formMeta.motherTongueId.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.communityId) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.communityId.fieldName}
                value={values[formMeta.communityId.fieldName]}
                onChange={handleChange}
                options={communities}
                keyExtractor={formMeta.communityId.keyExtractor}
                labelExtractor={formMeta.communityId.labelExtractor}
                label={t(formMeta.communityId.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.communityId.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.subCommunity) && (
            <Grid item className="form-element-container">
              <FormTextInput
                fieldName={formMeta.subCommunity.fieldName}
                value={values[formMeta.subCommunity.fieldName]}
                onChange={handleChange}
                label={t(formMeta.subCommunity.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.subCommunity.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.caste) && (
            <Grid item className="form-element-container">
              <FormTextInput
                fieldName={formMeta.caste.fieldName}
                value={values.caste}
                onChange={handleChange}
                label={t(formMeta.caste.label)}
                lang={appLanguage}
                visibility={fieldVisibility(formMeta.caste)}
                errorText={showFieldErrors && errors[formMeta.caste.fieldName]}
              />
            </Grid>
          )}
          {showGothraField && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.gothraId.fieldName}
                value={values[formMeta.gothraId.fieldName]}
                onChange={handleChange}
                options={gothraOptions}
                keyExtractor={formMeta.gothraId.keyExtractor}
                labelExtractor={formMeta.gothraId.labelExtractor}
                label={t(formMeta.gothraId.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.gothraId.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.height) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.height.fieldName}
                value={values[formMeta.height.fieldName]}
                onChange={handleChange}
                options={heightOptions}
                keyExtractor={formMeta.height.keyExtractor}
                labelExtractor={formMeta.height.labelExtractor}
                label={t(formMeta.height.label)}
                lang={appLanguage}
                igonoreLocaleLabels
                errorText={showFieldErrors && errors[formMeta.height.fieldName]}
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.civilStatus) && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.civilStatus.fieldName}
                value={values[formMeta.civilStatus.fieldName]}
                onChange={handleChange}
                options={civilStatusOptions}
                keyExtractor={formMeta.civilStatus.keyExtractor}
                labelExtractor={formMeta.civilStatus.labelExtractor}
                label={t(formMeta.civilStatus.label)}
                lang={appLanguage}
                errorText={
                  showFieldErrors && errors[formMeta.civilStatus.fieldName]
                }
              />
            </Grid>
          )}
          {fieldVisibility(formMeta.children) && showChildrenField && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.children.fieldName}
                value={values.children}
                onChange={handleChange}
                options={hasChildrenOptions}
                label={t(formMeta.children.label)}
                lang={appLanguage}
                errorText={showFieldErrors && errors.children}
              />
            </Grid>
          )}
          <Grid item className="form-element-container"></Grid>
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default BasicInformation;
