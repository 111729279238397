import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";

import groom from "../../images/groom.png";
import bride from "../../images/bride.png";
import heart from "../../images/heart.png";
import Badge from "../../images/topad-badge.png";
import PhotoGrid from "../PhotoGrid";

class Header extends Component {

  state = {
    nicModal: false,
    topAdModal: false,
    imageData: [],
  };

  componentDidUpdate = (prevProps) => {
    if (localStorage.getItem("auth_token")) {
      if(prevProps.showPhotos !== this.props.showPhotos) {
        if (this.props.showPhotos && this.props.receivedInterestImages.length > 0) {
          this.getProfilePhotos(this.props.receivedInterestImages);
        }
      }
    }
  };

  handleNicModalClose = () => {
    this.setState({ nicModal: false });
  }

  handleNicModalOpen = () => {
    this.setState({ nicModal: true });
  }

  handleTopAdModalClose = () => {
    this.setState({ topAdModal: false });
  }

  handleTopAdModalOpen = () => {
    this.setState({ topAdModal: true });
  }

  getProfilePhotos = (profileImgs) => {
    if (profileImgs.length > 0) {
      for (let i = 0; i < profileImgs.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/interests/${this.props.interestInfo.interestId}/images/${
              profileImgs[i]
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            this.setState({
              imageData: [...this.state.imageData, data],
              // preLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={this.props.lastOnline ? { minHeight: "8vh", position: "relative", paddingBottom: '10px' } : { minHeight: "8vh", position: "relative" }}
            className={this.props.isFeatured ? "complete-ad-div settings-container post-details-con top-ad-post-details-con" : "complete-ad-div settings-container post-details-con"}
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                {(this.props.isFeatured && localStorage.getItem("post_details") && !JSON.parse(localStorage.getItem("post_details")).isFeatured) && (
                  <span
                    title = {this.props.lng ? "Convert your ad as a Top Ad" : "ඔබගේ දැන්වීම Top Ad දැන්වීමක් ලෙස පරිවර්තනය කරන්න"}
                    onClick={this.handleTopAdModalOpen}
                    className="top-ad-label"
                  >
                    <img src={Badge} alt="top ad" className="top-ad-badge" style={{height: "12px", marginBottom: "-2px"}} /> Top Ad
                  </span>
                )}
                {(this.props.isFeatured && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isFeatured) && (
                  <span
                    title = {this.props.lng ? "Your ad is already a Top Ad" : "ඔබේ දැන්වීම දැනටමත් Top Ad දැන්වීමක් වේ"}
                    className="top-ad-label"
                  >
                    <img src={Badge} alt="top ad" className="top-ad-badge" style={{height: "12px", marginBottom: "-2px"}} /> Top Ad
                  </span>
                )}
                {(this.props.isFeatured && !localStorage.getItem("post_details")) && (
                  <span
                    title = {this.props.lng ? "Convert your ad as a Top Ad" : "ඔබගේ දැන්වීම Top Ad දැන්වීමක් ලෙස පරිවර්තනය කරන්න"}
                    onClick={this.handleTopAdModalOpen}
                    className="top-ad-label"
                  >
                    <img src={Badge} alt="top ad" className="top-ad-badge" style={{height: "12px", marginBottom: "-2px"}} /> Top Ad
                  </span>
                )}
                <div className="post-details-col">
                  {this.props.gender === "MALE" ? (
                    <img src={groom} className="applicant-photo" alt="groom" />
                  ) : (
                    <img src={bride} className="applicant-photo" alt="bride" />
                  )}

                  <Typography variant="h6" className="name">
                    <span style={{ textTransform: "capitalize" }}>
                      {!this.props.unmatchedTime && this.props.interestInfo && this.props.interestInfo.matched ?
                        this.props.fName+" "+this.props.lName
                      :
                        this.props.displayName
                      }
                    </span>
                  </Typography>
                  <p className="location">
                    {this.props.age && (
                      <React.Fragment>
                        {this.props.age}{" "}
                        <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                        {this.props.residentCity}{" "}
                        <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                        {this.props.lng
                          ? this.props.profession
                          : this.props.profession_si}{" "}
                        <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                        {Math.floor(Math.round(this.props.height / 2.54) / 12)}'{" "}
                        {Math.round(this.props.height / 2.54) % 12}"
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="post-date-col">
                  <p align="right" className="date-of-post">
                    {this.props.countryCodee === "" ? (
                      <i
                        className="fas fa-globe-americas"
                        data-tip="Other Country"
                      ></i>
                    ) : (
                      <ReactCountryFlag
                        countryCode={this.props.countryCode}
                        svg
                        style={{
                          fontSize: "1.25em",
                        }}
                        aria-label={
                          this.props.lng
                            ? this.props.country
                            : this.props.country_si
                        }
                        data-tip={
                          this.props.lng
                            ? this.props.country
                            : this.props.country_si
                        }
                      />
                    )}
                  </p>
                </div>
                {this.props.createdBy ? (
                  <label className="createdby-label">
                    {this.props.createdBy === "SELF"
                      ? this.props.lng
                        ? "Account created by: Self"
                        : "ගිණුම සාදන ලද්දේ : තමන් විසින්"
                      : this.props.lng
                      ? "Account created by: Parents / Family"
                      : "ගිණුම සාදන ලද්දේ : දෙමවුපියන් / පවුලේ අය"}
                  </label>
                ) : null}
                {(this?.props?.nicVerified === "Yes") &&
                  <label onClick={this.handleNicModalOpen} className="createdby-label" style={{cursor:"pointer", marginBottom: "29px", marginTop: "4px", background: "#2979ff", color:"white"}}>
                    {
                      this.props.lng
                        ? "ID verified "
                        : "හැඳුනුම්පත තහවුරු කර ඇත "
                    }
                    <i className="fas fa-check-circle"></i>
                  </label>
                }
                {(this.props.differentlyAbled && this.props.differentlyAbled === "Yes") &&
                  <label className="createdby-label" style={{background: "#E0E0E0", color: "#333333"}}>
                    {
                      this.props.lng
                        ? "Differently Abled"
                        : "විශේෂ අවශ්‍යතා සහිත"
                    }
                  </label>
                }
                {!this.props.cancelSentTime ? (
                  this.props.interestInfo &&
                  this.props.interestInfo.liked &&
                  this.props.interestInfo.liked === "OUTGOING" ? (
                    <p className="likes-you">
                      <img className="heart-icon" src={heart} alt="heart" />
                      &nbsp; Interest Sent
                    </p>
                  ) : null
                ) : null}

                {!this.props.cancelAcceptTime ? (
                  this.props.interestInfo &&
                  this.props.interestInfo.liked &&
                  this.props.interestInfo.liked === "INCOMING" ? (
                    <p className="likes-you">
                      <img className="heart-icon" src={heart} alt="heart" />
                      &nbsp; Interest Received
                    </p>
                  ) : null
                ) : null}

                {!this.props.unmatchedTime ? (
                  this.props.interestInfo &&
                  this.props.interestInfo.matched &&
                  this.props.interestInfo.matched ? (
                    <p className="likes-you">
                      <img className="heart-icon" src={heart} alt="heart" />
                      &nbsp; Matched
                    </p>
                  ) : null
                ) : null}

                {this.props.isMyProfile ? (
                  this.props.applyMatch ? (
                    this.props.images.length > 0 ? (
                      this.props.imageData.length > 0 ? (
                        <PhotoGrid images={this.props.imageData} />
                      ) : null
                    ) : null
                  ) : null
                ) : null}
                {!this.props.isMyProfile ? (
                  this.props.applyMatch ? (
                    this.props.areThereMyPhotos ? (
                      this.props.imageData.length > 0 ? (
                        <PhotoGrid images={this.props.imageData} />
                      ) : (
                        <p className="invisible-photos">
                          <i className="fas fa-eye-slash"></i>{" "}
                          {this.props.lng
                            ? "This user has not uploaded pictures to their profile. Your pictures will not be shared with them."
                            : "මෙම පරිශීලකයා ඔවුන්ගේ දැන්වීමට පින්තූර ඇතුලත් කර නොමැත. එම නිසා ඔබේ පින්තූර ඔවුන්ට පෙන්වන්නේ ද නොමැත."}
                        </p>
                      )
                    ) : (
                      <p
                        className="invisible-photos"
                        style={{ marginBottom: 0 }}
                      >
                        <i className="fas fa-eye-slash"></i>{" "}
                        {this.props.lng
                          ? "Upload pictures to your profile to view pictures of your matches"
                          : "ඔබගේ ගැලපීම් වල පින්තූර බැලීමට ඔබගේ දැන්වීම සඳහා ද පින්තූර ඇතුලත් විය යුතුය."}{" "}
                        <a
                          href="/edit-my-ad?scroll=toPhotos"
                          style={{ color: "#2196F3" }}
                        >
                          {this.props.lng
                            ? "Upload your photos"
                            : "ඔබගේ පින්තූර ඇතුලත් කරන්න"}
                        </a>
                      </p>
                    )
                  ) : null
                ) : null}

                {this.props.description && (
                  <p
                    className="desc"
                    style={{ marginBottom: 0, marginTop: 20 }}
                  >
                    {this.props.description}
                  </p>
                )}
                {(this.props.messageModal && this.props.showPhotos && this.props.receivedInterestImages.length > 0) &&
                  this.state.imageData.length && <PhotoGrid images={this.state.imageData} />
                }
              </React.Fragment>
            )}
          </Paper>
          <Dialog
            open={this.state.nicModal}
            onClose={this.handleNicModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "#000", paddingBottom: 0 }}
            >
              {this.props.lng ? (
                "Get verified"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  අනන්‍යතාවය තහවුරු කිරීම
                </span>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.lng ? (
                  "Would you like to verify your ad?"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">
                    ඔබගේ දැන්වීමේ අනන්‍යතාවය තහවුරු කිරීමට කැමතිද?
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleNicModalClose}>
                {this.props.lng ? (
                  "Cancel"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">අවලංගු කරන්න</span>
                )}
              </Button>
              <Button
                onClick={() =>  this.props.history.push({pathname: '/settings', state : "nic-div"})}
                style={{ background: "#000", color: "#fff" }}
              >
                {this.state.loadingDelete ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  <span>
                    {this.props.lng ? (
                      "Get verified"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-13">තහවුරු කර ගන්න</span>
                    )}
                  </span>
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.topAdModal}
            onClose={this.handleTopAdModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "#000", paddingBottom: 0 }}
            >
              Top Ad
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.lng ? (
                  "Would you like to convert your ad as a Top Ad"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">
                    ඔබගේ දැන්වීම Top Ad දැන්වීමක් ලෙස පරිවර්තනය කිරීමට කැමතිද?
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleTopAdModalClose}>
                {this.props.lng ? (
                  "Cancel"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">අවලංගු කරන්න</span>
                )}
              </Button>
              <Button
                onClick={() => this.props.history.push("/payments/top-ad")}
                style={{ background: "#000", color: "#fff" }}
              >
                {this.state.loadingDelete ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  <span>
                    {this.props.lng ? (
                      "Continue"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-13">ඉදිරියට යන්න</span>
                    )}
                  </span>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
