import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";

import Config from "../../../../../config";
import GroomImg from "../../../../../../images/groom.png";
import BrideImg from "../../../../../../images/bride.png";
import "./style.css";
import { isEmptyOrNullOrUndefined } from "../../../../../utils";

export const GenderSelector = withTranslation()(
  ({ meta, value, setFieldValue, t, i18n }) => {
    return (
      <div className="filter-cus-div">
        <p
          variant="h6"
          className={`filter-cus-heading ${
            (i18n.language === Config.APP_LANGS.SI &&
              "sinhala-w-600 sinhala-size-13") ||
            ""
          }`}
        >
          {t(meta.label)}
        </p>
        {["MALE", "FEMALE"].map((gender) => (
          <img
            key={`gender-${gender.toLowerCase()}-img`}
            src={gender === "MALE" ? GroomImg : BrideImg}
            alt={gender.toLowerCase()}
            className={`filter-gender ${
              value === gender ? "active-gender" : ""
            }`}
            onClick={() => setFieldValue(meta.fieldName, gender)}
          />
        ))}
      </div>
    );
  }
);

export const OrderBySelector = withTranslation()(
  ({ meta, value, setFieldValue, t, i18n }) => {
    return (
      <Grid container direction="row" className="sort-by-container">
        <FormControl className="post-sort-by">
          <InputLabel
            className={
              i18n.language === Config.APP_LANGS.SI
                ? "sinhala-w-600 sinhala-size-13"
                : ""
            }
          >
            {t("filterbar.sortName")}
          </InputLabel>
          <Select
            value={value}
            onChange={(e) => setFieldValue(meta.fieldName, e.target.value)}
            style={{ width: "100%" }}
          >
            {meta.options.map((option) => (
              <MenuItem
                key={option.value}
                className="sort-value"
                value={option.value}
              >
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }
);

export const InterestMatchToggle = withTranslation()(
  ({ meta, value, setFieldValue, t }) => {
    return (
      <Grid container direction="column" className="interest-match-toggle">
        <Typography variant="body1" className="toggle-heading">
          {t("filterbar.interestsAndMatches")}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={0}
        >
          <Typography variant="body1" className="toggle-label">
            {t("filterbar.showOnTheList")}
          </Typography>
          <Switch
            checked={value}
            onChange={(event) => {
              event.preventDefault();
              setFieldValue(meta.fieldName, event.target.checked);
            }}
          />
        </Grid>
      </Grid>
    );
  }
);

export const RangeSelector = ({
  startFieldMeta,
  endFieldMeta,
  startValue,
  endValue,
  setFieldValue,
  t,
  items,
}) => {
  const startFieldItems = useMemo(() => {
    if (isEmptyOrNullOrUndefined(endValue)) {
      return items;
    }
    let filtereditems = [];
    if (Array.isArray(items)) {
      return items.filter((element) => {
        let comparator = endFieldMeta.keyExtractor
          ? element[endFieldMeta.keyExtractor]
          : element;
        comparator =
          comparator.constructor === Number
            ? parseFloat(comparator.toString())
            : comparator;
        const upperBound =
          endValue.constructor === String ? parseFloat(endValue) : endValue;

        return comparator < upperBound;
      });
    }
    return filtereditems;
  }, [endFieldMeta.keyExtractor, endValue, items]);

  const endFieldItems = useMemo(() => {
    if (isEmptyOrNullOrUndefined(startValue)) {
      return items;
    }
    let filtereditems = [];
    if (Array.isArray(items)) {
      filtereditems = items.filter((element) => {
        let comparator = startFieldMeta.keyExtractor
          ? element[startFieldMeta.keyExtractor]
          : element;
        comparator =
          comparator.constructor === Number
            ? parseFloat(comparator.toString())
            : comparator;
        const lowerBound =
          startValue.constructor === String
            ? parseFloat(startValue)
            : startValue;
        return comparator > lowerBound;
      });

      return filtereditems;
    }
  }, [items, startFieldMeta.keyExtractor, startValue]);

  return (
    <Grid
      container
      direction="row"
      className="filter-bar-form-element-container"
    >
      <select
        className="select-dropdown"
        name={startFieldMeta.fieldName}
        value={startValue}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
      >
        {startFieldItems.map((option, i) => (
          <option
            key={`min-height-option-${i}`}
            value={option[startFieldMeta.keyExtractor] ?? option}
          >
            {option[startFieldMeta.labelExtractor] ?? option}
          </option>
        ))}
      </select>
      <span className="to-label">to</span>
      <select
        className="select-dropdown"
        name={endFieldMeta.fieldName}
        value={endValue}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
      >
        {endFieldItems.map((option, i) => (
          <option
            key={`max-height-option-${i}`}
            value={option[endFieldMeta.keyExtractor] ?? option}
          >
            {option[endFieldMeta.labelExtractor] ?? option}
          </option>
        ))}
      </select>
    </Grid>
  );
};
