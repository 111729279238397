import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Status from "../components/settings/Status";
import LoginInfo from "../components/settings/LoginInfo";
import PwdChange from "../components/settings/PwdChange";
import Notification from "../components/settings/Notification";
import Mailing from "../components/settings/Mailing";
import RemoveAd from "../components/settings/RemoveAd";
import HideAd from "../components/settings/HideAd";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import NicUpload from "../components/settings/NicUpload";
import Config from "../v2/config";

export default class Settings extends Component {
  state = {
    lng: true, // default en
    isAccessDenied: false,
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,

      isAccessDenied: localStorage.getItem("auth_token") ? false : true,
    });

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.props.location && this.props.location.state && document.getElementById(this.props.location.state) && localStorage.getItem("auth_token")) {
      document.getElementById(this.props.location.state).scrollIntoView({block: 'center'});
    }
  };

  handleLng = () => {
    this.setState({ lng: !this.state.lng });

    localStorage.setItem("lng", !this.state.lng);
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`Settings | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <PageHead title={`Settings | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <Navbar handleLng={this.handleLng} lng={this.state.lng} />
        <div style={{ background: "#dfdfdf", paddingTop: 5 }}>
          <Typography
            variant="h3"
            align="center"
            className="create-new-ad"
            style={{ paddingBottom: 30, fontWeight: 500 }}
          >
            {this.state.lng === true ? "Settings" : <span>සැකසුම්</span>}
          </Typography>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className="cus-container complete-ad-container"
          >
            <Status lng={this.state.lng} />
            <LoginInfo lng={this.state.lng} />
            <PwdChange lng={this.state.lng} />
            <Notification lng={this.state.lng} />
            {Config.OFFLINE_RESPONSES_VISIBILITY && <Mailing lng={this.state.lng} />}
            <NicUpload lng={this.state.lng} />
            {localStorage.getItem("postId") && <HideAd lng={this.state.lng} />}
            {localStorage.getItem("postId") && (
              <RemoveAd lng={this.state.lng} />
            )}
          </Grid>
        </div>
        <Footer lng={this.state.lng} />
      </React.Fragment>
    );
  }
}
